import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";

//redux
import { useDispatch } from "react-redux";
import { closeAllModal } from "../../redux/reducer/modalReducer";
import { setDetail } from "../../redux/reducer/paymentReducer";
import {
  clearOptionPrice,
  clearOption,
  setMaxfoodQuantity,
} from "../../redux/reducer/menuReducer";
import { setFoodData } from "../../redux/reducer/cartReducer";

export default function CartHeader({ title }) {
  const dispatch = useDispatch();

  const closeFunction = () => {
    dispatch(closeAllModal());
    dispatch(setDetail(""));
    dispatch(clearOptionPrice(0));
    dispatch(clearOption({}));
    dispatch(setFoodData({}));
    dispatch(setMaxfoodQuantity(0));
  };

  return (
    <MDBContainer className="cart-modal-header">
      <MDBRow className="header">
        <MDBCol className="col-11">
          <label className="_label --order">{title}</label>
        </MDBCol>
        <MDBCol className="col-1">
          <Icon
            className="icon"
            color="#373A2E"
            icon="material-symbols:close"
            onClick={() => {
              closeFunction();
            }}
          />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
