import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";

//assets
import Lottie from "lottie-react";
import Loading from "./../../../assets/json/loading.json";

export default function LoadingPage() {
  return (
    <div className="loading-page">
      <div className="loading-overlay2">
        <div className="d-flex flex-column align-items-center">
          <div style={{ height: '75%', display: 'contents', textAlign: 'center' }}>
            <div className="loading-lottie">
              <Lottie animationData={Loading} />
            </div>
            <div className="loading-div d-flex justify-content-center">
              Please wait..<br />
              Do not refresh or close your browser.
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}
