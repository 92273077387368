import { MDBModalBody } from "mdb-react-ui-kit";
import { RemoveScroll } from "react-remove-scroll";
import ModalBox from "../../components/modal/full.modal.box";

//component
import LoginHeader from "./component/login.header";
import LoginForm from "./component/login.form";
import RegisterForm from "./component/register-form";
import ForgotForm from "./component/forgot.form";
import PasswordForm from "./component/password.form";

//redux
import { useSelector } from "react-redux";

export default function Login() {
  const { isOpenLogin } = useSelector((state) => state.modal);
  const { login } = useSelector((state) => state.login);
  return (
    <RemoveScroll>
      <ModalBox
        show={isOpenLogin}
        className="login-modal"
        screenSize="fullscreen-xl-down"
        content={
          <MDBModalBody className="login-container">
            <LoginHeader />
            {login == "Login" ? (
              <LoginForm />
            ) : login == "Sign Up" ? (
              <RegisterForm />
            ) : login == "Forgot" ? (
              <ForgotForm />
            ) : login == "Password" || login == "Password2" ? (
              <PasswordForm />
            ) : (
              ""
            )}
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}
