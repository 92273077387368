import { createSlice } from "@reduxjs/toolkit";

const initialState = {
       detail:{},
      quantity:1,
      subTotal:0,
      ticket:{}
}

const alcoholicSlice = createSlice({
    name: "alcoholic",
    initialState,
    reducers: {
       changePrice: (state, action) => {
        state.quantity = action.payload 
      },
      setDetail: (state, action) => {
        state.detail = action.payload
      },
      setSubTotal :(state , action) =>{
        state.subTotal = action.payload * state.quantity
      },
      setData :(state , action) =>{
        state.ticket = action.payload 
      }
    },
    extraReducers:{
     
    }
})

export const {
 setDetail,
     changePrice,
       setSubTotal,
       setData,
} = alcoholicSlice.actions
const { reducer } = alcoholicSlice
export default reducer;