import moment from "moment/moment";
import { Icon } from "@iconify/react";
import { ProgressBar } from "primereact/progressbar";
import { MDBCol, MDBRow, MDBContainer } from "mdb-react-ui-kit";
import { Logo2 } from "../../../assets/images";
//component

//assets
import Lottie from "lottie-react";
import Loading from "./../../../assets/json/loading.json";

//redux
import { useSelector, useDispatch } from "react-redux";
import { formatNumber } from "../../../helper/return_number";

export default function OrderDetail() {
  const dispatch = useDispatch();
  const { order, order_loading } = useSelector((state) => state.cart);
  const { cart } = useSelector((state) => state.menu);

  return (
    <div style={{ paddingBottom: "3em" }}>
      {order_loading ?
        <div className="d-flex flex-column align-items-center image-div">
          <div style={{ height: '75%' }}>
            <div className="loading-lottie">
              <Lottie animationData={Loading} />
            </div>
          </div>
        </div>
      : order && order.length > 0 ? (
          order.map((item, index) => {
            let no = item.order_status;
            return (
              <MDBContainer className="food-order" key={index}>
                {item.merchant && (
                  <div>
                    <MDBRow>
                      <label className="_label --name">
                        From{" "}
                        <span
                          style={{
                            textTransform: "uppercase",
                            paddingLeft: "0.2em",
                            fontSize: "1.05em",
                          }}
                        >
                          {item.merchant}
                        </span>
                      </label>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol>
                        <label className="_label --total_paid">
                          Order Number
                        </label>
                      </MDBCol>
                      <MDBCol className="text-end">
                        <label className="_label --order_number">
                          No. <span> {item.order_no} </span>
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol>
                        <label className="_label --data">Order Time </label>
                      </MDBCol>
                      <MDBCol className="text-end">
                        <label className="_label --data2">
                          {moment(item.order_date_time, "hh:mm").format(
                            "DD MMM YYYY"
                          )}{" "}
                          {""}
                          {moment(item.order_date_time).format("h:mm A")}
                        </label>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol>
                        <label className="_label --data">Order Type</label>
                      </MDBCol>
                      <MDBCol className="text-end">
                        <label className="_label --data2">{item.method}</label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol>
                        <label className="_label --data">Table</label>
                      </MDBCol>
                      <MDBCol className="text-end">
                        <label className="_label --data2">
                          {item.table_name}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="border-upline">
                      <MDBCol>
                        <label className="_label --total_paid">Total Paid</label>
                      </MDBCol>
                      <MDBCol className="text-end">
                        <label className="_label --total_price">
                          RM {formatNumber(item.total_price)}
                        </label>
                      </MDBCol>
                    </MDBRow>
                  </div>
                )}
                <MDBContainer className="step-div p-0">
                  {item.order && (
                    <MDBRow className="p-0 m-0">
                      <label className="p-0 _label --text">
                        Check Order Detail
                      </label>
                    </MDBRow>
                  )}
                  {item.order ? (
                    item.order.map((item, index) => {
                      return (
                        <MDBRow key={index} className="food-detail">
                          <MDBCol className="col-10">
                            <MDBRow className="">
                              <MDBCol className="food-col">
                                <label className="_label --food_name">
                                  {item.food_name}{" "}
                                </label>
                                <label className="_label --code">
                                  {item.food_code && item.food_code}
                                </label>
                              </MDBCol>
                            </MDBRow>
                            {item.remark === "alcohol" && (
                              <label className="_label --option">
                                expired date : {item.expired_at}
                              </label>
                            )}
                            {item.details && item.details.length > 0
                              ? item.details.map((item, index) => {
                                  return (
                                    <MDBRow key={index} className="">
                                      <label className="_label --option ">
                                        {item}
                                      </label>
                                    </MDBRow>
                                  );
                                })
                              : ""}

                            <MDBRow className="">
                              <label className="_label --option ">
                                {item.order_remark}
                              </label>
                            </MDBRow>
                            {item.remark === "alcohol" && (
                              <label className="_label --label">
                                Go Alcohol Page Claim Your Order
                              </label>
                            )}
                          </MDBCol>
                          <MDBCol className="col-2 text-end">
                            <label className=" _label --quantity">
                              {" "}
                              x {item.quantity}
                            </label>
                          </MDBCol>
                        </MDBRow>
                      );
                    })
                  ) : (
                    <MDBRow className="food-detail ">
                      <MDBCol className="col-4 food-image">
                        <img
                          src={item.picture ? item.picture : cart.merchant_image}
                          className="img"
                        />
                      </MDBCol>
                      <MDBCol className="col-8 food-data">
                        <MDBRow>
                          {item.alcohol_code && (
                            <MDBCol className="col-3 food-col">
                              <label className="_label --code text-center">
                                {item.alcohol_code}
                              </label>
                            </MDBCol>
                          )}
                          <MDBCol>
                            <label className="_label --name">
                              {item.alcohol_name}
                            </label>
                          </MDBCol>
                        </MDBRow>

                        <MDBRow>
                          <label className="_label --quantity">
                            x {item.quantity}
                          </label>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                  )}
                </MDBContainer>
              </MDBContainer>
            );
          })
        )
        : 
        <div className="d-flex flex-column align-items-center image-div">
          <div style={{ height: '75%', textAlign: 'center' }}>
            <div className="loading-lottie">
            <img
              src={Logo2}
              className="logo-image"
              alt="logo-image"
              fetchpriority="high"
            />
            </div>
            <div className="loading-div d-flex justify-content-center">
              No Data Available
            </div>
          </div>
        </div>
      }
    </div>
  );
}
