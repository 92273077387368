import { useState, useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import { Skeleton } from "primereact/skeleton";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { motion } from "framer-motion";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

//redux
import { useDispatch, useSelector } from "react-redux";
import {
  openModalCart,
  openModalCart2,
} from "../../../redux/reducer/modalReducer";
import {
  setFoodId,
  changePrice,
  option,
  setSearchValue,
  setSearching,
} from "../../../redux/reducer/menuReducer";

//component
import PartialRegisterModal from "../../../components/modal/partial.register.modal";
import useWindowDimensions from "../../../components/common/window-dimension";

export default function FoodListing({ animation }) {
  const { width } = useWindowDimensions();
  const [showBottom, setShowBottom] = useState(false);

  const [isLoading, setisLoading] = useState(true);
  const inputBRef = useRef();

  const dispatch = useDispatch();
  const { food_loading, food, cart, searchValue, rejectedStatus } = useSelector(
    (state) => state.menu
  );
  const { menu } = useSelector((state) => state.menu.food);
  const { picture } = useSelector((state) => state.menu.data);

  const foodList = menu ? menu : [];

  const filtered = foodList
    .filter(function (value) {
      const menuCode =
        value.menu_code !== null ? value.menu_code.toLowerCase() : "";
      const menuName = value.menu_name.toLowerCase();
      const searchValueLower = searchValue.toLowerCase();
      return (
        menuCode.includes(searchValueLower) ||
        menuName.includes(searchValueLower)
      );
    })
    .map((el) => el);


  let sletedFood = [];
  cart.cart_item &&
    cart.cart_item.map((item) => sletedFood.push(item.cart_item_menu_id));

    const uniqueCategoryIds = Array.from(new Set(filtered.map(item => item.category_id)));

    // 第二步：按 uniqueCategoryIds 中的顺序创建分组
    const groupedData = uniqueCategoryIds.map(catId => {
      const itemsInCategory = filtered.filter(item => item.category_id === catId);
      return {
        id: `${catId}`,
        name: itemsInCategory[0].category_name, // 假设所有相同 category_id 的项具有相同的 category_name
        items: itemsInCategory
      };
    });

  // 将分组后的数据转换为一个数组
  const result = Object.values(groupedData).flatMap((group) => [
    { id: group.id, name: group.name },
    ...group.items,
  ]);

  useEffect(() => {
    if (!animation && searchValue !== "") {
      inputBRef.current.focus();
    }
  }, [animation]);

  return (
    <MDBContainer className="menu-food-listing">
      <MDBRow className="menu_search">
        <MDBCol>
          <input
            id="input-b"
            className="w-100"
            type="search"
            name="input"
            value={searchValue}
            placeholder="Search food name or code"
            onChange={(e) => {
              dispatch(setSearchValue(e.target.value));
              if (e.target.value == "") {
                dispatch(setSearching(false));
              } else {
              }
            }}
            ref={inputBRef}
          />
        </MDBCol>
        <MDBCol className="col-2">
          <Icon
            icon="dashicons:search"
            width="30"
            heigth="27"
            className="icon"
          />
        </MDBCol>
      </MDBRow>

      {food_loading ? (
        <MDBContainer className="mt-3">
            <MDBRow >
              {Array.from({ length: width > 770 ? 4 : 2 }, (_, bottomSectionIndex) => (
                <MDBCol className={`col-${ width > 770 ? 3 : 6} d-flex justify-content-center`} key={bottomSectionIndex}>
                  <Skeleton width="15em" height="18em" borderRadius="1.75em" className="mt-5" />
                </MDBCol>
              ))}
            </MDBRow>
        </MDBContainer>
      ) : (
        <MDBRow>
          {menu !== undefined && result ? (
            result.map((item, index) => {
              return (
                <MDBCol
                  key={index}
                  className={
                    item.category_id || item._id
                        ? "col-6 col-md-3 p-6 menu-listing"
                        : "col-12 col-md-12 p-6 menu-listing"
                  }
                >
                  <MDBRow
                    key={index}
                    className="title-row"
                    id={item.category_id ? item.category_id : item.id} 
                  >
                    <label className="_label --title">{item.name}</label>
                  </MDBRow>
                  {item.category_id || item._id ? (
                    <motion.div
                    animate={{opacity : 0}}
                    whileInView={{
                      opacity:1
                    }
                    }>
                    <MDBContainer
                      className="listing-container"
                                            onClick={() => {
                        if (
                          item.stock_status == 1 &&
                          sletedFood.includes(item.menu_id)
                        ) {
                          dispatch(openModalCart2());
                          dispatch(
                            setFoodId({
                              id: item.menu_id,
                              name: item.menu_name,
                              price: item.unit_price,
                            })
                          );
                        } else if (item.stock_status == 0) {
                        } else {
                          dispatch(option({ menu_id: item.menu_id }))
                            .unwrap()
                            .then((res) => {
                            })
                            .catch((ex) => {});
                          dispatch(changePrice(1));
                          dispatch(openModalCart());
                          setisLoading(false);
                        }
                      }}
                    >
                      <MDBRow className="image">
                        {item.picture && (
                          <img
                            src={item.picture || picture}
                            className={
                              item.stock_status == 0
                                ? "food-image"
                                : "food-image"
                            }
                            alt="food-image"
                            loading="lazy"
                          />
                        )}
                        {item.stock_status == 0 && (
                          <label className="sold_out">OUT</label>
                        )}
                      </MDBRow>
                      <MDBContainer className="text-label">
                        <MDBRow>
                          <label className="_label --title">
                            {item.menu_name}
                          </label>
                        </MDBRow>
                        <MDBRow className="price-row">
                          {item.menu_code ? (
                            <MDBCol className="col-4">
                              <label className="_label --code text-center">
                                {item.menu_code}
                              </label>
                            </MDBCol>
                          ) : (
                            ""
                          )}
                          <MDBCol
                            className={
                              item.menu_code !== null
                                ? "col-8 pe-1"
                                : "col-12 text-center"
                            }
                          >
                            <div>
                              <label className="_label --price">
                                RM {parseFloat(item.unit_price).toFixed(2)}
                              </label>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBContainer>
                    </MDBContainer>
                    </motion.div>
                  ) : (
                    ""
                  )}
                </MDBCol>
              );
            })
          ) : !food_loading && menu === undefined && rejectedStatus ? (
            <PartialRegisterModal
              showBottom={showBottom}
              show={foodList.length === 0 && food.length === 0 ? true : false}
              content={
                <div className="text-center partial-modal login">
                  <MDBRow className="user-modal">
                    <MDBCol lg="7" md="7" className="col-12">
                      <Icon
                        icon="ic:outline-access-time"
                        width="22"
                        height="22"
                        className="icon-clock"
                      />
                      <label className="_label --user">
                        Closed for now, come back later.
                      </label>
                    </MDBCol>
                  </MDBRow>
                </div>
              }
            />
          ) : (
            menu !== undefined &&
            menu.length === 0 && (
              <MDBRow className="message">
                <label className="_label --message">
                  There's nothing available to order.
                </label>
              </MDBRow>
            )
          )}
        </MDBRow>
      )}
    </MDBContainer>
  );
}
