import { useEffect, useState } from "react";
import { Logo2 } from "../../assets/images";

//redux

export default function LoadingPage() {
  const [addClass, setAddClass] = useState(false);

  useEffect(() => {
    setTimeout(() => setAddClass(true), 1500);
  }, []);

  return (
    <div className="loading-page">
      <div className={addClass ? "loading-overlay --end" : "loading-overlay"}>
        <div className="animated-logo">
          <img src={Logo2} width="100%" alt="Fos Logo" />
        </div>
      </div>
    </div>
  );
}
