import { useState } from "react";
import { RemoveScroll } from "react-remove-scroll";
import { MDBModalBody, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ref } from "yup";
import { toast } from "react-toastify";

//component
import ModalBox from "../../components/modal/full.modal.box";
import ModalHeader from "../../components/header/modal.header";
import Text from "../../components/element/text";
import Button from "../../components/element/button";

//redux
import { useSelector, useDispatch } from "react-redux";
import { closeAllModal } from "../../redux/reducer/modalReducer";
import { profile } from "../../redux/reducer/profileReducer";

//api
import apiServices from "../../services/api.services";

export default function ModalChangePassword() {
  const { isOpenChangePassword } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    password: "",
    new_password: "",
    confirm_new_password: "",
  });
  const changePasswordSchema = Yup.object({
    password: Yup.string().required("Required"),
    new_password: Yup.string().required("Required"),
    confirm_new_password: Yup.string()
      .required("Required")
      .oneOf([ref("new_password")], "Passwords do not match"),
  });

  const handleSubmit = async ({ values, setFieldError }) => {
    try {
      const response = await apiServices.changePassword(values);
      if (response) {
        dispatch(closeAllModal());
        dispatch(profile());
        toast.success("Change Password Success", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "light",
        });
      }
    } catch (ex) {
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
            });
          }
        }
      }
    }
  };
  return (
    <RemoveScroll>
      <ModalBox
        show={isOpenChangePassword}
        screenSize="fullscreen-xl-down"
        className="change-password-modal"
        content={
          <MDBModalBody>
            <ModalHeader title="Change Password" />
            <Formik
              initialValues={userData}
              validationSchema={changePasswordSchema}
              onSubmit={(values, { resetForm }) => {
                handleSubmit({ values });
                resetForm({ values: "" });
              }}
            >
              <Form>
                <MDBContainer className="change-password-container">
                  <MDBRow>
                    <Text
                      type="password"
                      name="password"
                      className="service-field"
                      placeholder="Current Password"
                      as
                    />
                  </MDBRow>
                  <MDBRow>
                    <Text
                      type="password"
                      name="new_password"
                      className="service-field"
                      placeholder="New Password"
                      as
                    />
                  </MDBRow>
                  <MDBRow>
                    <Text
                      type="password"
                      name="confirm_new_password"
                      className="service-field"
                      placeholder="Confirm Password"
                      as
                    />
                  </MDBRow>
                  <MDBRow className="btn-row">
                    <Button
                      type={"submit"}
                      name="Save"
                      className="login_button"
                    />
                  </MDBRow>
                </MDBContainer>
              </Form>
            </Formik>
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}
