import { Formik, Form } from "formik";
import * as Yup from "yup";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { toast } from "react-toastify";

//component
import Text from "../../../components/element/text";
import Button from "../../../components/element/button";
import Code from "../../../components/element/code";

//redux
import { useDispatch } from "react-redux";
import { changeLogin, changeUserId } from "../../../redux/reducer/loginReducer";

//auth
import authServices from "../../../services/auth.services";
import { Notification } from "../../../helper/react_notification";

export default function ForgotForm() {
  const dispatch = useDispatch();
  const userData = {
    phone_number: "",
    verification_code: "",
    type: "forgot password",
  };

  const loginSchema = Yup.object({
    phone_number: Yup.string()
      .required("Required")
      .matches(/^(0)(1)[0-9]{8}$/, "Invalid phone number format."),
    verification_code: Yup.number()
      .required("Required")
      .typeError("Verification code must be a number"),
  });

  const handleSubmit = async ({ values, setFieldError }) => {
    try {
      const response = await authServices.codeValidate(values);
      if (response) {
        dispatch(changeLogin("Password2"));
        dispatch(changeUserId(values.phone_number));
        // toast.success("Verification Success !", {
        //   position: "top-center",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: false,
        //   draggable: true,
        //   theme: "light",
        // });
        Notification({
          title: "Successful!",
          message: "Phone number verified.",
          type: "success",
        });
      }
    } catch (ex) {
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
            });
          }
        }
      }
    }
  };

  return (
    <Formik
      initialValues={userData}
      validationSchema={loginSchema}
      onSubmit={(values, { resetForm, setFieldError }) => {
        handleSubmit({ values, setFieldError });
        resetForm({ values: "" });
      }}
    >
      {({}) => (
        <Form>
          <MDBContainer className="login-body">
            <MDBRow>
              <label className="_label --note">
                Opss.. Check your mail box
              </label>
            </MDBRow>
            <MDBRow>
              <label className="_label --text">
                Get an account to enjoy more and benefits
              </label>
            </MDBRow>
            <MDBRow className="tag-div">
              <label className="_label --tag">Contact</label>
            </MDBRow>
            <Text
              className="login-field"
              name="phone_number"
              placeholder="(+60)"
              verification
            />
            <Code className="login-field" name="verification_code" />
            <MDBRow>
              <Button
                type={"submit"}
                name="Continue"
                className="login_button"
              />
            </MDBRow>
          </MDBContainer>
        </Form>
      )}
    </Formik>
  );
}
