import { createSlice } from "@reduxjs/toolkit";

const initialState = {

    header:[{
        label: "Menu",
        path: "/menu",
        icon: "icon-park-outline:knife-fork",
        icon2: "icon-park-solid:knife-fork",
        value: 0,
        merchant_halal_status : "",

    },{
        label: "Alcoholic",
        path: "/alcoholic",
        icon: "maki:alcohol-shop",
        icon2: "maki:alcohol-shop",
        value: 0,
        merchant_halal_status : "halal",

    },{
        label: "Cart",
        path: "/cart",
        icon: "ph:shopping-cart-simple-bold",
        icon2: "ph:shopping-cart-simple-fill",
        value: 1,
        merchant_halal_status : "",


    },{
        label: "Order",
        path: "/order",
        icon: "ri:file-list-3-line",
        icon2: "ri:file-list-3-fill",
        value: 0,
        merchant_halal_status : "",

    }],
    header2:[{
        label: "Menu",
        path: "/menu",
        icon: "icon-park-outline:knife-fork",
        icon2: "icon-park-solid:knife-fork",
        value: 0,
        merchant_halal_status : "",

    },{
        label: "Cart",
        path: "/cart",
        icon: "ph:shopping-cart-simple-bold",
        icon2: "ph:shopping-cart-simple-fill",
        value: 1,
        merchant_halal_status : "",


    },{
        label: "Order",
        path: "/order",
        icon: "ri:file-list-3-line",
        icon2: "ri:file-list-3-fill",
        value: 0,
        merchant_halal_status : "",

    }],
    t_and_c_Listing: [
        {
            title: "1. Acceptance of Terms",
            content: "By accessing and using our platform, you agree to comply with and be bound by these Terms of Use. If you do not agree with any part of these terms, please refrain from using our platform."
        },
        {
            title: "2. Platform Description",
            content: "Our platform is a community website for food lovers to order food from restaurants."
        },
        {
            title: "3. Prohibited Content",
            content: "You agree not to share, post, or distribute violent, explicit, or offensive content on our platform. While using our platform, you must not engage in any illegal activities or infringe upon the rights of others."
        },
        {
            title: "4. User Behavior",
            content: "You are responsible for your behavior on our platform. You must respect other users and adhere to community guidelines. We reserve the right to suspend or terminate your account for violations of these Terms of Use."
        },
        {
            title: "5. Intellectual Property",
            content: "All content provided on our platform is protected by copyright and other intellectual property laws. You may not use any content from our platform for commercial purposes without our permission."
        },
        {
            title: "6. Privacy",
            content: "We collect and process personal information in accordance with our Privacy Policy."
        },
        {
            title: "7. Termination",
            content: "We reserve the right to terminate or suspend your access to our platform at any time, without notice, for violations of these Terms of Use."
        },
        {
            title: "8. Changes to the Terms",
            content: "We may update these Terms of Use from time to time. It is your responsibility to review them regularly."
        },
    ],
    privacyListing: [
        {
            title: "1. Information We Collect",
            content: "When you register on our platform, we collect personal information such as your name, email address, and other relevant details. We may collect non-personal information, such as IP addresses and browsing data."
        },
        {
            title: "2. How We Use Your Information",
            content: "We use your information to provide and enhance the functionality of our platform. We do not sell, rent, or share your personal information with third parties without your consent."
        },
        {
            title: "3. Security",
            content: "We take reasonable measures to protect your personal information, but data transmitted over the internet is not entirely secure."
        },
        {
            title: "4. Third-Party Links",
            content: "Our platform may contain links to third-party websites. We are not responsible for their privacy policies."
        },
        {
            title: "5. Children's Privacy",
            content: "Our platform is not intended for children under 13 years old. We do not knowingly collect children's information."
        },
        {
            title: "6. Changes to This Notice",
            content: "We may periodically update this Privacy Notice. We encourage you to review this notice regularly to stay informed about how we are protecting your data."
        },
        {
            title: "7. Contact Us",
            content: "For any inquiries regarding this Privacy Notice or our data practices, please contact us at the following:"
        }
    ],
    selectedFile : "terms" , 
    logoutStatus : false , 
    pusherStore : {} ,
}

const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        setSelectedFile :(state , action) =>{
            state.selectedFile = action.payload 
          } , 
          setLogoutStatus :(state , action) =>{
            state.logoutStatus = !state.logoutStatus 
          },
          setPusherStore :(state , action) =>{
            state.pusherStore =  action.payload 
          }
          
    },
    extraReducers:{
    }
})

export const {
    setSelectedFile,
    setLogoutStatus , 
    setPusherStore ,
} = commonSlice.actions
const { reducer } = commonSlice
export default reducer;