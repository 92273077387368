import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import apiServices from "../../services/api.services";
import httpService from "../../services/http.services";
import authServices from "../../services/auth.services";

export const transfer_cart = createAsyncThunk("post/transfer", async ({cart_id , device_id}) => {
  const response = await apiServices.orderCart({cart_id , device_id});
  return response.data;
});

export const bank_list = createAsyncThunk("get/bank", async (payload) => {
  const response = await apiServices.bankList(payload);
  return response;
});

export const provider_channels = createAsyncThunk("post/payment", async (payload) => {
  const response = await apiServices.providerChannels(payload);
  return response.data;
});

export const request_paymentV2 = createAsyncThunk("post/paymentV2", async (payload) => {
  const response = await apiServices.requestPaymentV2(payload);
  return response.data;
});

export const getAllChannels = createAsyncThunk('get/all/channels', async(payload, thunkAPI) => {
  try{
      httpService.setToken(authServices.getToken());
      const { data } = await httpService.get(process.env.REACT_APP_API_URL + `/get_all_channels?cart_id=${payload.cart_id}&amount=${payload.amount}` , payload)
      return data
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})


const initialState = {
   data:{},
   request:{},
   banks:[],
   loading: false,
   pay_channels:[],
   payId:"",
   orderId:"",
   bank_loading: false,
   quantity:0,
   paymentPoint: 0
}

const paymentSlice = createSlice({
    name: "payment",
    initialState,
    reducers: {
      handleChangeQuantity: (state, action) => {
        if(action.payload.type == "increases"){
          for (let i = 0; i <  state.listing.length; i++) {
            if(i == action.payload.index ) {
              void(state.listing[action.payload.index] =
                {...state.listing[action.payload.index],
              quantity:state.listing[action.payload.index].quantity + 1
              } )
            }
          }

        }
        else if(action.payload.type == "decreases")
      for (let i = 0; i <  state.listing.length; i++) {
        if(i == action.payload.index ) {
          void(state.listing[action.payload.index] =
            {...state.listing[action.payload.index],
          quantity:state.listing[action.payload.index].quantity - 1
          } )
        }
      }
      },
      setPaymentPoint : (state,action) =>{
        state.paymentPoint = action.payload
        },  
      changePrice: (state, action) => {
        state.quantity = action.payload
      },
      setDetail: (state, action) => {
        state.detail = action.payload
      },
      setSubTotal: (state, action) => {
          
      },
      setPayId: (state, action) => {
        state.payId = action.payload
      },
      setOrder: (state, action) => {
        state.orderId = action.payload
      },
      setBankLoading: (state, action) => {
        state.bank_loading = action.payload
      }
    },
    extraReducers:{
      [transfer_cart.pending]: (state, action) => {
        state.loading = true;
      },
      [transfer_cart.fulfilled]: (state, action) => {
        state.loading = false;
        state.data = action.payload;
      },
      [request_paymentV2.pending]: (state, action) => {
        state.bank_loading = true;
      },
      [request_paymentV2.fulfilled]: (state, action) => {
        state.bank_loading = true;
        state.request = action.payload;
      },
      [request_paymentV2.rejected]: (state, action) => {
        state.bank_loading = false;
    },
      [bank_list.pending]: (state, action) => {
        state.loading = true;
      },
      [bank_list.fulfilled]: (state, action) => {
        state.loading = false;
        state.banks = action.payload;
      },
      [provider_channels.pending]: (state, action) => {
        state.loading = true;
      },
      [provider_channels.fulfilled]: (state, action) => {
        state.loading = false;
        state.pay_channels = action.payload;
      },

      [getAllChannels.pending]: (state, action) => {
        state.loading = true;
      },
      [getAllChannels.fulfilled]: (state, action) => {
        state.loading = false;
        state.banks = action.payload.data
      },
    }
})

export const {
changePrice,
setDetail,
handleChangeQuantity,
setSubTotal,
setBankName,
setPayId,
setOrder,
setPaymentPoint,
setBankLoading

} = paymentSlice.actions
const { reducer } = paymentSlice
export default reducer;