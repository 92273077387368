import {
  MDBCol,
  MDBRow,
  MDBCheckbox,
  MDBModalBody,
  MDBContainer,
} from "mdb-react-ui-kit";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Skeleton } from "primereact/skeleton";
import { RemoveScroll } from "react-remove-scroll";

//api
import apiServices from "../../../services/api.services";

//component
import ModalBox from "../../../components/modal/full.modal.box";
import CartHeader from "../../../components/header/cart-header";
import InputCount from "../../../components/element/count";
import Button from "../../../components/element/button";
import Text from "../../../components/element/text";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  changePrice,
  setOptionPrice,
  deleteOptionPrice,
  setSubTotal,
  merchantCart,
} from "../../../redux/reducer/menuReducer";
import { closeModalEdit } from "../../../redux/reducer/modalReducer";
import { cartListing, setData } from "../../../redux/reducer/cartReducer";

//formik
import { Formik, Form, FieldArray, useFormikContext } from "formik";
import { Notification } from "../../../helper/react_notification";
import useWindowDimensions from "../../../components/common/window-dimension";

export default function EditFood() {
  const { width } = useWindowDimensions() 
  const deviceId = navigator.userAgent.replace(/[^\w]/gi, "");
  const table = localStorage.getItem("table");
  const [loader , setLoading] =useState(false) 
  const [loaderStatus , setLoderStatus] =useState(false) 

  const dispatch = useDispatch();
  const { option, optionPrice, quantity, loading } = useSelector(
    (state) => state.menu
  );
  const { foodData ,editFormData} = useSelector((state) => state.cart);
  const { picture } = useSelector((state) => state.menu.data);
  const { isOpenEdit } = useSelector((state) => state.modal);
  const [remark, setRemak] = useState({
    cart_item_id: foodData.cart_item_id ? foodData.cart_item_id : "",
    quantity: foodData.quantity ? foodData.quantity : "",
    remark: foodData.remark ? foodData.remark : "",
    option: [],
  });
  const option_list = option.option_listing ? option.option_listing : [];

  const initialValues =
    option_list.length > 0
      ? option_list.reduce(
          (acc, group) => ({
            ...acc,
            ...remark,
            [group.option_id]: [],
          }),
          {}
        )
      : remark;

  const validationSchema = Yup.object().shape(
    option_list.reduce(
      (acc, group) => ({
        ...acc,
        [group.option_id]: Yup.array()
          .min(
            group.option_item_min,
            `Minimum selected ${group.option_item_min} `
          )
          .max(
            group.option_item_max,
            `Maximum selected  ${group.option_item_max} `
          ),
      }),
      {}
    )
  );

  const handleSubmit = async ({ values, setFieldError }) => {
    setLoderStatus(true)
    let newOption = [];
    for (var key in option_list) {
      if( values[option_list[key].option_id]){
        values[option_list[key].option_id].map((item) =>
         newOption.push({ option_item_id: Number(item) })
       );
      }
    }
    try {
      const response = await apiServices.updateFoodCart({
        cart_item_id: values.cart_item_id ? values.cart_item_id : "",
        quantity: quantity ? quantity : "",
        remark: values.remark ? values.remark : "",
        option: newOption,
      });
      if (response) {
    setLoderStatus(false)
        dispatch(setData());
        dispatch(merchantCart({ device_id: deviceId, table_id: table }));
        dispatch(closeModalEdit());
        dispatch(cartListing({ device_id: deviceId }));
        Notification({
          title: "Successful!",
          message: `Cart updated.`,
          type: "success",
        });
      }
    } catch (ex) {
    setLoderStatus(false)
      if (ex && Object.keys(ex).length > 0) {
        let errorMsg = "";
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
              errorMsg = errors[item];
              // Notification({
              //   title: "Error!",
              //   message: `${errorMsg}`,
              //   type: "danger",
              // });
            });
          }
        }
      }
    }
  };

  useEffect(() => {
    dispatch(setSubTotal(parseInt(option.unit_price, 10)));
  }, [quantity, optionPrice, option.unit_price]);

  useEffect(() => {
    foodData.details &&
      foodData.details.map((item) => {
        const optionId = item.option_id ? item.option_id : "";
        if (
          initialValues &&
          initialValues[optionId] !== undefined &&
          initialValues[optionId].includes(item.option_item_id)
        ) {
        } else if (initialValues && initialValues[optionId] !== undefined) {
          initialValues[optionId].push(item.option_item_id);
          setLoading(true)
        }
      });
    dispatch(changePrice(foodData.quantity));
  }, [initialValues]);


  return (
    <RemoveScroll>
      <ModalBox
        show={isOpenEdit}
        className="cart-modal"
        screenSize="fullscreen"
        content={
          <MDBModalBody>
            <Formik
              initialValues={loader ? editFormData :initialValues}
              validateOnChange={true}
              validateOnMount={true}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={(values, { setFieldError, resetForm }) => {
                handleSubmit({ values, setFieldError });
                // resetForm({ values: "" });
              }}
            >
              {({ isSubmitting, setFieldValue, values ,errors}) => (
                <Form>
                  <CartHeader title="Update Order" />
                  {!loading && (
                    <MDBCol className="food-image">
                      <img
                        src={option.picture ? option.picture : picture}
                        className="image"
                        alt="food-image"
                      />
                    </MDBCol>
                  )}
                  {loading ? (
                    <MDBContainer>
                    <Skeleton className="skeleton_image" />
                    <MDBRow className="skeleton_food mt-4">
                      <MDBCol className="skeleton_col justify-content-start">
                        <Skeleton width={ width > 500 ? "80px" : "40%" } height="1.6em" borderRadius="8px" />
                      </MDBCol>
                      <MDBCol className="skeleton_col justify-content-end col-5">
                        <Skeleton width={ width > 500 ? "150px" : "70%" } height="1.6em" borderRadius="8px" />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="skeleton_food mt-3">
                      <MDBCol className="">
                        <Skeleton width={ width > 500 ? "60%" : "90%" }  height="1.3em" borderRadius="8px" />
                        <Skeleton width={ width > 500 ? "70%" : "100%" } height="1.3em" borderRadius="8px" className="mt-3"/>
                      </MDBCol>
                      <MDBCol className="d-flex justify-content-end col-5">
                        <Skeleton width={ width > 500 ? "100px" : "50%" } height="1.3em" borderRadius="8px" />
                      </MDBCol>
                    </MDBRow>
                    <Skeleton width="100%" height="10em" borderRadius="8px" className="mt-5" />
                    <div className="d-flex mt-5">
                      <Skeleton width={ width > 500 ? "250px" : "40%" } height="1.6em" borderRadius="8px" />
                      <Skeleton width={ width > 500 ? "80px" : "20%" } height="1.6em" borderRadius="8px" className="ms-3" />
                    </div>
                    <Skeleton width={ width > 500 ? "55%" : "100%" } height="1.3em" borderRadius="8px" className="mt-4"/>
                  </MDBContainer>
                  ) : (
                    <>
                      <MDBContainer style={{ overflow: 'auto', marginBottom: '0em' }}>
                        <MDBRow className="food-title">
                          <MDBCol className="col-8 title">
                            {option.code ? (
                              <>
                                <label className="_label --code text-center ms-1 mt-0">
                                  {option.code}
                                </label>
                                &nbsp;&nbsp;
                              </>
                            ) : (
                              ""
                            )}
                            <label className="_label --title">
                              {option ? option.name : ""}
                            </label>
                          </MDBCol>
                          <MDBCol>
                            <label className="_label --price">
                              {option
                                ? "RM " +
                                  parseFloat(option.unit_price).toFixed(2)
                                : ""}
                            </label>
                            <p className="_p --price">Base price</p>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow className="food-title">
                          <MDBCol className="col-9">
                            <p className="_p --detail">
                              {option ? option.description : ""}
                            </p>
                          </MDBCol>
                          <MDBCol>
                            {/* <p className="_p --price">Base price</p> */}
                          </MDBCol>
                        </MDBRow>
                        <div className="food-order" style={{ overflow: 'hidden' }}>
                        <FieldArray
                          name="option"
                          render={(arrayHelpers) => (
                            <MDBRow className="addon">
                              {option.option_listing &&
                              option.option_listing.length > 0
                                ? option.option_listing.map((item, index) => {
                                    const max = item.option_item_max;
                                    return (
                                      <MDBContainer
                                        className="addon-container"
                                        key={index}
                                      >
                                      <MDBRow className="addon-item">
                                          <MDBCol className="col-8">
                                            <label className="_label --addon-name">
                                              {item.option_name} &nbsp;
                                              <span className="_span --text">
                                                Option,max{" "}
                                                {item.option_item_max}
                                              </span>
                                            </label>
                                          </MDBCol>
                                          <MDBCol>
                                            {errors &&
                                            errors[item.option_id] ? (
                                              <div className="text-danger text-right no-padding error-message text-end">
                                                <label className="_label --error">
                                                  {errors[item.option_id]}
                                                </label>
                                              </div>
                                            ) : null}
                                          </MDBCol>
                                        </MDBRow>
                                        {item.option_item.map(
                                          (option, option_index) => {

                                          // if (!values[item.option_id]) {
                                          //   values[item.option_id] = [];
                                          // } else  {
                                          //     values[item.option_id] = values[item.option_id];
                                          // }

                                            return (
                                              <MDBRow
                                                className="addon-item"
                                                key={option_index}
                                              >
                                                <MDBCol className="col-9" style={{display : "flex" , alignItems : "center"}}>
                                                  <MDBCheckbox 
                                                   id={`${index} + checkbox2 + ${option_index}`}
                                                    disabled={
                                                      values[item.option_id] !==
                                                      undefined
                                                        ? values[item.option_id].length == max &&
                                                          max !== 1 &&
                                                          !values[
                                                            item.option_id
                                                          ].includes(
                                                            option.option_item_id
                                                          )
                                                        : false
                                                    }
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        dispatch(
                                                          setOptionPrice(
                                                            parseInt(
                                                              option.option_item_price,
                                                              10
                                                            )
                                                          )
                                                        );

                                                        if (
                                                          values[item.option_id] && values[item.option_id].length == max
                                                        ) {
                                                          setFieldValue(
                                                            item.option_id,
                                                            [
                                                              option.option_item_id,
                                                            ]
                                                          );
                                                        } else {
                                                          if(values[item.option_id]){
                                                            setFieldValue(
                                                              item.option_id,
                                                              [
                                                                ...values[
                                                                  item.option_id
                                                                ],
                                                                option.option_item_id,
                                                              ]
                                                            );
                                                          } else {
                                                        setFieldValue(item.option_id, [option.option_item_id]);

                                                          }

                                                          // setFieldValue(
                                                          //   item.option_id,
                                                          //   [
                                                          //     ...values[
                                                          //       item.option_id
                                                          //     ],
                                                          //     option.option_item_id,
                                                          //   ]
                                                          // );
                                                        }
                                                      } else {
                                                        dispatch(
                                                          deleteOptionPrice(
                                                            parseInt(
                                                              option.option_item_price,
                                                              10
                                                            )
                                                          )
                                                        );
                                                        setFieldValue(
                                                          item.option_id,
                                                          values[
                                                            item.option_id
                                                          ].filter(
                                                            (a) =>
                                                              a !==
                                                              option.option_item_id
                                                          )
                                                        );
                                                      }
                                                    }}
                                                    type="checkbox"
                                                    name={item.option_id}
                                                    value={
                                                      option.option_item_id
                                                    }
                                                    checked={
                                                      values &&
                                                      values[item.option_id]
                                                        ? values[
                                                            item.option_id
                                                          ].includes(
                                                            option.option_item_id
                                                          )
                                                        : false
                                                    }
                                                    label={option.option_item_name}
                                                  />
                                                    <label style={{fontSize : "1.4rem" , color : "#83829A"}}
                                                 onClick={() => {
                                                  document.getElementById(`${index} + checkbox2 + ${option_index}`).click();
                                                }}
                                                >{option.option_item_name}</label>
                                                </MDBCol>

                                                <MDBCol>
                                                  <label className="_label --addon-price">
                                                    +{" "}
                                                    {parseFloat(
                                                      option.option_item_price
                                                    ).toFixed(2)}
                                                  </label>
                                                </MDBCol>
                                              </MDBRow>
                                            );
                                          }
                                        )}
                                      </MDBContainer>
                                    );
                                  })
                                : ""}
                            </MDBRow>
                          )}
                        />
                        <MDBRow className="note-1">
                          <label className="_label --note">
                            Note to restaurant &nbsp;
                            <span className="_span --note">Optional</span>
                          </label>
                          <Text
                            name="remark"
                            className="text_remark"
                            placeholder="e.g no pickles"
                          />
                        </MDBRow>
                      </div>
                      </MDBContainer>
                    </>
                  )}
                  <MDBRow className="count_button">
                    <InputCount
                      className="cart-btn"
                      quantity={foodData.quantity}
                      changePrice={changePrice}
                      max={
                        option.quantity_balance !== null
                          ? option.quantity_balance
                          : null
                      }
                    />
                  </MDBRow>
                  <MDBRow className="button">
                    <Button
                      name={"Update"}
                      type="text"
                      className="cart_button"
                      disabled={
                        loading ? true : false || quantity == 0 ? true : false
                      }
                      loading={loaderStatus}
                    />
                  </MDBRow>
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}
