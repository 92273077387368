import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";

//redux
import { useDispatch, useSelector } from "react-redux";
import { openModalAlcoholic2 } from "../../../redux/reducer/modalReducer";
import { setData } from "../../../redux/reducer/alcoholicReducer";

export default function Ticket() {
  const dispatch = useDispatch();
  const { ticket_list } = useSelector((state) => state.menu.alcoholic);
  const { picture } = useSelector((state) => state.menu.data);

  return (
    <MDBContainer className="ticket-component">
      <MDBRow>
        <MDBCol className="col-12 ticket-title">
          <label className="_label --title">My Drink Ticket&nbsp;&nbsp;</label>
          <Icon icon="ion:ticket-outline" className="icon" />
        </MDBCol>
        <MDBCol className="col-12 ticket-text">
          <label className="_label --text">
            Click the claim button to submit order to the kitchen
          </label>
        </MDBCol>
      </MDBRow>
      <MDBContainer className="ticket-claim">
        {ticket_list && ticket_list.length > 0 ? (
          ticket_list.map((item, index) => {
            return (
              <MDBRow key={index} className="ticket-list">
                <MDBCol className="col-3 image">
                  <img
                    src={item.picture || picture}
                    className="food-image"
                    alt="ticket-png"
                  />
                </MDBCol>
                <MDBCol className="col-6">
                  <MDBRow>
                    <label className="_label --name">{item.alcohol_name}</label>
                  </MDBRow>
                  <MDBRow>
                    <label className="_label --quantity">
                      x {item.alcohol_balance}
                    </label>
                  </MDBRow>
                  <MDBRow>
                    <label className="_label --date">
                      {item.expired_at
                        ? "Expried date : " + item.expired_at
                        : ""}
                    </label>
                  </MDBRow>
                </MDBCol>
                <MDBCol className="col-3 claim_button">
                  <MDBBtn
                    onClick={() => {
                      dispatch(openModalAlcoholic2());
                      dispatch(setData(ticket_list[index]));
                    }}
                  >
                    Claim
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            );
          })
        ) : (
          <MDBRow>
            <MDBCol className="col-7">
              <Icon icon="material-symbols:add-box-outline" height="1.4em" />{" "}
              <span>-</span>
            </MDBCol>
            <MDBCol className="col-5">
              <label className="_label --date">Expried date : -</label>
            </MDBCol>
          </MDBRow>
        )}
      </MDBContainer>
    </MDBContainer>
  );
}
