import { useId, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import { MDBContainer, MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { toast } from "react-toastify";

//component
import Text from "../../../components/element/text";
import Button from "../../../components/element/button";

//redux
import { useDispatch, useSelector } from "react-redux";
import { closeModalLogin } from "../../../redux/reducer/modalReducer";
import { changeLogin } from "../../../redux/reducer/loginReducer";
import { profile } from "../../../redux/reducer/profileReducer";
//auth
import authServices from "../../../services/auth.services";
import { Notification } from "../../../helper/react_notification";

export default function PasswordForm() {
  const { login, userId } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const cart = localStorage.getItem("cart");
  const [userData, setUserData] = useState({
    phone_number: userId,
    password: "",
  });

  const loginSchema = Yup.object({
    password: Yup.string().required("Required"),
  });

  const handleSubmit = async ({ values, setFieldError }) => {
    try {
      const response = await authServices.setPassword(values);
      if (response) {
        toast.success("Set Password Success", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "light",
        });
        try {
          const response = await authServices.login({
            username: values.phone_number,
            password: values.password,
            cart_id: cart ? cart : "",
          });
          if (response) {
            dispatch(closeModalLogin());
            dispatch(changeLogin("Login"));
            dispatch(profile());
            // toast.info("Welcome " + values.phone_number, {
            //   position: "top-center",
            //   autoClose: 2000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: false,
            //   draggable: true,
            //   theme: "light",
            // });
            Notification({
              title: "Login Successful!",
              message: `Welcome, ${values.phone_number}`,
              type: "success",
            })
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            if (ex.response.status === 422) {
              const errors = ex.response.data.errors;
              if (errors && Object.keys(errors).length > 0) {
                Object.keys(errors).map((item, i) => {
                  setFieldError(item, errors[item]);
                });
              }
            }
          }
        }
      }
    } catch (ex) {
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
            });
          }
        }
      }
    }
  };

  return (
    <Formik
      initialValues={userData}
      validationSchema={loginSchema}
      onSubmit={(values, { resetForm, setFieldError }) => {
        handleSubmit({ values, setFieldError });
        resetForm({ values: "" });
      }}
    >
      {({ errors, isValid, resetForm, isSubmitting, setFieldError }) => (
        <Form>
          <MDBContainer className="login-body">
            <MDBRow>
              <label className="_label --note">Verification Success !</label>
            </MDBRow>
            <MDBRow>
              <label className="_label --text">
                {login == "Password"
                  ? " Create your password"
                  : "Reset your password"}
              </label>
            </MDBRow>
            <MDBRow className="tag-div">
              <label className="_label --tag">Password</label>
            </MDBRow>
            <Text
              className="login-field"
              name="password"
              type="password"
              placeholder="Enter Password"
            />
            <MDBRow>
              <Button
                type={"submit"}
                name="Continue"
                className="login_button"
              />
            </MDBRow>
          </MDBContainer>
        </Form>
      )}
    </Formik>
  );
}
