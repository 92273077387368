import http from "./http.services"
import authServices from "./auth.services"

async function scanQr(payload) {
  const response = await http.post(process.env.REACT_APP_API_URL + `/guest`, 
    payload
  )
  return response.data
}

async function changePassword(payload) {
    http.setToken(authServices.getToken());
    const response = await http.post(process.env.REACT_APP_API_URL + `/change_password`, 
      payload
    )
    return response.data
  }

  async function changePhone(payload) {
    http.setToken(authServices.getToken());
    const response = await http.post(process.env.REACT_APP_API_URL + `/change_phone_number`, 
      payload
    )
    return response.data
  }

  async function userProfile() {
    http.setToken(authServices.getToken());
    const response = await http.get(process.env.REACT_APP_API_URL + `/profile`
    )
    return response.data
  }

  async function updateProfile(payload) {
    http.setToken(authServices.getToken());
    const response = await http.post(process.env.REACT_APP_API_URL + `/update_profile`,payload
    )
    return response.data
  }

  async function merchantCard(payload) {
    http.setToken(authServices.getToken());
    const response = await http.get(process.env.REACT_APP_API_URL + `/merchant_card/${payload}`
    )
    return response.data
  }

  async function foodListing({table_id,remark,}) {
    http.setToken(authServices.getToken());
    const response = await http.get(process.env.REACT_APP_API_URL + `/display_menu?table_id=${table_id}&remark=${remark}`
    )
    return response.data
  }

  async function optionListing({menu_id}) {
    http.setToken(authServices.getToken());
    const response = await http.get(process.env.REACT_APP_API_URL + `/option_listing/${menu_id}`,
    )
    return response.data
  }

  async function categoryListing({table_id,remark}) {
    http.setToken(authServices.getToken());
    const response = await http.get(process.env.REACT_APP_API_URL + `/category_listing?table_id=${table_id}&remark=${remark}`,
    )
    return response.data.data
  }

  async function addToCart(payload) {
    http.setToken(authServices.getToken());
    const response = await http.post(process.env.REACT_APP_API_URL + `/cart`,payload
    )
    return response.data
  }

  async function updateFoodCart(payload) {
    http.setToken(authServices.getToken());
    const response = await http.post(process.env.REACT_APP_API_URL + `/cart/update_cart`,payload
    )
    return response.data
  }

  async function cartListing(payload) {
    http.setToken(authServices.getToken());
    const response = await http.post(process.env.REACT_APP_API_URL + `/cart/display_cart?device_id=${payload.device_id}` ,
    )
    return response.data
  }

  async function updateCart(payload) {
    http.setToken(authServices.getToken());
    const response = await http.post(process.env.REACT_APP_API_URL + `/cart/update_cart` , payload,
    )
    return response.data
  }

  async function transactionOrder(payload) {
    http.setToken(authServices.getToken());
    const response = await http.post(process.env.REACT_APP_API_URL + `/transaction/make_payment` ,  payload
    )
    return response.data
  }

  async function orderListing(payload) {
    http.setToken(authServices.getToken());
    const response = await http.get(process.env.REACT_APP_API_URL + `/order?device_id=${payload.device_id}` , 
    )
    return response.data
  }

  async function claimTicket(payload) {
    http.setToken(authServices.getToken());

    const response = await http.post(process.env.REACT_APP_API_URL + `/claim_ticket` , payload
    )
    return response.data
  }

  async function purchaseHistory(payload) {
    http.setToken(authServices.getToken());
    const response = await http.post(process.env.REACT_APP_API_URL + `/purchase_history` ,payload
    )
    return response.data
  }

  async function ticketHistory(payload) {
    http.setToken(authServices.getToken());
    const response = await http.post(process.env.REACT_APP_API_URL + `/ticket_history`  , payload
    )
    return response.data
  }

  async function cashbackData() {
    http.setToken(authServices.getToken());
    const response = await http.post(process.env.REACT_APP_API_URL + `/get_wallet_balance` 
    )
    return response.data
  }

  async function walletHistory(payload) {
    http.setToken(authServices.getToken());
    const response = await http.post(process.env.REACT_APP_API_URL + `/get_wallet_history` , payload
    )
    return response.data
  }

  async function purchaseHistoryDetail(id) {
    http.setToken(authServices.getToken());
    const response = await http.post(process.env.REACT_APP_API_URL + `/history_detail` ,id
    )
    return response.data
  }

  async function withdrawWallet(payload) {
    http.setToken(authServices.getToken());
    const response = await http.post(process.env.REACT_APP_API_URL + `/withdraw_wallet` ,payload 
    )
    return response.data
  }

  async function sameFood(payload) {
    http.setToken(authServices.getToken());
    const response = await http.post(process.env.REACT_APP_API_URL + `/same_menu_existed/${payload.menu_id}` ,{user_id : payload.user_id }
    )
    return response.data
  }

  async function merchantCart(payload) {
    http.setToken(authServices.getToken());
    const response = await http.get(process.env.REACT_APP_API_URL + `/user_merchant_cart/${payload.table_id}?device_id=${payload.device_id}` ,
    )
    return response.data
  }

  async function cartItem(payload) {
    http.setToken(authServices.getToken());
    const response = await http.get(process.env.REACT_APP_API_URL + `/count_cart_item?device_id=${payload.device_id}` ,
    )
    return response.data
  }

  async function bankList(payload) {
    http.setBank(payload);
    http.setToken(authServices.getToken());
    const response = await http.get(process.env.REACT_APP_API_URL + `/get_allowed_tenant_channel_id?table_id=${payload.table}&amount=${payload.amount}` , 
    ) 
    return response.data.data
  }

  async function providerChannels(payload) {
    http.setBank(payload.token);
    http.setToken(authServices.getToken());
    const response = await http.get(process.env.REACT_APP_API_URL + `/get_provider_channel_id/${payload.token}/${payload.channel}?table_id=${payload.table_id}` , 
    ) 
    return response.data
  }

  async function orderCart(payload) {
    http.setToken(authServices.getToken());
    const response = await http.post(process.env.REACT_APP_API_URL + `/transfer_cart_into_order` , payload
    )
    return response.data
  }

  async function requestPayment(payload) {
    // http.setToken(authServices.getToken());
    http.setToken(authServices.getToken());
    http.setPayment(localStorage.getItem("signature"));
    const response = await http.post(`https://staging-payments.commerce.asia/api/services/app/PaymentGateway/RequestPayment` , payload
    )
    return response.data
  }

  async function requestPaymentV2(payload) {
    http.setToken(authServices.getToken());
    const response = await http.post(process.env.REACT_APP_API_URL + `/transaction/generate_order_payment` , payload
    )
    return response.data
  }


  export default {
    scanQr ,
    changePassword , 
    changePhone, 
    userProfile , 
    merchantCard , 
    foodListing , 
    updateProfile ,
    optionListing , 
    categoryListing , 
    addToCart , 
    cartListing , 
    updateCart ,
    transactionOrder ,
    orderListing , 
    claimTicket , 
    purchaseHistory , 
    ticketHistory , 
    cashbackData,
    purchaseHistoryDetail,
    withdrawWallet,
    sameFood,
    merchantCart,
    cartItem,
    walletHistory,
    bankList,
    orderCart,
    requestPayment,
    providerChannels,
    requestPaymentV2,
    updateFoodCart
  }

  