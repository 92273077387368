import { useState, useEffect } from "react";
import { RemoveScroll } from "react-remove-scroll";
import {
  MDBCol,
  MDBRow,
  MDBModalBody,
  MDBRadio,
  MDBContainer,
} from "mdb-react-ui-kit";
import moment from "moment/moment";
import React, { lazy, Suspense } from "react";
import { Icon } from "@iconify/react";
import InfiniteScroll from "react-infinite-scroll-component";

//assets
import Lottie from "lottie-react";
import { Logo2 } from "../../assets/images"

//component
import ModalBox from "../../components/modal/full.modal.box";
import ModalHeader from "../../components/header/modal.header";

//redux
import { useSelector, useDispatch } from "react-redux";
import { openModalReceipt2 } from "../../redux/reducer/modalReducer";
import { purchase_history } from "../../redux/reducer/reportReducer";
import { purchaseReceipt } from "../../redux/reducer/reportReducer";

import useWindowDimensions from "../../components/common/window-dimension";
import { formatNumber } from "../../helper/return_number";

export default function ModalPurchaseHistory() {
  const dispatch = useDispatch();
  const [id, setId] = useState(0);
  const { purchase, purchase_loading } = useSelector((state) => state.report);
  const { isOpenPurchaseHistory } = useSelector((state) => state.modal);

  const changeId = (no) => {
    setId(no);
  };

  const [showPurchase, setShowPurchase] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const [length, setLength] = useState(10);
  const [start, setStart] = useState(0);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const { height } = useWindowDimensions()

  const getMoreData = () => {
    dispatch(purchase_history({
      length : length,
      start : start + 10
    }))
    .unwrap()
    .then((res) => {
      setStart(start + 10);
      let newData = [];
      for (var key in res.aaData) {
        newData.push({
          ...res.aaData[key],
        });
      }
      if (data.length + newData.length >= res.iTotalRecords) {
        setHasMore(false);
      }
      setData([...data, ...newData]);
    })
    .catch((ex) => {});

  }

  useEffect(() => {
    if (id) {
      dispatch(openModalReceipt2());
      dispatch(purchaseReceipt({ order_id: id }));
    }
    if (id !== 0) {
      setId(0);
    }
  }, [id]);

  useEffect(() => {
    setLoader(true)
    dispatch(purchase_history({
      length: length,
      start: start,
    }))
    .unwrap()
    .then((res) => {
      setData(res.aaData);
      setLoader(false)
      if (data.length !== res.iTotalRecords) {
      setHasMore(true)
      }
    })
    .catch((ex) => {});
  }, []);

  let scrollHiegth = height - 88.8

  return (
    <RemoveScroll>
      <ModalBox
        scrollable={true}
        show={isOpenPurchaseHistory}
        screenSize="fullscreen-xl-down"
        className="purchase-modal"
        content={
          <MDBModalBody>
            <ModalHeader title="Purchase History" />
            <MDBContainer className="purchase-container">
            <InfiniteScroll 
              // style={{overflow : "unset"}}
              dataLength={data.length}
              height={`${scrollHiegth}px`}
              hasMore={hasMore}
              next={() => {
              getMoreData();
              }}
             loader={data.length > 0 && <h4 className="text-center mt-5" style={{color : "black"}}>Loading...</h4>}
      >
        {loader ? 
          <div style={{display : "flex" , marginTop : "3em" , marginBottom : "5em", alignItems: "center" , justifyContent : "center"}}>
     <Icon icon="line-md:loading-twotone-loop" color="#e26368"  height={"2.5em"}/>
   </div>  :  

   !loader && data && data.length > 0 ? data.map((item, index) => {
    return (
      <MDBRow
        key={index}
        className="listing-div"
        onClick={() => {
          changeId(item.order_id);
        }}
      >
        <MDBCol className="col-5">
          <MDBRow>
            <label className="_label --name">
              {item.merchant_name}
            </label>
          </MDBRow>
          <MDBRow>
            <label className="_label --date">
              at &nbsp;
              {moment(item.created_at).format(
                "DD MMM YYYY h:mm A"
              )}
            </label>
          </MDBRow>
          <MDBRow>
            <label className="_label --order_no">
              Order No.{item.order_no}
            </label>
          </MDBRow>
        </MDBCol>

        <MDBCol className="col-4 col-center">
          <MDBRow>
            <label className="_label --ticket">Stamp</label>
          </MDBRow>
          <MDBRow>
            <label className="_label --no">
              {parseFloat(item.cashback).toFixed(2)}
            </label>
          </MDBRow>
        </MDBCol>
        <MDBCol className="col-center">
          <label className="_label --price">
            RM {formatNumber(item.total_price)}
          </label>
        </MDBCol>
      </MDBRow>
    );
  })

  : 
  <div className="d-flex flex-column align-items-center empty-div" style={{ background: 'transparent' }}>
  <div style={{ height: '75%', textAlign: 'center' }}>
    <div className="loading-lottie">
    <img
      src={Logo2}
      className="logo-image"
      alt="logo-image"
      fetchpriority="high"
    />
    </div>
    <div className="loading-div d-flex justify-content-center">
      No Data Available
    </div>
  </div>
</div>
      }
        </InfiniteScroll>
            
            </MDBContainer>
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}


