import React from "react";
import {
  Route,
  Navigate,
  useLocation,
  useSearchParams,
  useParams,
} from "react-router-dom";

const ProtectedRoute = () => {
  const { pathname } = useLocation();

  return pathname !== "/" ? "" : <Navigate to="/scan-qr" replace></Navigate>;
};

export default ProtectedRoute;
