import { MDBContainer, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

//REDUX
import { useDispatch } from "react-redux";
import {
  closeAllModal,
  closeModalReceipt,
  closeModalReceipt2,
  closeModalReceipt3,
} from "../../redux/reducer/modalReducer";

export default function ModalHeader({ title, getImage }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <MDBRow className="modal-header-container">
      <MDBCol className="col-2 icon-back">
        <Icon
          icon="eva:arrow-ios-back-fill"
          className="icon"
          color="darkgray"
          width="30"
          height="27"
          onClick={() => {
            title !== "Receipt"
              ? dispatch(closeAllModal())
              : dispatch(closeModalReceipt2());
            dispatch(closeModalReceipt3());
            dispatch(closeModalReceipt());
          }}
        />
      </MDBCol>
      <MDBCol
        className={title ? "col-8 text-center p-0" : "col-6 text-center p-0"}
      >
        <label className="_label ">{title}</label>
      </MDBCol>
      <MDBCol className="col-2">
        {title == "Receipt" ? (
          <Icon
            icon="fa-solid:share-alt"
            height="20"
            onClick={() => getImage()}
          />
        ) : (
          ""
        )}
      </MDBCol>
    </MDBRow>
  );
}
