import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import apiServices from "../../services/api.services";

export const card = createAsyncThunk("get/card", async (payload) => {
  const response = await apiServices.merchantCard(payload);
  return response.data;
});

export const food = createAsyncThunk("get/food", async ({remark,table_id}) => {
  const response = await apiServices.foodListing({remark,table_id});
  return response.data;
});

export const alcoholic = createAsyncThunk("get/alcoholic", async ({remark,table_id}) => {
  const response = await apiServices.foodListing({remark,table_id});
  return response.data;
});

export const f_category = createAsyncThunk("get/category/food", async ({table_id,remark}) => {
  const response = await apiServices.categoryListing({table_id,remark});
  return response;
});

export const category = createAsyncThunk("get/category", async ({table_id,remark}) => {
  const response = await apiServices.categoryListing({table_id,remark});
  return response;
});

export const option = createAsyncThunk("get/option", async (menu_id) => {
  const response = await apiServices.optionListing(menu_id);
  return response.data
});

export const same = createAsyncThunk("get/same", async (payload) => {
  const response = await apiServices.sameFood(payload);
  return response.data
});

export const total_item = createAsyncThunk("get/total_item", async (payload) => {
  const response = await apiServices.cartItem(payload);
  return response.data
});

export const merchantCart = createAsyncThunk("get/merchant_cart", async (payload) => {
  const response = await apiServices.merchantCart(payload);
  return response.data
});

const initialState = {
  data:{
    description : "",
    display_address : "",
    open_at : "",
    close_at : "",
    display_name : "",
  },
  food:[],
  alcoholic:[],
  loading:false,
  category:[],
  f_category:[],
  option:{},
  same:{},
  total:"",
  cart:{},
  food_id:{
    id : "",
    name : "", 
    price : "",
    remark : ""
},
  detail:{},
  quantity:1,
  subTotal:0,
  optionPrice:0,
  maxfoodQuantity:0,
  searchValue : "" , 
  is_searching : false,
  loadingPage : false,
  animationIcon : false, 
  rejectedStatus : false,
  card_loading: false,
  food_loading: false,
  alcoholic_loading: false,
}
const menuSlice = createSlice({
    name: "menu",
    initialState,
    reducers: {
      changePrice: (state, action) => {
        state.quantity = action.payload 
      },
      setOptionPrice: (state, action) => {
        state.optionPrice = state.optionPrice + action.payload;
      },
      clearOptionPrice: (state, action) => {
        state.optionPrice =  action.payload;
      },
      changeTotalFoodPrice: (state, action) => {
        state.optionPrice =  action.payload;
      },
      deleteOptionPrice: (state, action) => {
        state.optionPrice = state.optionPrice - action.payload ;
      },
      setDetail: (state, action) => {
        state.detail = action.payload
      },
      setSubTotal :(state , action) =>{
        state.subTotal = (state.optionPrice + action.payload) * state.quantity
      },
      setFoodId :(state , action) =>{
        state.food_id =  action.payload 
      },
      clearOption :(state , action) =>{
        state.option =  action.payload 
      },
      setMaxfoodQuantity :(state , action) =>{
        state.maxfoodQuantity =  action.payload 
      },
      setSearchValue :(state , action) =>{
        state.searchValue =  action.payload 
      },
      setSearching :(state , action) =>{
        state.is_searching
        =  action.payload 
      },
      setLoadingPage :(state , action) =>{
        state.loadingPage
        =  action.payload 
      },
      setAnimationIcon :(state , action) =>{
        state.animationIcon
        =  action.payload 
      }
      
    },
    extraReducers:{
      [card.pending]: (state, action) => {
        state.card_loading = true;
      },
      [card.fulfilled]: (state, action) => {
        state.card_loading = false;
        state.data = action.payload;
      },
      [card.rejected]: (state, action) => {
        state.card_loading = false
        state.rejectedStatus = true
      },
      [food.pending]: (state, action) => {
        state.food_loading = true;
        state.rejectedStatus = false
      },
      [food.fulfilled]: (state, action) => {
        state.food_loading = false;
        state.food = action.payload;
      },
       [food.rejected]: (state, action) => {
        state.food_loading = false
        state.rejectedStatus = true
    },
      [alcoholic.pending]: (state, action) => {
        state.alcoholic_loading = true;
        state.rejectedStatus = false
      },
      [alcoholic.fulfilled]: (state, action) => {
        state.alcoholic_loading = false;
        state.alcoholic = action.payload
      },
      [alcoholic.rejected]: (state, action) => {
        state.alcoholic_loading = false
        state.rejectedStatus = true
      },
      [category.pending]: (state, action) => {
        state.loading = true;
        state.category = [];
      },
      [category.fulfilled]: (state, action) => {
        state.loading = false;
        state.f_category = action.payload;
        state.category = action.payload;
      },
      [f_category.pending]: (state, action) => {
        state.loading = true;
        state.f_category = [];

      },
      [f_category.fulfilled]: (state, action) => {
        state.loading = false;
        state.f_category = action.payload;
      },
      [option.pending]: (state, action) => {
        state.loading = true;
        state.option = [];

      },
      [option.fulfilled]: (state, action) => {
        state.loading = false;
        state.option = action.payload;
      },
      [same.pending]: (state, action) => {
        state.loading = true;
      },
      [same.fulfilled]: (state, action) => {
        state.loading = false;
        state.same = action.payload;
      },
      [total_item.pending]: (state, action) => {
        state.loading = true;
      },
      [total_item.fulfilled]: (state, action) => {
        state.loading = false;
        state.total = action.payload;
      },
      [merchantCart.pending]: (state, action) => {
        state.loading = true;
      },
      [merchantCart.fulfilled]: (state, action) => {
        state.loading = false;
        state.cart = action.payload;
      },
    }
})

export const {
setDetail,
changePrice,
setOptionPrice,
deleteOptionPrice,
setSubTotal,
setFoodId,
clearOptionPrice,
clearOption,
setMaxfoodQuantity,
setSearchValue,
setSearching,
setLoadingPage,
setAnimationIcon,
changeTotalFoodPrice
} = menuSlice.actions
const { reducer } = menuSlice
export default reducer;