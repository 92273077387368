import { useEffect, useState } from "react";
import { Banner } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import Lottie from "lottie-react";
import Loading from "../../assets/json/qr_expried.json";

//component
import ModalPurchaseHistory from "../Modal/purchase.history";
import ModalReceipt from "../Modal/receipt";
import ModalClaimHistory from "../Modal/claim.history";
import ModalAccount from "../Modal/account";
import ModalCashback from "../Modal/cashback";
import ModalChangePhone from "../Modal/change.phone";
import ModalInformation from "../Modal/information";
import ModalPurchaseReceipt from "../Modal/purchase.receipt";
import ModalClaimReceipt from "../Modal/claim.receipt";
import Login from "../Login";
import PartialRegisterModal from "../../components/modal/partial.register.modal";
import Guest from "../Modal/guest";
import PreRegister from "../Modal/register";
import { Logo2 , Footer } from "../../assets/images";

//redux
import { useDispatch, useSelector } from "react-redux";
import { merchantCart } from "../../redux/reducer/menuReducer";
import { setLoadingPage } from "../../redux/reducer/menuReducer";
import { setSelectedFile } from "../../redux/reducer/commonReducer";

//api
import apiServices from "../../services/api.services";

//width 
import useWindowDimensions from "../../components/common/window-dimension";

export default function ScanQr() {
  const {
    isOpenLogin,
    isOpenReceipt,
    isOpenReceipt2,
    isOpenPurchaseHistory,
    isOpenClamHistory,
    isOpenAccount,
    isOpenChangePhone,
    isOpenCashBack,
    isOpenInformation,
    isOpenReceipt3,
  } = useSelector((state) => state.modal);
  const { picture } = useSelector((state) => state.menu.data);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showBottom, setShowBottom] = useState(false);
  const { width , height } = useWindowDimensions()

  const deviceId = navigator.userAgent.replace(/[^\w]/gi, "");
  const expiredTime = Date.now();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const table_id = searchParams.get("table");
  const table = localStorage.getItem("table");
  const token = localStorage.getItem("token");
  const p_guest = localStorage.getItem("p_guest");
  const guest = localStorage.getItem("guest");
  const new_guest = localStorage.getItem("new_guest");

  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const showModalUp = () => {
    setShowBottom(!showBottom);
  };

  const handleSubmit2 = async () => {
    dispatch(setLoadingPage(true));
    try {
      const response = await apiServices.scanQr({
        table_id: table_id ? table_id : table,
      });
      if (response) {
        const newToken = response.data.token ;
         if (table_id && !table) {
          localStorage.setItem("guest", response.data.token);
        }
        try {
          const response = await apiServices.merchantCard(table_id);
          if (response) {
           dispatch(setLoadingPage(false));
            localStorage.setItem("expired", expiredTime + 7200000);
            localStorage.setItem("table", table_id ? table_id : table);
            navigate("/menu");
          }
        } catch (ex) {
          if (ex && ex.response.status === 422) {
            const errors = ex.response.data.errors;
          } else if (ex && ex.response.status === 401){
        dispatch(setLoadingPage(false));
             localStorage.removeItem("token")
             localStorage.removeItem("p_guest")
             localStorage.setItem("guest" , newToken)
             dispatch(setLoadingPage(true));
             localStorage.setItem("expired", expiredTime + 7200000);
             localStorage.setItem("table", table_id ? table_id : table);
             navigate("/menu");
          }
        }
     
      }
    } catch (ex) {
      navigate("/");

      if (ex && Object.keys(ex).length > 0) {
        let errorMsg = "";
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              errorMsg = errors[item];
              if (
                errorMsg[0] ==
                "table_id is currently unavailable. Please scan QR code from another table, thank you."
              ) {
              }
            });
          }
        }
      }
    }
  };
  

  useEffect(() => {
    if (table_id !== null) {
      handleSubmit2();
      // dispatch(card(table_id));
    }
    if (token && table_id === null || p_guest && table_id === null) {
      dispatch(
        merchantCart({ device_id: deviceId, table_id: table ? table : 1 })
      );
    }
  }, [table_id]);

  useEffect(() => {
    const image = new Image();
    image.src = picture || Banner;
    image.onload = () => {
      setIsImageLoaded(true);
    };
  }, [picture, Banner]);

  return (
    <div className="home-div">
      {!table_id && (
        <>
          <div style={{background : "#ffffff" ,height : height , width : width}}>
           <MDBContainer style={{padding : "0em 1.2em"}}>
            <div className="logo-div">
           <img
              src={Logo2}
              alt="foodlink"
              className="logo-image"
              loading="lazy"
            />
            </div>
            <div className="box-div">
              <MDBRow><label className="_label --big">Access via QR Code only</label></MDBRow>
              <MDBRow className="mt-3"><label className="_label --small">Please Request for a QR code from the restaurant stuff to place order</label></MDBRow>
            </div>
           </MDBContainer>
           <div className={width > 440 ? "footer-div desktop" : "footer-div"} style={{backgroundImage : width < 440 ? `url(${Footer})` : "" , height : height - 350}}>
              <div className="text-center">
                <label className="_label --scan"><span style={{color : width > 440 ? "#ed1c24" : ''}}>S</span>can <span style={{color : width > 440 ? "#ed1c24" : ''}}>O</span>rder <span style={{color : width > 440 ? "#ed1c24" : ''}}>E</span>arn</label>
              </div>
              <div className="lottie-div mt-3">
              <Lottie animationData={Loading} />
              </div>
            </div>
          </div>
          {token || p_guest ? (
              <PartialRegisterModal
                onDismiss={showModalUp}
                showBottom={showBottom}
                show={true}
                content={
                  <Guest
                    showBottom={showBottom}
                    setShowBottom={setShowBottom}
                    name="/"
                    table_id={table_id}
                  />
                }
              />
            ) : (
              <PartialRegisterModal
                show={true}
                register={true}
                content={<PreRegister name="/" />}
              />
            )}
        </>
      )}
      {isOpenLogin && <Login />}
      {isOpenPurchaseHistory && <ModalPurchaseHistory />}
      {isOpenReceipt && <ModalReceipt />}
      {isOpenClamHistory && <ModalClaimHistory />}
      {isOpenReceipt2 && <ModalPurchaseReceipt />}
      {isOpenReceipt3 && <ModalClaimReceipt />}
      {isOpenCashBack && <ModalCashback />}
      {isOpenAccount && <ModalAccount />}
      {isOpenChangePhone && <ModalChangePhone />}
      {isOpenInformation && <ModalInformation title="Order Time" />}
    </div>
  );
}
