import * as Yup from "yup";
import moment from "moment";
import { useState } from "react";
import { Formik, Form } from "formik";
import { RemoveScroll } from "react-remove-scroll";
import { MDBRow, MDBModalBody, MDBContainer } from "mdb-react-ui-kit";

//component
import ModalBox from "../../components/modal/full.modal.box";
import ModalHeader from "../../components/header/modal.header";
import Text from "../../components/element/text";
import Select from "../../components/element/select";
import Button from "../../components/element/button";
import ModalUpdateProfile from "./update.profile";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  closeAllModal,
  openModalUpdateProfile,
  closeModalUpdateProfile,
} from "../../redux/reducer/modalReducer";
import { profile } from "../../redux/reducer/profileReducer";
//api
import apiServices from "../../services/api.services";
import { Notification } from "../../helper/react_notification";

export default function ModalAccount() {
  const { isOpenAccount } = useSelector((state) => state.modal);

  const { phone_number, name, email, dob, gender } = useSelector(
    (state) => state.profile.data
  );

  const { cart } = useSelector((state) => state.menu);
  const { userno} = useSelector((state) => state.profile);

  const [loading , setLoading] = useState(false)

  const dispatch = useDispatch();
  const today = new Date();
  const [userData, setUserData] = useState({
    date_of_birth:
      dob && dob !== null
        ? moment(dob, "DD/MM/YYYY").format("YYYY-MM-DD")
        : moment(today).format("YYYY-MM-DD"),
    email: email ? email : "",
    gender: gender ? gender : "",
    name: name ? name : "",
    phone_number:userno !== "" ? userno  : cart.username ? cart.username : phone_number ? phone_number : "",
  });

  const dataSchema = Yup.object({
    email: Yup.string()
      .required("Email is required")
      .email("Invalid Email format"),
    name: Yup.string().required("Name is required"),
    gender: Yup.string().required("Required"),
    date_of_birth: Yup.date()
      .max(new Date(), "Date of birth cannot be a future date.")
      .required("Required"),
  });

  const genderList = [
    { label: "male", id: "male" },
    { label: "female", id: "female" },
  ];

  const openUpdateModal = () => {
    dispatch(openModalUpdateProfile());
  };

  const handleSubmit = async ({ values, setFieldError }) => {
    setLoading(true);
    dispatch(closeModalUpdateProfile());
    try {
      const response = await apiServices.updateProfile({
        date_of_birth: moment(values.date_of_birth).format("DD/MM/YYYY"),
        email: values.email,
        gender: values.gender,
        name: values.name,
      });
      if (response) {
        setLoading(false);
        dispatch(profile());
        dispatch(closeAllModal());
        Notification({
          title: "Successful!",
          message: `Profile updated.`,
          type: "success",
        });
      }
    } catch (ex) {
      setLoading(false);
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
            });
          }
        }
      }
    }
  };

  return (
    <RemoveScroll>
      <Formik
        initialValues={userData}
        validationSchema={dataSchema}
        onSubmit={(values, { resetForm, setFieldError }) => {
          openUpdateModal({ values, setFieldError });
          // resetForm({ values: "" });
        }}
      >
        {({ values, errors, setFieldError }) => (
          <Form>
            <ModalBox
              show={isOpenAccount}
              screenSize="fullscreen-xl-down"
              className="account-modal"
              content={
                <MDBModalBody>
                  <ModalHeader title="My Account" />
                  <MDBContainer className="account-container">
                    <MDBRow>
                      <Text
                        type="email"
                        name="email"
                        className="service-field"
                        placeholder={email !== null ? "" : "Email"}
                        as
                        disabled={email !== null ? true : false}
                      />
                    </MDBRow>
                    <MDBRow>
                      <Text
                        name="name"
                        className="service-field"
                        placeholder={name !== null ? "" : "Full Name has be IC"}
                        as
                        disabled={name !== null ? true : false}
                        errors={errors}
                      />
                    </MDBRow>
                    <MDBRow>
                      <Text
                        type="date"
                        name="date_of_birth"
                        className="service-field"
                        placeholder={dob !== null ? "" : "Date of birth"}
                        as
                        disabled={dob !== null ? true : false}
                        errors={errors}
                      />
                    </MDBRow>
                    <MDBRow>
                      <Select
                        name="gender"
                        label={gender !== null ? "" : "Gender"}
                        errors={errors}
                        className="gender"
                        options={genderList}
                        placeholder="Please select your gender"
                        disabled={gender !== null ? true : false}
                      />
                    </MDBRow>
                    <MDBRow>
                      <Text
                        name="phone_number"
                        className="service-field"
                        as
                        disabled={true}
                      />
                    </MDBRow>
                    <MDBRow>
                      <label className="_label --noted">
                        Noted: Birthday and Gender only allowed limited changes
                        attempt. Please make sure to fill in the correct
                        information.
                      </label>
                    </MDBRow>
                    <MDBRow className="btn-row">
                      {email == null && (
                        <Button
                          type={"submit"}
                          name="Save"
                          className="login_button"
                          loading={loading}
                        />
                      )}
                    </MDBRow>
                  </MDBContainer>
                </MDBModalBody>
              }
            />
            <ModalUpdateProfile
              handleSubmit={handleSubmit}
              values={values}
              setFieldError={setFieldError}
            />
          </Form>
        )}
      </Formik>
    </RemoveScroll>
  );
}
