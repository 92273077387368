import * as Yup from "yup";
import { useState } from "react";
import { Formik, Form } from "formik";
import { RemoveScroll } from "react-remove-scroll";
import { MDBRow, MDBModalBody, MDBContainer } from "mdb-react-ui-kit";

//component
import Text from "../../components/element/text";
import Code from "../../components/element/code";
import Button from "../../components/element/button";
import ModalBox from "../../components/modal/full.modal.box";
import ModalHeader from "../../components/header/modal.header";

//redux
import { useSelector, useDispatch } from "react-redux";
import { closeAllModal } from "../../redux/reducer/modalReducer";
import { profile } from "../../redux/reducer/profileReducer";

//api
import apiServices from "../../services/api.services";
import { Notification } from "../../helper/react_notification";

export default function ModalChangePhone() {
  const { isOpenChangePhone } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const [time, setTime] = useState(0);
  const [userData, setUserData] = useState({
    username: "",
    verification_code: "",
    type: "phone verification",
  });
  const [loading, setLoading] = useState(false)

  const dataSchema = Yup.object({
    username: Yup.string()
      .required("Required")
      .matches(
        /^(01)[02-9]-*[0-9]{7}$|^(01)[1]-*[0-9]{8}$/g,
        "Invalid phone number format."
      ),
    verification_code: Yup.string().required("Required"),
  });

  const handleSubmit = async ({ values, setFieldError }) => {
    try {
      setLoading(true)
      const response = await apiServices.changePhone(values);
      if (response) {
        setLoading(false)
        dispatch(closeAllModal());
        dispatch(profile());
        Notification({
          title: "Successful!",
          message: `Phone number has changed.`,
          type: "success",
        });
      }
    } catch (ex) {
      setLoading(false)
      if (ex && Object.keys(ex).length > 0) {
        let errorMsg = "";
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              errorMsg = errors[item];
              setFieldError(item, errorMsg);
            });
          }
        }
      }
    }
  };

  return (
    <RemoveScroll>
      <ModalBox
        show={isOpenChangePhone}
        screenSize="fullscreen-xl-down"
        className="change-phone-modal"
        content={
          <MDBModalBody>
            <ModalHeader title="Change Phone Number" />
            <Formik
              initialValues={userData}
              validationSchema={dataSchema}
              onSubmit={(
                values,
                { resetForm, setFieldValue, setFieldError }
              ) => {
                handleSubmit({ values, setFieldError });
                resetForm({ values: "" });
                setFieldValue("verification_code", "");
              }}
            >
              {({
                values,
                errors,
                isValid,
                resetForm,
                isSubmitting,
                setFieldError,
              }) => (
                <Form>
                  <MDBContainer className="change-phone-container">
                    <MDBRow>
                      <Text
                        type="username"
                        name="username"
                        className="service-field"
                        placeholder="Phone Number"
                        as
                      />
                    </MDBRow>
                    <MDBRow>
                      <Code className="login-field" name="verification_code" />
                    </MDBRow>
                    <MDBRow className="btn-row">
                      <Button
                        time={time}
                        setTime={setTime}
                        type="button"
                        name={
                          time == 0
                            ? "Send verification"
                            : time + " Resend Verification"
                        }
                        sendCode="code"
                        className="login_button"
                        values={values}
                        disabled={time == 0 ? false : true}
                      />
                    </MDBRow>
                    <MDBRow className="btn-row">
                      <Button
                        type="submit"
                        name="Save"
                        className="login_button"
                        loading={loading}
                      />
                    </MDBRow>
                  </MDBContainer>
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}
