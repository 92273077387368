import { Field, useFormikContext } from "formik";
import { MDBCol, MDBRow, MDBInput } from "mdb-react-ui-kit";
import { useState, useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import moment from "moment";
import { toast } from "react-toastify";

//redux
import { useSelector } from "react-redux";

//auth
import authServices from "../../services/auth.services";

export default function Text({
  as,
  type,
  label,
  name,
  disabled,
  placeholder,
  verification,
  className,
}) {
  const { login } = useSelector((state) => state.login);
  const { values, errors, setFieldValue, setFieldError } = useFormikContext();
  const [inputFocus, setInputFocus] = useState(false);
  const [sendingCode, setSendingCode] = useState(false);
  const [time, setTime] = useState(-1);
  
  const sendCode = async () => {
    if (login == "Login") {
      setSendingCode(true)
      try {
        const response = await authServices.sendCode({
          username: values.username,
          type: "phone verification",
        });
        if (response) {
          setSendingCode(false)
          setTime(60);
        }
      } catch (ex) {
        setSendingCode(false)
        if (ex && ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError("username", errors[item]);
            });
          }
        }
      }
    } else if (login == "Forgot") {
      try {
        const response = await authServices.sendCode({
          username: values.username,
          type: "forgot password",
        });
        if (response) {
          setTime(60);
        }
      } catch (ex) {}
    }
  };
  const handleFocus = () => {
    setInputFocus(true);
  };
  const handleBlur = () => {
    setInputFocus(false);
  };

  useEffect(() => {
    time > -1 && setTimeout(() => setTime(time - 1), 1000);
  }, [time]);

  return (
    <>
      <MDBCol
        className={`form-group _text ${className}`}
        style={
          className == "service-field" && inputFocus
            ? { border: "0.1rem solid black" }
            : {}
        }
      >
        {label && (
          <MDBRow>
            <label className="form-label">{label}</label>
          </MDBRow>
        )}
        <MDBRow className="form-group _text">
          {as && type !== "date" ? (
            <Field
              onFocus={handleFocus}
              onBlur={handleBlur}
              as={MDBInput}
              type={type ? type : "text"}
              name={name}
              label={placeholder}
              disabled={disabled}
              className="text"
            />
          ) : as && type == "date" ? (
            <Field
              onFocus={handleFocus}
              onBlur={handleBlur}
              as={MDBInput}
              type="date"
              value={moment(values[name]).format("YYYY-MM-DD")}
              name={name}
              label={placeholder}
              disabled={disabled}
              className="text"
              onChange={(event) => {
                if (event.target.value === "Invalid date") {
                } else {
                  setFieldValue(name, event.target.value);
                }
              }}
            />
          ) : (
            <Field
              onFocus={handleFocus}
              onBlur={handleBlur}
              type={type ? type : "text"}
              name={name}
              placeholder={placeholder}
              disabled={disabled}
              className="text"
            />
          )}
          {verification && (
            <div className="verification-button">
              {time == -1 ? (
                <button
                  type="button"
                  className="button"
                  onClick={() => sendCode()}
                >
                <div className="d-flex align-items-center justify-content-center">
                  SEND
                  {sendingCode && 
                    <Icon icon="line-md:loading-twotone-loop" width="1.4em" className="ms-2" />
                  }
                </div>
                </button>
              ) : time < 1 ? (
                <button type="button">
                  RESEND <Icon icon="eos-icons:loading" height="13" />
                </button>
              ) : (
                <button type="button">RESEND {time}s</button>
              )}
            </div>
          )}
        </MDBRow>
      </MDBCol>
      <MDBRow>
        {errors && errors[name] ? (
          <div className="text-danger text-right no-padding error-message">
            {errors[name]}
          </div>
        ) : null}
      </MDBRow>
    </>
  );
}
