import { useEffect } from "react";
import { useState } from "react";
import moment from "moment/moment";
import { Icon } from "@iconify/react";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { MDBCol, MDBRow, MDBContainer, MDBBtn } from "mdb-react-ui-kit";
import apiServices from "../../../services/api.services";
import { Logo2 } from "../../../assets/images";
//component
import InputCount from "../../../components/element/count";

//assets
import Lottie from "lottie-react";
import Loading from "./../../../assets/json/loading.json";
import Loading2 from "./../../../assets/json/loading_two.json";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  openModalBank,
  openModalRemove,
  openModalEdit,
  openModalInformation,
  openModalRedeemOption,
  openModalLogin,
} from "../../../redux/reducer/modalReducer";
import {
  setPayId,
  setOrder,
  bank_list,
  transfer_cart,
  getAllChannels,
  handleChangeQuantity,
  setPaymentPoint,
} from "../../../redux/reducer/paymentReducer";
import { changePrice, option } from "../../../redux/reducer/menuReducer";
import {
  cartListing,
  setFoodData,
  setPointSeleted,
  setCartData,
} from "../../../redux/reducer/cartReducer";
import { Notification } from "../../../helper/react_notification";

export default function OrderListing({ setTitle, setId }) {
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [vouOpen, setVouOpen] = useState(false);

  const deviceId = navigator.userAgent.replace(/[^\w]/gi, "");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const item = localStorage.getItem("item");
  const token = localStorage.getItem("token");
  const p_guest = localStorage.getItem("p_guest");
  const table_id = localStorage.getItem("table");

  const { data, cartPointSeleted, cart_loading , selected_cart_food_id ,cart_order_loading , selected_cart_index } = useSelector((state) => state.cart);
  const { cart, total } = useSelector((state) => state.menu);
  const { cashback } = useSelector((state) => state.report);
  const { isOpenReceipt } = useSelector((state) => state.modal);

  const dataValue = {
    cart_id: "",
    amount: 0,
  };

  const handlePayment = async (data) => {
    setPaymentStatus(true);
    try {
      const response = await apiServices.orderCart({
        cart_id: data.id,
        device_id: deviceId,
        amount: data.point,
      });
      if (response) {
        dispatch(
          getAllChannels({
           cart_id: data.id,
        // token: response.data.cap_access_token,
            // table: table_id,
            amount: data.amount,
          })
        )
        .then((res) => {
          dispatch(setPayId(response.data.cap_access_token));
          dispatch(setOrder(response.data.cart_id));
          dispatch(openModalBank());
          setPaymentStatus(false);
          dispatch(setPaymentPoint(data.point));
        })
        .catch((ex) => {
      dispatch(cartListing({ device_id: deviceId }));
      Notification({
        title: "Error!",
        message: `Error`,
        type: "danger",
      });
        });
       
      }
    } catch (ex) {
      dispatch(cartListing({ device_id: deviceId }));
      if (ex && Object.keys(ex).length > 0) {
        let errorMsg = [];
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              errorMsg = [errors[item][0]];
              Notification({
                title: "Error!",
                message: `${errorMsg}`,
                type: "danger",
              });
              const timer = setTimeout(() => {
                setPaymentStatus(false);
              }, 2000);
              return () => {
                clearTimeout(timer);
              };
            });
          }
        }
      }
    }
  };

  const errorMessage = () => {
    Notification({
      title: "Error!",
      message: "Please log in or register first before making payment.",
      type: "danger",
    });
  };

  const deleleFood = () => {
    dispatch(openModalRemove());
  };


  useEffect(() => {
    if (isOpenReceipt) {
      navigate("/order");
    }
  }, [isOpenReceipt]);

  useEffect(() => {
    if (data.total_item && data.total_item === item) {
      localStorage.setItem("item", data.total_item);
    }
  }, [item]);



  return (
    <Formik initialValues={dataValue}>
      {({ values, setFieldValue }) => (
        <Form>
          <div>
            {!cart_order_loading && cart_loading ?
              <div className="d-flex flex-column align-items-center image-div">
                <div style={{ height: '75%' }}>
                  <div className="loading-lottie">
                    <Lottie animationData={Loading} />
                  </div>
                </div>
              </div>
             : (
              
            data && data.length  &&
              data.length > 0  ? ( 
                data.map((item, index) => {
                  const cartIndex = index
                  const subtotal = item.subtotal ? parseFloat(item.subtotal).toFixed(2) : 0;
                  const service_charge_percent = item.service_charge !== null && item.service_charge_percent ? parseFloat(item.service_charge_percent).toFixed(2) : 0;
                  const sales_service_tax = item.sales_service_tax !== null ? 6 : 0;
                  const cartFood = item.cart;
                  const cartId = item.cart_id;
                  const cashback_percent = item.cashback_percent
                  const matchingData = cartPointSeleted.find(
                    (dataItem) => dataItem.id === cartId
                    );
                    const newSubtotal =  Number(subtotal)
                    const voucher = matchingData !== undefined && matchingData.point !== "" ? matchingData.point / 100 : 0
                    const sst =  parseFloat((newSubtotal * sales_service_tax ) / 100).toFixed(2)
                    const service_charge =  parseFloat((newSubtotal * service_charge_percent ) / 100).toFixed(2)
                    const platform_charge_percent =  parseFloat(item.platform_charge_percent).toFixed(0)
                    const totalPrice = parseFloat(newSubtotal + Number(sst) + Number(service_charge) - Number(voucher)).toFixed(2)
                    const platform_charge_amount = parseFloat(platform_charge_percent !== "0" ? ((newSubtotal - voucher) / platform_charge_percent) : 0).toFixed(2)
                    const sstBonus = parseFloat(platform_charge_amount - (platform_charge_amount / 1.06)).toFixed(2)
                    const cashback = parseFloat(((platform_charge_amount - sstBonus) * cashback_percent)).toFixed(2)

                  return (
                    <div key={index}>
                      {item.cart.length > 0 ? (
                        <>
                          <MDBRow className="table-data">
                            <MDBCol className="col-4">
                              <div className="table-no">
                                <label className="_label --text">
                                  {item.method} &nbsp;
                                  <Icon
                                    icon="mdi:information-outline"
                                    color="#c3c4cd"
                                    // onClick={() => {
                                    //   dispatch(openModalInformation());
                                    //   setTitle("Dine In");
                                    // }}
                                  />
                                </label>
                              </div>
                            </MDBCol>

                            <MDBCol className="col-4">
                              <div className="table-no">
                                <label className="_label --text">
                                  Table : {item.table_name}
                                </label>
                              </div>
                            </MDBCol>
                            <MDBCol className="col-4">
                              <div className="table-no">
                                <label className="_label --text">
                                  Time :{" "}
                                  {moment(item.time, "hh:mm").format("h:mm A")}
                                </label>
                              </div>
                            </MDBCol>
                          </MDBRow>
                          <MDBContainer className="food-order">
                            <MDBRow>
                              <label className="_label --title">
                                From{" "}
                                <span
                                  style={{
                                    textTransform: "uppercase",
                                    paddingLeft: "0.2em",
                                    fontSize: "1.05em",
                                  }}
                                >
                                  {item.merchant_name}
                                </span>
                              </label>
                            </MDBRow>
                            <MDBRow>
                              {item.cart && item.cart.length > 0
                                ? item.cart.map((item, index) => {
                                    const quantitySum = cartFood.reduce(
                                      (sum, currentItem) => {
                                        if (
                                          currentItem.menu_id === item.menu_id
                                        ) {
                                          return sum + currentItem.quantity;
                                        }
                                        return sum;
                                      },
                                      0
                                    );

                                    return (
                                      <MDBContainer
                                        key={index}
                                        className="order-container"
                                      >
                                        <MDBRow className="order-listing">
                                          <MDBCol
                                            className="col-7 hidden"
                                            onClick={() => {
                                              dispatch(
                                                option({
                                                  menu_id: item.menu_id,
                                                })
                                              )
                                                .unwrap()
                                                .then((res) => {
                                                })
                                                .catch((ex) => {});
                                              dispatch(openModalEdit())
                                              dispatch(setFoodData(item));
                                            }}
                                          >
                                            {item.menu_code ? (
                                              <>
                                                <label className="_label --code text-center">
                                                  {item.menu_code}
                                                </label>
                                                &nbsp;&nbsp;&nbsp;
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            <label className="_label --food-name">
                                              {item.menu_name}
                                            </label>
                                          </MDBCol>
                                          <MDBCol className="col-5 food-price">
                                            <label className="_label --price">
                                              RM
                                              {selected_cart_food_id === item.cart_item_id && cart_order_loading ?  <Icon icon="eos-icons:three-dots-loading" />  :  parseFloat(
                                                item.total_price
                                              ).toFixed(2)}
                                            </label> &nbsp;
                                            <Icon
                                              icon="mdi:delete-circle"
                                              color="#9091a0"
                                              height="20"
                                              width="20"
                                              onClick={() => {
                                                deleleFood();
                                                setId(item.cart_item_id);
                                              }}
                                            />
                                        <MDBRow>
                                          {item.quantity_balance !== null &&
                                              quantitySum >=
                                                item.quantity_balance && (
                                                <MDBRow className="error-balance-message">
                                                  <label className="_label">
                                                    Left {item.quantity_balance}{" "}
                                                    Item
                                                  </label>
                                                </MDBRow>
                                              )}
                                            <InputCount
                                            cartIndex={cartIndex}
                                              setId={setId}
                                              food_id={item.cart_item_id}
                                              className="order"
                                              index={null}
                                              quantity={item.quantity}
                                              changePrice={changePrice}
                                              handleChangeQuantity={
                                                handleChangeQuantity
                                              }
                                              remark={item.remark}
                                              balance={
                                                item.quantity_balance !== null
                                                  ? item.quantity_balance
                                                  : undefined
                                              }
                                              max={null}
                                              quantitySum={quantitySum}
                                            />
                                          </MDBRow>
                                          
                                          </MDBCol>
                                          {/* <MDBCol className="col-1">
                                          
                                          </MDBCol> */}
                                        </MDBRow>
                                        <MDBRow className="add-order">
                                          <MDBCol>
                                            {item.details &&
                                            item.details.length > 0
                                              ? item.details.map(
                                                  (item, index) => {
                                                    return (
                                                      <MDBRow key={index}>
                                                        <label className="_label --addon">
                                                          {item.name}
                                                        </label>
                                                      </MDBRow>
                                                    );
                                                  }
                                                )
                                              : ""}
                                            <MDBRow>
                                              <label className="_label --remark">
                                                {item.remark ? item.remark : ""}
                                              </label>
                                            </MDBRow>
                                          </MDBCol>
                                          <MDBCol className="order-div col-5">
                                            {/* {item.quantity_balance !== null &&
                                              quantitySum >=
                                                item.quantity_balance && (
                                                <MDBRow className="error-balance-message">
                                                  <label className="_label">
                                                    Left {item.quantity_balance}{" "}
                                                    Item
                                                  </label>
                                                </MDBRow>
                                              )}
                                            <InputCount
                                              setId={setId}
                                              food_id={item.cart_item_id}
                                              className="order"
                                              index={null}
                                              quantity={item.quantity}
                                              changePrice={changePrice}
                                              handleChangeQuantity={
                                                handleChangeQuantity
                                              }
                                              remark={item.remark}
                                              balance={
                                                item.quantity_balance !== null
                                                  ? item.quantity_balance
                                                  : undefined
                                              }
                                              max={null}
                                              quantitySum={quantitySum}
                                            /> */}
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBContainer>
                                    );
                                  })
                                : ""}
                            </MDBRow>

                            <MDBContainer className="payment-detail">
                              <MDBRow style={{alignItems : "center"}}>
                                <MDBCol className="p-0">
                                  <label className="_label --data">
                                    Subtotal
                                  </label>
                                </MDBCol>
                                <MDBCol className="amount-col">
                                  <label className="_label --data2">
                                    RM {selected_cart_index === cartIndex && cart_order_loading ? <Icon icon="eos-icons:three-dots-loading" /> :  parseFloat(item.subtotal).toFixed(2) }
                                  </label>
                                </MDBCol>
                              </MDBRow>
                            {item.sales_service_tax !== null && item.subtotal !== 0 &&
                                    item.service_charge !== 0 &&
                                    item.service_charge_percent  &&  <MDBRow style={{alignItems : "center"}}>
                                <MDBCol className="p-0">
                                  <label className="_label --data">
                                    Service Charge (
                                    {item.sales_service_tax !== null && item.subtotal !== 0 &&
                                    item.service_charge !== 0 &&
                                    item.service_charge_percent
                                      ? parseFloat(
                                          item.service_charge_percent
                                        ).toFixed(0)
                                      : 0}
                                    %)
                                  </label>
                                </MDBCol>
                                <MDBCol className="amount-col">
                                  <label className="_label --data2">
                                    RM{" "}
                                    {selected_cart_index === cartIndex && cart_order_loading ?  <Icon icon="eos-icons:three-dots-loading" />   : matchingData !== undefined && item.service_charge  && 
                                            matchingData.point !== ""
                                              ?    service_charge   :  parseFloat(item.service_charge ? item.service_charge : 0).toFixed(2) }
                                  </label>
                                </MDBCol>
                              </MDBRow>}
                     {item.sales_service_tax !== 0 && item.sales_service_tax !== null  &&   <MDBRow style={{alignItems : "center"}}>
                                <MDBCol className="p-0">
                                  <label className="_label --data">
                                    {item.sales_service_tax == 0 || item.sales_service_tax === null
                                      ? "SST (0%)"
                                      : "SST (6%)"}
                                  </label>
                                </MDBCol>
                                <MDBCol className="amount-col">
                                  <label className="_label --data2">
                                    RM{" "}
                                    {selected_cart_index === cartIndex && cart_order_loading ?   <Icon icon="eos-icons:three-dots-loading" />  :  matchingData !== undefined && item.sales_service_tax !== null &&
                                            matchingData.point !== ""
                                              ?    sst    :    parseFloat(item.sales_service_tax ? item.sales_service_tax : 0).toFixed(2) }
                                  </label>
                                </MDBCol>
                              </MDBRow>}
                              <MDBRow style={{alignItems : "center"}}>
                                <MDBCol className="p-0">
                                  <label className="_label --data">
                                    Cashback &nbsp;
                                    <Icon
                                      icon="mdi:information-outline"
                                      color="#001a00"
                                      height="14"
                                      onClick={() => {
                                        dispatch(openModalInformation());
                                        setTitle("Cashback");
                                      }}
                                    />
                                  </label>
                                </MDBCol>
                                <MDBCol className="amount-col">
                                  <label className="_label --data2">
                                    {selected_cart_index === cartIndex && cart_order_loading ?  <Icon icon="eos-icons:three-dots-loading" /> : parseFloat(cashback).toFixed(0)} Stamp
                                  </label>
                                </MDBCol>
                              </MDBRow>

                              <MDBRow style={{alignItems : "center" , marginTop : "1em"}}>
                                <MDBCol>
                                  <MDBRow className="redeem-row">
                                    <button
                                      className="redeem-btn"
                                      onClick={() => {
                                        if (token) {
                                          dispatch(
                                            setPointSeleted(
                                              matchingData !== undefined
                                                ? matchingData.point
                                                : 0
                                            )
                                          );
                                          dispatch(setCartData(item));
                                          dispatch(openModalRedeemOption());
                                        } else {
                                          dispatch(openModalLogin());
                                        }
                                      }}
                                    >
                                      Redeem Now
                                    </button>
                                  </MDBRow>
                                </MDBCol>
                                <MDBCol className="text-end">
                                  {matchingData !== undefined &&
                                    matchingData.point !== "" &&
                                    item.total_price / 2 >
                                      matchingData.point / 100 && (
                                      <label className="_label --decution">
                                        Deduction : - RM{matchingData.point / 100}
                                      </label>
                                    )}
                                </MDBCol>
                              </MDBRow>
                              <MDBRow className="pay-button mb-5">
                                <MDBCol className="button">
                                  <MDBBtn
                                    disabled={paymentStatus}
                                    className="button-group"
                                    onClick={() => {
                                      if (token || p_guest) {
                                        handlePayment({
                                          id: item.cart_id,
                                          amount:
                                            matchingData !== undefined &&
                                            matchingData.point !== ""
                                              ? totalPrice
                                              : item.total_price,
                                          point:
                                            matchingData !== undefined &&
                                            matchingData.point !== "" &&
                                            item.total_price / 2 >
                                              matchingData.point / 100
                                              ? matchingData.point
                                              : 0,
                                        });
                                      } else {
                                        errorMessage();
                                      }
                                    }}
                                  >
                                    <div className="d-flex align-items-center justify-content-center">
                                    Total Pay - RM
                                    {matchingData !== undefined &&
                                    matchingData.point !== "" 
                                      ? totalPrice
                                      : parseFloat(item.total_price).toFixed(2)}
                                    {paymentStatus && 
                                      <Icon icon="line-md:loading-twotone-loop" color="#fafafa" width="1.4em" className="ms-3" />
                                    }
                                    </div>
                                  </MDBBtn>
                                </MDBCol>
                              </MDBRow>
                            </MDBContainer>
                          </MDBContainer>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })
              )
              : 
              <div className="d-flex flex-column align-items-center image-div">
                <div style={{ height: '75%', textAlign: 'center' }}>
                  <div className="loading-lottie">
                  <img
                    src={Logo2}
                    className="logo-image"
                    alt="logo-image"
                    fetchpriority="high"
                  />
                  </div>
                  <div className="loading-div d-flex justify-content-center">
                    No Data Available
                </div>
              </div>
            </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
