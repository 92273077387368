//lib
import { MDBRow } from 'mdb-react-ui-kit'
import { useNavigate} from "react-router-dom";

//component
import { Logo2 } from '../../../assets/images';
import useWindowDimensions from "../../../components/common/window-dimension";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setSelectedFile } from '../../../redux/reducer/commonReducer';

export default function DataHeader() {
    const { width } = useWindowDimensions();
    const backLocation = localStorage.getItem("location")
    const dispatch = useDispatch();
    const { selectedFile } = useSelector((state) => state.common);
    const navigate = useNavigate();

    const handleBackPage = () => {
        navigate(backLocation ? backLocation : "/")
    }

    const handleChange = (item) => {
        dispatch(setSelectedFile(item))
    }

  return (
   <div className='text-center'>
    <div style={{height : "60px" , width : "70px"}} onClick={() => handleBackPage()}>
    <img src={Logo2} alt="FoodLink" style={{height : "100%" , width : "100%" , objectFit : "cover"}}/>   
    </div>
    <label style={{fontSize : width > 720 ? "32px" : ""}} className='_label --big_title mt-3'>{selectedFile === "terms" ?  "Terms And Service"  : "Privacy Policy"}</label>
    <MDBRow style={{justifyContent : "center" , marginBottom : "0.7em" , marginTop : "0.5em"}}>
        <button className={selectedFile === "terms" ? "choose-btn active" : "choose-btn" }onClick={() => handleChange("terms")}>Terms And Service</button>
        <button className={selectedFile === "privacy" ? "choose-btn active" : "choose-btn" } onClick={() => handleChange("privacy")}>Privacy Policy</button>    
    </MDBRow>
   </div>
  )
}
