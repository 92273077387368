import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";

//rdeux
import { useDispatch, useSelector } from "react-redux";
import { changeLogin } from "../../../redux/reducer/loginReducer";
import {
  closeModalLogin,
  closeModalPeregister,
} from "../../../redux/reducer/modalReducer";

export default function LoginHeader({ title }) {
  const dispatch = useDispatch();
  const { login } = useSelector((state) => state.login);

  return (
    <MDBRow className="login-header">
      <MDBCol className="col-div">
        <label className="_label --icon">
          <Icon
            icon="material-symbols:close"
            onClick={() => {
              dispatch(closeModalLogin());
              dispatch(closeModalPeregister());
              dispatch(changeLogin("Login"));
            }}
          />
        </label>
      </MDBCol>
      <MDBCol className="col-div col-6">
        <label className="_label --title">
          {title
            ? title
            : login == "Forgot"
            ? "Login"
            : login == "Password2"
            ? "Password"
            : login}
        </label>
      </MDBCol>
      <MDBCol className="col-div"></MDBCol>
    </MDBRow>
  );
}
