import { Formik, Form } from "formik";
import { useState, useEffect } from "react";
import { RemoveScroll } from "react-remove-scroll";
import apiServices from "../../../services/api.services";
import ModalBox from "../../../components/modal/half.modal.box";
import { MDBCol, MDBRow, MDBContainer } from "mdb-react-ui-kit";
import { Notification } from "../../../helper/react_notification";

//component
import Button from "../../../components/element/button";
import InputCount from "../../../components/element/count";

//redux
import { useSelector, useDispatch } from "react-redux";
import { alcoholic } from "../../../redux/reducer/menuReducer";
import { closeAllModal } from "../../../redux/reducer/modalReducer";
import { changePrice } from "../../../redux/reducer/alcoholicReducer";

export default function ModalAlcoholic2({ onDismiss }) {
  const table = localStorage.getItem("table");
  const guest = localStorage.getItem("guest");
  const p_login = localStorage.getItem("p_login");
  const loginId = localStorage.getItem("loginId");

  const dispatch = useDispatch();
  const { ticket } = useSelector((state) => state.alcoholic);
  const { isOpenAlcoholic2 } = useSelector((state) => state.modal);
  const { quantity } = useSelector((state) => state.alcoholic);
  const [loading , setLoading]=useState(false)

  const [data, setData] = useState({
    name: "",
    quantity: "",
  });

  const handleSubmit = async (data) => {
    setLoading(true)
    if (data && quantity !== 0) {
      try {
        const response = await apiServices.claimTicket({
          ticket_item_id: data ? data : "",
          quantity: quantity ? quantity : 0,
          table_id: table ? table : "",
        });
        if (response) {
         setLoading(false)
          dispatch(closeAllModal());
          dispatch(
            alcoholic({
              table_id: table,
              remark: "alcohol",
              user_id: loginId ? loginId : p_login ? p_login : guest,
            })
          );
          Notification({
            title: "Successful!",
            message: `Item has been claimed.`,
            type: "success",
          });
        }
      } catch (ex) {
        setLoading(false)
        if (ex && Object.keys(ex).length > 0) {
          if (ex.response.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {});
            }
          }
        }
      }
    } else {
      setLoading(false)
    }
  };

  useEffect(() => {
    dispatch(changePrice(1));
  }, []);

  return (
    <RemoveScroll>
      <ModalBox
        onDismiss={onDismiss}
        show={isOpenAlcoholic2}
        className="alcoholic-modal-2"
        content={
          <Formik
            initialValues={data}
            onSubmit={(values, { resetForm, setFieldError }) => {
              handleSubmit(ticket.ticket_item_id);
              resetForm({ values: "" });
            }}
          >
            <Form>
              <MDBContainer className="food-order">
                <MDBRow className="food-title">
                  <MDBCol className="col-6">
                    <label className="_label --title ">
                      {ticket.alcohol_name}
                    </label>
                  </MDBCol>
                  <MDBCol>
                    <MDBRow>
                      <label className="_label --quantity">
                        x {ticket.alcohol_balance}
                      </label>
                    </MDBRow>
                    <MDBRow>
                      <label className="_label --text">Ticket Balance</label>
                    </MDBRow>
                    <MDBRow>
                      <label className="_label --text">
                        Use before : {ticket.expired_at}
                      </label>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="count">
                  <InputCount
                    className="alcoholic-btn"
                    quantity={1}
                    max={ticket.alcohol_balance}
                    changePrice={changePrice}
                  />
                </MDBRow>
                <MDBRow className="btn-alcoholic">
                  <Button
                    name="Claim Ticket"
                    className="alcoholic_button-2"
                    type="submit"
                    disabled={loading}
                    loading={loading}
                  />
                </MDBRow>
              </MDBContainer>
            </Form>
          </Formik>
        }
      />
    </RemoveScroll>
  );
}
