import { Field, useFormikContext } from "formik";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

export default function Select({
  label,
  name,
  options,
  disabled,
  placeholder,
  className,
}) {
  const { values, errors } = useFormikContext();

  return (
    <>
      <MDBCol className={`form-group _select ${className}`}>
        {label && (
          <MDBRow
            style={{
              height: "0.85em",
            }}
          >
            <label
              className="form-label"
              style={{
                display: disabled === true ? "none" : "",
                height: "10em",
              }}
            >
              {label}
            </label>
          </MDBRow>
        )}
        <Field as="select" name={name} disabled={disabled}>
          <option value="">
            {placeholder ? placeholder : "- Please Select -"}
          </option>
          {options &&
            options.map((option) => (
              <option key={option.id} value={option.value}>
                {option.label}
              </option>
            ))}
        </Field>
      </MDBCol>

      {errors && errors[name] ? (
        <div className="text-danger text-right no-padding error-message">
          {errors[name]}
        </div>
      ) : null}
    </>
  );
}
