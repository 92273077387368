//framework & plugin
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBModalBody,
} from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { RemoveScroll } from "react-remove-scroll";

//redux
import { useSelector, useDispatch } from "react-redux";
import { closeModalPayFail } from "../../../redux/reducer/modalReducer";

//component
import ModalBox from "../../../components/modal/full.modal.box";

export default function PaymentFail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpenPayFail } = useSelector((state) => state.modal);

  return (
    <RemoveScroll>
      <ModalBox
        show={isOpenPayFail}
        screenSize="m"
        className="remove-modal"
        type="payment-failed"
        content={
          <MDBModalBody style={{ height: "18em" }}>
            <MDBContainer>
              <MDBRow className="header">
                <MDBCol className="icon-col mt-2 me-1">
                  <Icon
                    height="20"
                    icon="material-symbols:close"
                    onClick={() => dispatch(closeModalPayFail())}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mt-3">
                <label className="remove-title">Payment Failed</label>
              </MDBRow>
              <MDBRow>
                <span className="remove-span">Opps, something went wrong.</span>
              </MDBRow>
              <MDBContainer className="information">
                <MDBRow className="btn-row">
                  <MDBBtn
                    className="fail-btn"
                    onClick={() => {
                      navigate("/cart");
                      dispatch(closeModalPayFail());
                    }}
                  >
                    Reorder
                  </MDBBtn>
                </MDBRow>
              </MDBContainer>
            </MDBContainer>
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}
