//framework & plugin
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { MDBContainer } from "mdb-react-ui-kit";
import { RemoveScroll } from "react-remove-scroll";

export default function PartialRegisterModal({
  show,
  header,
  footer,
  content,
  backDrop,
  className,
  onDismiss,
  showBottom,
  register
}) {
  return (
    <BottomSheet
      open={show}
      scrollLocking={showBottom ? showBottom : false}
      onDismiss={onDismiss ? onDismiss : false}
      snapPoints={
        showBottom == false
          ? ({ maxHeight }) => [maxHeight / 12]
          : showBottom == true
          ? ({ maxHeight }) => [maxHeight / 1.45]
          : register == true 
          ? ({ maxHeight }) => [maxHeight / 4]
          : ({ maxHeight }) => [maxHeight / 3.4]
      }
      blocking={showBottom ? showBottom : false}
      header={header}
      footer={footer}
      className="partial-register-modal"
    >
      <MDBContainer>{content}</MDBContainer>
    </BottomSheet>
  );
}
