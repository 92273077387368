import http from './http.services'

http.setToken(getToken())

// process.env.REACT_APP_API_URL
async function login(payload) {
  http.setToken(getToken2())
  const { data } = await http.post(process.env.REACT_APP_API_URL + '/login', payload)
  const token = data.data.token || "";
  localStorage.setItem("token", token);
  if (token) return true;
  else return false;
}

async function sendCode(payload) {
  const { data } = await http.post(process.env.REACT_APP_API_URL + '/send_verification_code', payload)
  return data
}

async function codeValidate(payload) {
  http.setToken(getToken2())
  const { data } = await http.post(process.env.REACT_APP_API_URL + '/validate_verification_code', payload)
  return data
}

async function register(payload) {
  const { data } = await http.post(process.env.REACT_APP_API_URL + '/register', payload)
  return data
}

async function setPassword(payload) {
  const { data } = await http.post(process.env.REACT_APP_API_URL + '/set_password', payload)
  return data
}

async function perRegister(payload) {
  const { data } = await http.post(process.env.REACT_APP_API_URL + '/verify_user_if_exist', payload)
  const id = data.data.token || "";
  const userID = data.data.id || "";
  localStorage.setItem("p_guest", id);
  localStorage.setItem("userID", userID);
  
  if (id) return true;
  else return false;
}

function getToken() {
  const p_guest = localStorage.getItem("p_guest")
const token = localStorage.getItem("token")
const guest = localStorage.getItem("guest")
const new_guest = localStorage.getItem("new_guest")
  if(token){
    return localStorage.getItem("token")
  }
  else if(!token && p_guest){
    return localStorage.getItem("p_guest")
  }
  else if(!token && !p_guest && !guest && new_guest){
    return localStorage.getItem("new_guest")
  }
  else{
    return guest
  }
}
function getToken2() {

    return localStorage.getItem("guest")
  }


async function logout() {
  // localStorage.clear()
  localStorage.removeItem("token")
  localStorage.removeItem("p_guest")
    localStorage.removeItem("username")
  localStorage.removeItem("userID")
  // localStorage.removeItem("table")
}

function getCurrentUser() {
  const token = localStorage.getItem('token')
  if (token) return { token }
  else return ''
}

export default {
  login,
  logout,
  register,
  sendCode,
  codeValidate,
  setPassword,
  getToken,
  getCurrentUser,
  perRegister,
  getToken2,
}
