import moment from "moment/moment";
import { Icon } from "@iconify/react";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";

//redux
import { useSelector } from "react-redux";
import { Skeleton } from "primereact/skeleton";

export default function Card() {
  const { card_loading } = useSelector((state) => state.menu);
  const { description, display_address, open_at, close_at, display_name } =
    useSelector((state) => state.menu.data);
  return (
    <MDBContainer className="location_card">
      {!card_loading ? 
        <MDBRow>
          <label className="_label --title">{display_name}</label>
        </MDBRow>
      : 
        <MDBRow className="p-2">
          <Skeleton width="18em" height="1.8em" borderRadius="20px" className="mt-2"/>
          <Skeleton width="25em" height="1.3em" borderRadius="20px" className="mt-4 ms-2"/>
        </MDBRow>
      }
      {/* <MDBRow style={{ maxHeight: "2em", maxWidth: "100%" }}>
        <label className="_label --desc">{description && description}</label>
      </MDBRow> */}
      {/* {display_address && (
        <MDBRow className="mt-3">
          <MDBCol className="col-1">
            <Icon icon="ion:location-outline" className="icon" />
          </MDBCol>
          <MDBCol>
            <label className="_label --detail">{display_address}</label>
          </MDBCol>
        </MDBRow>
      )} */}
      {!card_loading && open_at ? (
        <MDBRow>
          <MDBCol className="col-1">
            <Icon icon="ic:sharp-access-time" className="icon" />
          </MDBCol>
          <MDBCol>
            <label className="_label --detail">
              Open at {moment(open_at, "HH:mm:ss").format("HH:mm A")} -{" "}
              {moment(close_at, "HH:mm:ss").format("HH:mm A")}
            </label>
          </MDBCol>
        </MDBRow>
      ) : (
        ""
      )}
    </MDBContainer>
  );
}
