import { configureStore } from "@reduxjs/toolkit";

import commonReducer from "./reducer/commonReducer"
import menuReducer from "./reducer/menuReducer"
import modalReducer from "./reducer/modalReducer"
import alcoholicReducer from "./reducer/alcoholicReducer"
import cartReducer from "./reducer/cartReducer"
import paymentReducer from "./reducer/paymentReducer"
import loginReducer from "./reducer/loginReducer"
import reportReducer from "./reducer/reportReducer"
import profileReducer from "./reducer/profileReducer"


const reducer = {
    common: commonReducer,
    modal:modalReducer,
    menu : menuReducer,
    alcoholic : alcoholicReducer,
    cart : cartReducer,
    payment : paymentReducer,
    login : loginReducer,
    report : reportReducer,
    profile:profileReducer
}

const store = configureStore({
    reducer: reducer,
    devTools: true,
})

export default store