import ModalBox from "../../../components/modal/half.modal.box";
import { MDBCol, MDBRow, MDBContainer } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import { Notification } from "../../../helper/react_notification";
import { BankIcon } from "../../../assets/images";
//component

//redux
import { useSelector, useDispatch } from "react-redux";
import { closeModalBank } from "../../../redux/reducer/modalReducer";
import {
  provider_channels,
  request_paymentV2,
} from "../../../redux/reducer/paymentReducer";

export default function BankModal({ onDismiss }) {
  const deviceId = navigator.userAgent.replace(/[^\w]/gi, "");
  const dispatch = useDispatch();
  const table = localStorage.getItem("table");
  const [bank, setBank] = useState("");
  const [payMethod, setPayMethod] = useState("");
  const [payment, setPayment] = useState(false);

  const { banks, payId, pay_channels, orderId, paymentPoint , loading} = useSelector(
    (state) => state.payment
  );
  const { isOpenBank } = useSelector((state) => state.modal);

  const handleChangeBank = (pay) => {
    setPayMethod(pay);
    setPayment(true);
    dispatch(provider_channels({ token: payId, channel: pay , table_id : table}));
  };

  const handleChangepayment = (pay) => {
    setBank(pay);
    dispatch(
      request_paymentV2({
        tenant_channel_id: payMethod, //2
        cart_id: orderId,
        provider_channel_id: pay,
        device_id: deviceId,
        amount: paymentPoint,
      })
    ).unwrap()
    .then((res) => {
      let link = res.url;
      window.location.replace(link);
    })
    .catch((ex) => {
      dispatch(closeModalBank())
      Notification({
        title: "Order unsuccessfully!",
        message: `${ex.message}`,
        type: "danger",
      });
    });
};

const handleImageError = (e) => {
  e.target.src = BankIcon;
  e.target.style.width = '40px'
  e.target.style.height = '38px';
};

const handlePayment = (data) => {
  dispatch(
    request_paymentV2({
      tenant_channel_id: data.tenant_channel_id, //2
      cart_id: orderId,
      provider_channel_id: data.provider_channel_id,
      device_id: deviceId,
      amount: paymentPoint,
    })
  ).unwrap()
  .then((res) => {
    let link = res.url;
    window.location.replace(link);
    // window.open(link, '_blank')
  })
  .catch((ex) => {
    dispatch(closeModalBank())
    Notification({
      title: "Order unsuccessfully!",
      message: `${ex.message}`,
      type: "danger",
    });
  });
}

  return (
    <ModalBox
      show={isOpenBank}
      size={false}
      onDismiss={onDismiss}
      className="bank-modal"
      content={
        <MDBContainer className="bank-container">
          <MDBRow>
            <MDBCol className="col_bank col-2">
              <label className="_label --icon">
                {payment ? (
                  <Icon
                    icon="material-symbols:arrow-back-ios"
                    onClick={() => setPayment(false)}
                  />
                ) : (
                  <Icon
                    icon="material-symbols:arrow-back-ios"
                    onClick={() => dispatch(closeModalBank())}
                  />
                )}
              </label>
            </MDBCol>

            <MDBCol className="col_bank col-10">
              <label className="_label --payment">Select Payment Method</label>
            </MDBCol>
          </MDBRow>
          <br />
          {!loading && !payment && banks && banks.length > 0
            ? banks.map((list, index) => {
                return (
                  <MDBRow
                    className={
                      payMethod === list.id
                        ? "payment-border "
                        : "payment-border"
                    }
                    key={index}
                    onClick={() => {
                      if (process.env.REACT_APP_ENV == "local") {
                        setPayMethod(2);
                        handleChangeBank(2);
                      } else {
                        handlePayment(list)
                      }
                    }}
                  >
                    <MDBCol className="col-3">
                      <div className="method_bg">
                       {list.image_url ?  <img
                          src={list.image_url}
                          className="method_img"
                          alt="bank-png"
                          loading="lazy"
                          onError={handleImageError}
                        /> :
                        <Icon icon="mingcute:bank-line" height={27}/>}
                      </div>
                    </MDBCol>
                    <MDBCol className="col-6 text-center">
                      <label className="_label --bank">
                        {list.name}
                      </label>
                    </MDBCol>
                    <MDBCol className="col-3" style={{ textAlign: "end" }}>
                      <label
                        className="_label --drop"
                        id="credit"
                        value={list.id}
                      >
                        {/* {payOpen ? ( */}
                        <>
                          <Icon
                            icon="material-symbols:arrow-forward-ios"
                            //rotate={1}
                            className={
                              bank === list.id ? "drop active" : "drop"
                            }
                          />
                        </>
                        {/* ) : (
                            <Icon
                              icon="material-symbols:arrow-forward-ios"
                              height="0.8em"
                            />
                            )} */}
                      </label>
                    </MDBCol>
                  </MDBRow>
                );
              })
            
            :    <div style={{display : "flex" , marginTop : "3em" , marginBottom : "5em", alignItems: "center" , justifyContent : "center"}}>
            <Icon icon="line-md:loading-twotone-loop" color="#e26368"  height={"2.5em"}/>
            </div>}
        </MDBContainer>
      }
    />
  );
}
