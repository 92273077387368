import { RemoveScroll } from "react-remove-scroll";
import {  MDBModalBody, MDBContainer, MDBRow } from "mdb-react-ui-kit";

//component
import ModalBox from "../../components/modal/full.modal.box";
import CongratulationsEffect from "../../components/common/congratulations";
//redux
import { useSelector, useDispatch } from "react-redux";
import { closeModalCongratulations } from "../../redux/reducer/modalReducer";
import { useEffect } from "react";

export default function ModalCongratulations({ title }) {
  const dispatch = useDispatch();
  const { isOpenCongratulations } = useSelector((state) => state.modal);
  const { receipt } = useSelector((state) => state.report);

  const closeFunction = () => {
    dispatch(closeModalCongratulations())
  }

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     closeFunction()
  //   }, 5000);
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // },[])

  return (
    <RemoveScroll>
      <ModalBox
      show={receipt.result && receipt.result.cashback ? true : false}
      screenSize="sm"
      className="information-modal congratulation-modal"
      closeFunction={closeFunction}
      content={
          <MDBModalBody onClick={() => {
            closeFunction()
          }}>
               {isOpenCongratulations &&  receipt.result && receipt.result.order_no && 
               <MDBContainer>
            <CongratulationsEffect/>
            </MDBContainer>}
          <MDBContainer className="text-center">
            <MDBRow style={{marginTop : "17em"}}>
            <label className="_label --congratulation">
              Congratulations 🎉
              </label>
            </MDBRow>
              <label className="_label --stamps">
              You just a earn <span className="_span --stamps">{receipt.result && receipt.result.cashback ?  receipt.result.cashback : 0}</span> stamps
              </label>
              <MDBRow>

              <label className="_label --price">
              {receipt.result && receipt.result.cashback ? "RM " +  parseFloat(receipt.result.cashback/100).toFixed(2) : 0}
              </label>
              </MDBRow>
          </MDBContainer>
        </MDBModalBody>
      
    }
    />
    
    </RemoveScroll>
  );
}
