//lib
import {  MDBRow} from 'mdb-react-ui-kit'
import { useSelector } from 'react-redux'

//component
import useWindowDimensions from "../../../components/common/window-dimension";

export default function TermsData() {
  const { t_and_c_Listing } = useSelector((state) => state.common);
  const { width } = useWindowDimensions();

  return (
   <div>
    <p className='_label --tag' style={{fontSize : width > 720 ? "18px" : ""}}>Your acceptance of the Terms of Agreement in respect of the provision of FoodLink System to you indicates that you agree to follow and be bound by the Terms and Conditions contained herein.</p>
    {t_and_c_Listing.map((item , index) => {
        return(
            <MDBRow key={index} style={{marginTop : "0.5em"}}>
                <label style={{fontSize : width > 720 ? "20px" : ""}} className='_label --title'>{item.title}</label>
                <br />
                <p  style={{fontSize : width > 720 ? "18px" : ""}} className='_p'>{item.content}</p>
            </MDBRow>
        )
    })}
   </div>
  )
}
