import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { Skeleton } from "primereact/skeleton";
import { RemoveScroll } from "react-remove-scroll";
import { MDBCol, MDBRow, MDBModalBody, MDBContainer } from "mdb-react-ui-kit";

//component
import ModalBox from "../../../components/modal/full.modal.box";
import CartHeader from "../../../components/header/cart-header";
import InputCount from "../../../components/element/count";
import Button from "../../../components/element/button";
import Text from "../../../components/element/text";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  changePrice,
  setSubTotal,
} from "../../../redux/reducer/alcoholicReducer";
import {
  total_item,
  setAnimationIcon,
} from "../../../redux/reducer/menuReducer";
import { closeModalAlcoholic } from "../../../redux/reducer/modalReducer";

//api
import apiServices from "../../../services/api.services";
import { Notification } from "../../../helper/react_notification";
import useWindowDimensions from "../../../components/common/window-dimension";

export default function ModalAlcoholic() {
  const { width } = useWindowDimensions()
  const deviceId = navigator.userAgent.replace(/[^\w]/gi, "");
  const dispatch = useDispatch();
  const merchant = localStorage.getItem("merchant");
  const guest = localStorage.getItem("guest");
  const table = localStorage.getItem("table");
  const p_login = localStorage.getItem("p_login");
  const loginId = localStorage.getItem("loginId");
  const { quantity, subTotal } = useSelector((state) => state.alcoholic);
  const { option, loading } = useSelector((state) => state.menu);
  const { picture } = useSelector((state) => state.menu.data);
  const [remark, setRemak] = useState({
    merchant_id: merchant ? merchant : "",
    user_id: loginId ? loginId : p_login ? p_login : guest ? guest : "",
    quantity: quantity ? quantity : "",
    menu_id: option ? option.menu_id : "",
    table_id: table ? table : "",
    remark: "",
    option: [],
  });

  const [ addingOrder, setAddingOrder ] = useState(false)

  const handleSubmit = async ({ values, setFieldError }) => {
    setAddingOrder(true)
    try {
      const response = await apiServices.addToCart({
        merchant_id: merchant ? merchant : "",
        table_id: values.table_id,
        user_id: values.user_id,
        quantity: quantity ? quantity : "",
        remark: values.remark,
        menu_id: option ? option.menu_id : "",
        option: values.option,
        device_id: deviceId,
      });
      if (response) {
        setAddingOrder(false)
        dispatch(setAnimationIcon(true));
        dispatch(total_item({ device_id: deviceId }));
        dispatch(closeModalAlcoholic());
      }
    } catch (ex) {
      setAddingOrder(false)
      if (ex && Object.keys(ex).length > 0) {
        let errorMsg = "";
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
              errorMsg = errors[item];
            });
          }
        }
      }
    }
  };

  useEffect(() => {
    dispatch(setSubTotal(parseInt(option.unit_price, 10)));
  }, [quantity, option.unit_price]);

  return (
    <RemoveScroll>
      <ModalBox
        show={true}
        className="cart-modal"
        screenSize="fullscreen"
        content={
          <MDBModalBody>
            <Formik
              initialValues={remark}
              onSubmit={(values, { setFieldError, resetForm }) => {
                handleSubmit({ values, setFieldError });
                resetForm({ values: "" });
              }}
            >
              {({ isSubmitting, setFieldvalue, values }) => (
                <Form>
                  <CartHeader title="Place Order" />
                  {!loading && (
                    <MDBCol className="food-image">
                      {option.picture && (
                        <img
                          src={option.picture ? option.picture : picture}
                          className="image"
                          alt="alcohol-png"
                          loading="lazy"
                        />
                      )}
                    </MDBCol>
                  )}
                  {loading ? (
                    <MDBContainer>
                      <Skeleton className="skeleton_image" />
                      <MDBRow className="skeleton_food mt-4">
                        <MDBCol className="skeleton_col justify-content-start">
                          <Skeleton width={ width > 500 ? "80px" : "40%" } height="1.6em" borderRadius="8px" />
                        </MDBCol>
                        <MDBCol className="skeleton_col justify-content-end col-5">
                          <Skeleton width={ width > 500 ? "150px" : "70%" } height="1.6em" borderRadius="8px" />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="skeleton_food mt-3">
                        <MDBCol className="">
                          <Skeleton width={ width > 500 ? "60%" : "90%" }  height="1.3em" borderRadius="8px" />
                          <Skeleton width={ width > 500 ? "70%" : "100%" } height="1.3em" borderRadius="8px" className="mt-3"/>
                        </MDBCol>
                        <MDBCol className="d-flex justify-content-end col-5">
                          <Skeleton width={ width > 500 ? "100px" : "50%" } height="1.3em" borderRadius="8px" />
                        </MDBCol>
                      </MDBRow>
                      {/* <Skeleton width="100%" height="10em" borderRadius="8px" className="mt-5" /> */}
                      <div className="d-flex mt-5">
                        <Skeleton width={ width > 500 ? "250px" : "40%" } height="1.6em" borderRadius="8px" />
                        <Skeleton width={ width > 500 ? "80px" : "20%" } height="1.6em" borderRadius="8px" className="ms-3" />
                      </div>
                      <Skeleton width={ width > 500 ? "55%" : "100%" } height="1.3em" borderRadius="8px" className="mt-4"/>
                    </MDBContainer>
                  ) : (
                    <MDBContainer className="food-order">
                      <MDBRow className="food-title">
                        <MDBCol className="col-8 title">
                        {option.code ? (
                          <MDBCol className="col-2">
                            <label className="_label --code text-center ms-1 mt-0">
                              {option.code}
                            </label>
                          </MDBCol>
                        ) : (
                          ""
                        )}
                          <label className="_label --title">
                            {option ? option.name : ""}
                          </label>
                        </MDBCol>
                        <MDBCol>
                          <label className="_label --price">
                            {option
                              ? "RM" + parseFloat(option.unit_price).toFixed(2)
                              : ""}
                          </label>
                          <p className="_p --price">Base price</p>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="food-title">
                        <MDBCol className="col-9">
                          <p className="_p --detail">
                            {option ? option.description : ""}
                          </p>
                        </MDBCol>
                        <MDBCol>
                          {/* <p className="_p --price">Base price</p> */}
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="note-1">
                        <label className="_label --note">
                          Note to restaurant &nbsp;
                          <span className="_span --note">Optional</span>
                        </label>
                        <Text
                          placeholder="e.g no pickles "
                          name="remark"
                          className="text_remark"
                        />
                      </MDBRow>
                    </MDBContainer>
                  )}
                  <MDBRow className="count_button">
                    <InputCount
                      className="cart-btn"
                      quantity={quantity}
                      changePrice={changePrice}
                    />
                  </MDBRow>
                  <MDBRow className="button">
                    <Button
                      name={"Add to Order"}
                      type="text"
                      className="cart_button"
                      disabled={
                        loading ? true : false || quantity == 0 ? true : false
                      }
                      loading={addingOrder}
                    />
                  </MDBRow>
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}
