import moment from "moment/moment";
import { useState, useEffect } from "react";
import { RemoveScroll } from "react-remove-scroll";
import { MDBCol, MDBRow, MDBModalBody, MDBContainer } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import InfiniteScroll from "react-infinite-scroll-component";

//assets
import { Logo2 } from "../../assets/images"

//component
import ModalBox from "../../components/modal/full.modal.box";
import ModalHeader from "../../components/header/modal.header";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  ticket_history,
  purchaseReceipt,
} from "../../redux/reducer/reportReducer";
import { openModalReceipt3 } from "../../redux/reducer/modalReducer";
import useWindowDimensions from "../../components/common/window-dimension";

export default function ModalClaimHistory() {
  const [id, setId] = useState(0);

  const dispatch = useDispatch();
  const { isOpenClamHistory } = useSelector((state) => state.modal);
  const { claim_loading, claim } = useSelector((state) => state.report);

  const [showPurchase, setShowPurchase] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [length, setLength] = useState(10);
  const [start, setStart] = useState(0);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const { height } = useWindowDimensions()


  const getMoreData = () => {
    setIsLoading(true)
    dispatch(ticket_history({
      length : length,
      start : start + 10
    }))
    .unwrap()
    .then((res) => {
      setIsLoading(false)
      setStart(start + 10);
      let newData = [];
      for (var key in res.data.aaData) {
        newData.push({
          ...res.data.aaData[key],
        });
      }
      if (data.length + newData.length >= res.data.iTotalRecords) {
        setHasMore(false);
      }
      setData([...data, ...newData]);
    })
    .catch((ex) => {});
  }

  useEffect(() => {
    if (id) {
      dispatch(openModalReceipt3());
      dispatch(purchaseReceipt({ order_id: id }));
    }
    if (id !== 0) {
      setId(0);
    }
  }, [id]);

  useEffect(() => {
    setLoader(true)
    dispatch(ticket_history({
      length: length,
      start: start,
    }))
    .unwrap()
    .then((res) => {
      setData(res.aaData);
      setLoader(false)
      if (data.length !== res.iTotalRecords) {
      setHasMore(true)
      }
    })
    .catch((ex) => {});
  }, []);

  let scrollHiegth = height - 88.8


  return (
    <RemoveScroll>
      <ModalBox
        show={isOpenClamHistory}
        screenSize="fullscreen-xl-down"
        className="claim-modal"
        content={
          <MDBModalBody>
            <ModalHeader title="Ticket Claimed History" />
            <MDBContainer className="claim-container">
            <InfiniteScroll 
              dataLength={data.length}
              height={`${scrollHiegth}px`}
              hasMore={hasMore}
              next={() => {
              getMoreData();
              }}
             loader={data.length > 0 && <h4 className="text-center mt-5" style={{color : "#D1d5db"}}>Loading...</h4>}
      >

        
        {loader ? 
        <div style={{display : "flex" , marginTop : "3em" , marginBottom : "5em", alignItems: "center" , justifyContent : "center"}}>
             <Icon icon="line-md:loading-twotone-loop" color="#e26368"  height={"2.5em"}/>
              </div>  :
       !loader && data && data.length > 0
           ? data.map((item, index) => {
    return (
      <MDBRow
        key={index}
        className="listing-div"
        onClick={() => {
          setId(item.order_id);
        }}
      >
        <MDBCol
          className={
            item.claim == item.quantity ? "col-8" : "col-10"
          }
        >
          <MDBRow>
            <label className="_label --name">
              {item.merchant_name}
            </label>
          </MDBRow>
          <MDBRow>
            <label className="_label --date">
              at &nbsp;
              {moment(item.created_at).format(
                "DD MMM YYYY h:mm A"
              )}
            </label>
          </MDBRow>
          <MDBRow>
            <label className="_label --order_no">
              Order No. {item.order_no}
            </label>
          </MDBRow>
          <MDBRow>
            <label className="_label --order_no">
              {/* Order No. {item.order_no} */}
            </label>
          </MDBRow>
        </MDBCol>

        {item.claim == item.quantity ? (
          <MDBCol className="col-4 col-sticker">
            <label className="_label --sticker_text">
              Fully Claimed
            </label>
          </MDBCol>
        ) : (
          <MDBCol className="col-2">
            <MDBRow>
              <label className="_label --ticket">Claim</label>
            </MDBRow>
            <MDBRow>
              <label className="_label --no">
                {parseFloat(item.claim).toFixed(0)}
              </label>
            </MDBRow>
          </MDBCol>
        )}
      </MDBRow>
    );
  }) : 
  <div className="d-flex flex-column align-items-center empty-div" style={{ background: 'transparent' }}>
    <div style={{ height: '75%', textAlign: 'center' }}>
  <div className="loading-lottie">
  <img
    src={Logo2}
    className="logo-image"
    alt="logo-image"
    fetchpriority="high"
  />
  </div>
  <div className="loading-div d-flex justify-content-center">
    No Data Available
  </div>
</div>
</div>
  }
        </InfiniteScroll>
            </MDBContainer>
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}




