import { useState , useEffect , useLayoutEffect} from "react";
import { Link } from "react-scroll";
import { Icon } from "@iconify/react";
import { Skeleton } from "primereact/skeleton";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";

//redux
import { useDispatch, useSelector } from "react-redux";
import {
  setSearchValue,
  setSearching,
} from "../../../redux/reducer/menuReducer";

export default function Category() {
  const [activeSections, setActiveSections] = useState(null);

  const dispatch = useDispatch();
  const { loading, f_category, searchValue } = useSelector(
    (state) => state.menu
  );

  const categoryId = f_category.length > 0 ? f_category.map(item => item.id) : []

  useEffect(() => {
    function handleScroll() {
      const windowHeight = window.innerHeight;

      categoryId.forEach((id) => {
        const element = document.getElementById(`${id}`);
        const elementItem = document.getElementById(`item-${id}`);
        const elementPosition = element.getBoundingClientRect().top;
        const isVisible = elementPosition <= windowHeight * 0.25;
        if (isVisible) {
          setActiveSections(id);
          elementItem.scrollIntoView({
            // behavior: "smooth",
            block: "nearest",
            // inline: "start"
          });
        }
      });
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if(activeSections !== null ) {
      const elementItem = document.getElementById(`item-${activeSections}`);
        elementItem.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start"
        });
    }
  }, [activeSections]);

  return (
    <MDBContainer className="element-container">
      <MDBRow className="menu_search">
        <MDBCol>
          <input
            id="input-a"
            className="w-100"
            type="search"
            name="input"
            value={searchValue}
            placeholder="Search food name or code"
            onChange={(e) => {
              dispatch(setSearchValue(e.target.value));
              if (e.target.value == "") {
                dispatch(setSearching(false));
              } else {
                dispatch(setSearching(true));
              }
            }}
          />
        </MDBCol>
        <MDBCol className="col-2">
          <Icon
            icon="dashicons:search"
            width="30"
            heigth="27"
            className="icon"
          />
        </MDBCol>
      </MDBRow>
       <div className="category-row">
        {f_category && f_category.length > 0 && f_category.map((item , index) => {
          return (
            <div className="category-div" key={index} id={`item-${item.id}`}><Link
            to={String(item.id)}
            offset={-185}
            >
            <label className={activeSections === item.id ? "active" : ""}>{item.name}</label>
            </Link></div>
          )
        })}
      </div>
    </MDBContainer>
  );
}
