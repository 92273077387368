import axios from 'axios'
import { toast } from 'react-toastify'
import { Notification } from '../helper/react_notification'

axios.interceptors.response.use(null, (error) => {
  const authError = error.response && error.response.status === 401
  const exptectedError = error.response && error.response.status >= 404
  const unexpectedError = error.response.status === 500

  if (authError) {
    localStorage.clear()

    // return (window.location = "/login");
  }

  if (unexpectedError) {
    // eslint-disable-next-line default-case
    // logger.log(error);
    // toast.error('An unexpected error occurred @contact support', {
    //   theme: 'colored',
    //   hideProgressBar: true,
    //   closeOnClick: true,
    //   closeButton: false,
    // })
    Notification({
      title: "Something went wrong!",
      message: `An unexpected error occurred, please contact @support.`,
      type: "danger",
    })
  }
  //this.props.history.push("/error500");
  //window.location = "/error500";
  return Promise.reject(error)
})

function setToken(token) {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
    Accept: `application/json`,
  }
}

function setBank(token) {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
    Accept: `application/json`,
    "Abp.TenantId" : 29, "Access-Control-Allow-Credentials": true
  }
}

function setPayment(e) {
  axios.defaults.headers.common = {
    Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjUwIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZSI6ImFkbWluIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvZW1haWxhZGRyZXNzIjoianVzdGluLnN5Y0B0ZWNocG9sZS5jbyIsIkFzcE5ldC5JZGVudGl0eS5TZWN1cml0eVN0YW1wIjoiT0ZQRkdOWk5GWDRSQUFKRkZYWUY3RE1ITzYyWFFVSFciLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBZG1pbiIsImh0dHA6Ly93d3cuYXNwbmV0Ym9pbGVycGxhdGUuY29tL2lkZW50aXR5L2NsYWltcy90ZW5hbnRJZCI6IjI5Iiwic3ViIjoiNTAiLCJqdGkiOiI4MzI3YzJiYy0xNjBiLTQwYTAtOTU3NS01OGJkMjRmZjgwODkiLCJpYXQiOjE2NzcxMTYxNTQsInRva2VuX3ZhbGlkaXR5X2tleSI6ImYyMjg3MDdiLWMyNWQtNGQ2Mi1hMzExLTYyMTUxZDNmZDQ2ZSIsInVzZXJfaWRlbnRpZmllciI6IjUwQDI5IiwidG9rZW5fdHlwZSI6IjAiLCJuYmYiOjE2NzcxMTYxNTQsImV4cCI6MTY3NzIwMjU1NCwiaXNzIjoiQ29tbWVyY2VBc2lhIiwiYXVkIjoiQ29tbWVyY2VBc2lhIn0.WcfaNBhUVddH2Y9wCwBOtoMRJYrl-PIiM5cLMs_--rY`,
    "Abp.TenantId" :29,
   " cap-signature" : e,
   Accept: `application/json`,
   "Access-Control-Allow-Origin" : `*` ,
  " Access-Control-Expose-Headers" : `*`
  }
}

const httpService = {
  get: axios.get,
  patch: axios.patch,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setToken,
  setPayment,
  setBank
};

export default httpService
