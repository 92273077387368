import { createSlice ,createAsyncThunk} from "@reduxjs/toolkit";
import apiServices from "../../services/api.services";

export const transaction = createAsyncThunk("post/order", async ({cart_id ,amount , device_id}) => {
  const response = await apiServices.transactionOrder({cart_id , amount, device_id});
  return response.data;
});

export const purchase_history = createAsyncThunk("post/purchase_history", async (payload) => {
  const response = await apiServices.purchaseHistory(payload);
  return response.data;
});

export const ticket_history = createAsyncThunk("post/ticket_history", async (payload) => {
  const response = await apiServices.ticketHistory(payload);
  return response.data;
});

export const cashbackData = createAsyncThunk("post/cashback", async () => {
  const response = await apiServices.cashbackData();
  return response.data;
});

export const walletHistory = createAsyncThunk("post/wallet_history", async (payload) => {
  const response = await apiServices.walletHistory(payload);
  return response.data;
});

export const purchaseReceipt = createAsyncThunk("post/receipt", async (id) => {
  const response = await apiServices.purchaseHistoryDetail(id);
  return response.data;
});

const initialState = {
    data:[],
    history:[],
      purchase:[
      ],
      claim:[
      ],
      cashback:"",
      receipt:{
        date_and_time : "",
        order_id : "" ,
        receipt_no : "",
        user_order : {
          merchant : ""
        }
      },
      id:0,
    purchase_loading: false,
    claim_loading: false,
    cashback_loading: false,
    history_loading: false
}

const reportSlice = createSlice({
    name: "report",
    initialState,
    reducers: {
      
    },
    extraReducers:{
    [transaction.pending]: (state, action) => {
        state.loading = true;
      },
      [transaction.fulfilled]: (state, action) => {
        state.loading = false;
        state.data = action.payload;
      },
      [purchase_history.pending]: (state, action) => {
        state.purchase_loading = true;
      },
      [purchase_history.fulfilled]: (state, action) => {
        state.purchase_loading = false;
        state.purchase = action.payload.data;
      },
      [purchase_history.rejected]: (state, action) => {
        state.purchase_loading = false;
      },
      [ticket_history.pending]: (state, action) => {
        state.claim_loading = true;
      },
      [ticket_history.fulfilled]: (state, action) => {
        state.claim_loading = false;
        state.claim = action.payload;
      },
      [ticket_history.rejected]: (state, action) => {
        state.claim_loading = false;
      },
      [cashbackData.pending]: (state, action) => {
        state.loading = true;
      },
      [cashbackData.fulfilled]: (state, action) => {
        state.loading = false;
        state.cashback = action.payload;
      },
      [cashbackData.rejected]: (state, action) => {
        state.loading = false;
      },
      [purchaseReceipt.pending]: (state, action) => {
        state.loading = true;
      },
      [purchaseReceipt.fulfilled]: (state, action) => {
        state.loading = false;
        state.receipt = action.payload;
      },
      [walletHistory.pending]: (state, action) => {
        state.history_loading = true;
      },
      [walletHistory.fulfilled]: (state, action) => {
        state.history_loading = false;
        state.history = action.payload;
      },
      [walletHistory.rejected]: (state, action) => {
        state.history_loading = false;
      },
    }
})

export const {
  
} = reportSlice.actions
const { reducer } = reportSlice
export default reducer;