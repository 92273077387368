import { useEffect, useState } from "react";
import ModalBox from "../../../components/modal/half.modal.box";
import { MDBCol, MDBRow, MDBContainer, MDBBtn } from "mdb-react-ui-kit";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  openModalCart,
  closeModalCart2,
  closeAllModal,
} from "../../../redux/reducer/modalReducer";
import {
  changePrice,
  option,
  setMaxfoodQuantity,
} from "../../../redux/reducer/menuReducer";
import { Icon } from "@iconify/react";

//helper
import { formatNumber } from "../../../helper/return_number";

export default function ModalCart2() {
  const dispatch = useDispatch();
  const { food_id, cart } = useSelector((state) => state.menu);
  const { isOpenCart2 } = useSelector((state) => state.modal);

  const foodQuantity = [];
  const [sum, setSum] = useState(0);
  const [makingOrder, setMakingOrder] = useState(false);

  const make_another = () => {
    setMakingOrder(true)
    dispatch(option({ menu_id: food_id.id }))
      .unwrap()
      .then((res) => {
        setMakingOrder(false)
        dispatch(closeModalCart2());
        dispatch(openModalCart());
      })
      .catch((ex) => {
        setMakingOrder(false)
      });
    dispatch(changePrice(1));
    dispatch(setMaxfoodQuantity(sum));
  };

  const closeModal = () => {
    dispatch(closeAllModal());
  };

  useEffect(() => {
    cart.cart_item &&
      cart.cart_item.map((item) => {
        if (food_id.id === item.cart_item_menu_id) {
          foodQuantity.push(item.cart_item_quantity);
          setSum(foodQuantity.reduce((acc, curr) => acc + curr, 0));
        }
      });
  }, []);

  return (
    <ModalBox
      onDismiss={closeModal}
      show={isOpenCart2}
      className="cart-modal-2"
      content={
        <MDBContainer className="food-order">
          <MDBRow className="food-title">
            <MDBCol className="col-7">
              <label className="_label --title ">
                {food_id.code} &nbsp; {food_id.name}
              </label>
            </MDBCol>
            <MDBCol>
              <MDBRow>
                <label className="_label --price">RM {formatNumber(food_id.price)}</label>
              </MDBRow>
              <MDBRow>
                <label className="_label --text">Base price</label>
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            {cart.cart_item && cart.cart_item.length > 0
              ? cart.cart_item.map((item, index) => {
                  return (
                    <div key={index}>
                      {food_id.id === item.cart_item_menu_id ? (
                        <MDBContainer className="food-container">
                          <MDBRow className="food-listing">
                            <MDBCol className="col-8">
                              <MDBRow>
                                <label className="_label --food-name">
                                  {item.cart_item_name}
                                </label>
                              </MDBRow>
                              <MDBRow>
                                <label className="_label --food-name">
                                  {item.cart_item_remark}
                                </label>
                              </MDBRow>
                            </MDBCol>
                            <MDBCol className="col-2 food-quantity">
                              <label className="_label --quantity">
                                {item.cart_item_quantity}
                              </label>
                            </MDBCol>
                            <MDBCol className="col-2 end">
                              <label className="_label --price">
                                {" "}
                                {parseFloat(item.cart_item_total_price).toFixed(
                                  2
                                )}
                              </label>
                            </MDBCol>
                          </MDBRow>
                        </MDBContainer>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })
              : ""}
          </MDBRow>
          <MDBRow className="button mb-2">
            <MDBBtn
              className="button-group submit-button"
              onClick={() => {
                make_another();
              }}
              style={{ background: makingOrder ? "#ec999d" : "", color: makingOrder ? "#fafafa" : "" }}
            >
              <div className="d-flex align-items-center justify-content-center">
              Make Another 
              {makingOrder && 
                <Icon icon="line-md:loading-twotone-loop" color="#fafafa" width="1.4em" className="ms-3" />
              }
              </div>
            </MDBBtn>
          </MDBRow>
        </MDBContainer>
      }
    />
  );
}
