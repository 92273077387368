//framework & plugin
import { MDBModal, MDBModalDialog, MDBModalContent } from "mdb-react-ui-kit";
import { RemoveScroll } from "react-remove-scroll";

import { useSelector } from "react-redux";

export default function ModalBox({
  className,
  show,
  screenSize,
  content,
  setShow,
  scrollable,
  type,
  closeFunction
}) {
  const { isOpenPayFail } = useSelector((state) => state.modal);

  return (
    <RemoveScroll>
      <MDBModal
        staticBackdrop
        className={`full-modal-box-container ${className}`}
        tabIndex="-1"
        show={show}
        setShow={setShow}
      >
              <div style={{position:"absolute" , height : "100%" ,width : "100%"}} onClick={() => {if(closeFunction)  {
                closeFunction()
              }}}>

                </div>
        <MDBModalDialog
          size={`${screenSize}`}
          scrollable={scrollable ? true : false}
          style={
            type == "payment-failed"
              ? { justifyContent: "center", display: "flex" }
              : { justifyContent: "center" }
          }
          centered
        >
          <MDBModalContent
            className="modal-content"
            style={
              type == "payment-failed" ? { width: "80%" } : { width: "100%" }
            }
          >
            {content}
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </RemoveScroll>
  );
}
