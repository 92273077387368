//lib
import {  MDBRow} from 'mdb-react-ui-kit'
import { useSelector } from 'react-redux'

//component
import useWindowDimensions from "../../../components/common/window-dimension";

export default function PolicyData() {
  const { privacyListing} = useSelector((state) => state.common);
  const { width } = useWindowDimensions();
  return (
   <div>
    <p  style={{fontSize : width > 720 ? "18px" : ""}} className='_label --tag'>Your acceptance of the FoodLink System Terms of Agreement signifies your commitment to comply with and be legally bound by the following terms and conditions:</p>
      {privacyListing.map((item , index) => {
        return(
            <MDBRow key={index} style={{marginTop : "0.5em"}}>
                <label style={{fontSize : width > 720 ? "20px" : ""}} className='_label --title'>{item.title}</label>
                <br />
                <p  style={{fontSize : width > 720 ? "18px" : ""}} className='_p'>{item.content}</p>
            </MDBRow>
        )
    })}
   </div>
  )
}
