import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";

//component
import ProtectedRoute from "../../components/common/protected.route";
import MainHeader from "../../components/header/main-header";
import Card from "./component/card";
import Ticket from "./component/ticket";
import AlcoholicListing from "./component/alcoholic.listing";
import ModalAlcoholic from "./component/alcoholic.modal";
import ModalAlcoholic2 from "./component/alcoholic.modal2";
import ModalDenied from "../Modal/denied";
import ModalPeregister from "../Modal/peregister";

//image
import { Banner } from "../../assets/images";

//redux
import { useDispatch, useSelector } from "react-redux";
import { closeAllModal } from "../../redux/reducer/modalReducer";
import { card, alcoholic, category } from "../../redux/reducer/menuReducer";
import { Skeleton } from "primereact/skeleton";

export default function Alcoholic() {
  const now = Date.now();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const table = localStorage.getItem("table");
  const expired = localStorage.getItem("expired");
  const [animation, setAnimation] = useState(false);

  const { isOpenAlcoholic, isOpenAlcoholic2, isOpenDenied, isOpenPeregister } =
    useSelector((state) => state.modal);
  const { picture } = useSelector((state) => state.menu.data);
  const { card_loading } = useSelector((state) => state.menu);

  const inputBRef = useRef(null);

  const menuPageContainerRef = useRef(null);

  const toggleVisible = () => {
    const scrolled =
      document.documentElement.scrollTop || document.body.scrollTop;
    if (scrolled > 0) {
      setAnimation(true);
    } else if (scrolled < 300) {
      setAnimation(false);
    }
  };

  document.addEventListener("scroll", toggleVisible);

  const scrollToTop = () => {
    if (menuPageContainerRef.current) {
      menuPageContainerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const closeModal = () => {
    dispatch(closeAllModal());
  };

  useEffect(() => {
    if (now > expired) {
      navigate("/");
    }
    scrollToTop();
    dispatch(card(table));
    dispatch(
      alcoholic({
        remark: "alcohol",
        table_id: table,
      })
    );
    dispatch(
      category({
        remark: "alcohol",
        table_id: table,
      })
    );
  }, []);

  return (
    <>
      <MDBContainer className="alcoholic-page-container">
        <ProtectedRoute />
        <MainHeader animation={animation} />
        <MDBRow className="alcoholic-banner">
          {!card_loading && picture ? (
            <img
              src={picture ? picture : Banner}
              className="banner_image"
              alt="store-image"
              placeholder={Banner}
              fetchpriority="high"
            />
          ) : (
            <Skeleton width="100%" height="16.5em" borderRadius="1em" className="mt-2"/>
          )}
        </MDBRow>
        <MDBRow className="alcoholic-location-card">
          <Card />
        </MDBRow>
        <MDBRow className="alcoholic-ticket">
          <Ticket />
        </MDBRow>
        <AlcoholicListing inputBRef={inputBRef} animation={animation} />
      </MDBContainer>
      {isOpenAlcoholic && <ModalAlcoholic />}
      {isOpenAlcoholic2 && <ModalAlcoholic2 onDismiss={closeModal} />}
      {isOpenDenied && <ModalDenied message="expired" />}
    </>
  );
}
