import ProtectedRoute from "../../components/common/protected.route";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Pusher from "pusher-js";

//component
import MainHeader from "../../components/header/main-header";
import PartialRegisterModal from "../../components/modal/partial.register.modal";
import Login from "../Login";
import OrderListing from "./component/order.listing";
import BankModal from "./component/bank.modal";
import ModalPurchaseHistory from "../Modal/purchase.history";
import ModalReceipt from "../Modal/receipt";
import ModalClaimHistory from "../Modal/claim.history";
import ModalAccount from "../Modal/account";
import ModalCashback from "../Modal/cashback";
import ModalChangePassword from "../Modal/change.password";
import ModalChangePhone from "../Modal/change.phone";
import ModalInformation from "../Modal/information";
import ModalRemove from "../Modal/remove";
import Guest from "../Modal/guest";
import ModalPurchaseReceipt from "../Modal/purchase.receipt";
import ModalClaimReceipt from "../Modal/claim.receipt";
import EditFood from "./component/edit.modal";
import ModalDenied from "../Modal/denied";
import ModalPeregister from "../Modal/peregister";
import PreRegister from "../Modal/register";
import ModalRedeemOption from "../Modal/redeem.option";
import LoadingPage from "./component/loading";

//redux
import { useDispatch, useSelector } from "react-redux";
import {
  closeAllModal,
  openModalDenied,
} from "../../redux/reducer/modalReducer";
import { setBankLoading } from "../../redux/reducer/paymentReducer";
import { cartListing } from "../../redux/reducer/cartReducer";
import { cashbackData } from "../../redux/reducer/reportReducer";

export default function Cart() {
  const deviceId = navigator.userAgent.replace(/[^\w]/gi, "");
  const now = Date.now();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const expired = localStorage.getItem("expired");
  const p_guest = localStorage.getItem("p_guest");
  const pre_number = localStorage.getItem("pre_number");
  const userID = localStorage.getItem("userID");
  const username = localStorage.getItem("username");
  const [title, setTitle] = useState("");
  const [id, setId] = useState(0);
  const {
    isOpenBank,
    isOpenReceipt,
    isOpenReceipt2,
    isOpenPurchaseHistory,
    isOpenClamHistory,
    isOpenChangePassword,
    isOpenAccount,
    isOpenChangePhone,
    isOpenCashBack,
    isOpenLogin,
    isOpenInformation,
    isOpenRemove,
    isOpenReceipt3,
    isOpenEdit,
    isOpenDenied,
    isOpenRedeem,
    isOpenPeregister,
  } = useSelector((state) => state.modal);
  const { bank_loading } = useSelector((state) => state.payment);
  const { loadingPageStatus } = useSelector((state) => state.cart);
  const { logoutStatus } = useSelector((state) => state.common);
  const [showBottom, setShowBottom] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const closeModal = () => {
    dispatch(closeAllModal());
  };

  useEffect(() => {
    if (now > expired) {
      navigate("/");
    }
    dispatch(cartListing({ device_id: deviceId }));
    if (token) {
      // dispatch(cashbackData());
    }

    scrollToTop();
  }, [logoutStatus]);

  const showModalUp = () => {
    setShowBottom(!showBottom);
  };

  useEffect(() => {
    if (username && userID && (p_guest || token)) {
      const authHeaders = token ? {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        username: username,
      } : {
        Authorization: `Bearer ${p_guest}`,
        Accept: "application/json",
        username: pre_number,
      };
  
      const pusher = new Pusher(process.env.REACT_APP_SOCKET_API_KEY, {
        cluster: "ap1",
        authEndpoint: process.env.REACT_APP_SOCKET_API_URL,
        auth: { headers: authHeaders },
      });
  
      const channel = pusher.subscribe("presence-user" + userID);
      channel.bind("order-paid-event", (data) => {
        dispatch(setBankLoading(false))
        if (data.data.payment_status === 'active' && data.data.order_id !== null) {
          navigate(`/order?status=success&order_id=${data.data.order_id}`);
        }
      });
  
      return () => {
        channel.unbind("order-paid-event");
        pusher.disconnect();
      };
    }
  }, [username]);

  return (
    <>
      <MDBContainer className="cart-page-container">
        {bank_loading ? (
          <LoadingPage />
        ) : (
          <>
            <ProtectedRoute />
            <MainHeader />
            <MDBContainer className="cart-container">
              <MDBRow className="cart-title">
                <MDBCol className="col-12 title-col">
                  <label className="_label --title">Your Order</label>
                  &nbsp;&nbsp;
                  <Icon icon="bx:food-menu" height="1.6em" className="icon" />
                </MDBCol>
                <label className="_label --text">Order Summary</label>
              </MDBRow>
              <OrderListing setTitle={setTitle} setId={setId} />
            </MDBContainer>
            {p_guest || token ? (
              <PartialRegisterModal
                onDismiss={showModalUp}
                showBottom={showBottom}
                show={token || p_guest ? true : false}
                content={
                  <Guest
                    name="cart"
                    showBottom={showBottom}
                    setShowBottom={setShowBottom}
                  />
                }
              />
            ) : (
              <PartialRegisterModal
                show={!token || !p_guest ? true : false}
                content={<PreRegister name="/cart" />}
              />
            )}
            {isOpenLogin && <Login />}
            {isOpenBank && <BankModal onDismiss={closeModal} />}
            {isOpenPurchaseHistory && <ModalPurchaseHistory />}
            {isOpenReceipt && <ModalReceipt />}
            {isOpenClamHistory && <ModalClaimHistory />}
            {isOpenReceipt2 && <ModalPurchaseReceipt />}
            {isOpenReceipt3 && <ModalClaimReceipt />}
            {isOpenCashBack && <ModalCashback />}
            {isOpenAccount && <ModalAccount />}
            {isOpenChangePassword && <ModalChangePassword />}
            {isOpenChangePhone && <ModalChangePhone />}
            {isOpenInformation && <ModalInformation title={title} />}
            {isOpenRemove && <ModalRemove food_id={id} />}
            {isOpenDenied && <ModalDenied message="expired" />}
            {isOpenRedeem && <ModalRedeemOption onDismiss={closeModal} />}
            {/* {isOpenPeregister && <ModalPeregister />} */}
          </>
        )}
      </MDBContainer>
      {loadingPageStatus && <LoadingPage />}
      {isOpenEdit && <EditFood />}
    </>
  );
}
