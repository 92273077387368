import { useFormikContext } from "formik";

import { RemoveScroll } from "react-remove-scroll";
import { MDBCol, MDBRow, MDBModalBody, MDBContainer } from "mdb-react-ui-kit";

//component
import ModalBox from "../../components/modal/full.modal.box";

//redux
import { useSelector, useDispatch } from "react-redux";
import { closeModalUpdateProfile } from "../../redux/reducer/modalReducer";

export default function ModalUpdateProfile({ handleSubmit }) {
  const dispatch = useDispatch();
  const { setFieldError, values, resetForm } = useFormikContext();
  const { isOpenUpdateProfile } = useSelector((state) => state.modal);

  const closeModal = () => {
    dispatch(closeModalUpdateProfile());
    resetForm({ values: values });
  };
  const submitForm = () => {
    handleSubmit({ values, setFieldError });
    resetForm({ values: values });
  };

  return (
    <RemoveScroll>
      <ModalBox
        show={isOpenUpdateProfile}
        screenSize="s"
        className="update-modal"
        content={
          <MDBModalBody className="update-body">
            <MDBRow className="mt-4 mb-2">
              <label className="_label --title">Update Profile ?</label>
            </MDBRow>
            <MDBRow className="mt-4 mb-2">
              <p className="_p">
                Birthday and Gender only allowed limited changes attempt. Please
                make sure to fill in the correct information.
              </p>
            </MDBRow>
            <MDBRow>
              <MDBCol className="col-6"></MDBCol>
              <MDBCol className="col-3 text-center">
                <label className="_label --cancel" onClick={() => closeModal()}>
                  Cancel
                </label>
              </MDBCol>
              <MDBCol className="col-3 text-center">
                <label className="_label --ok" onClick={() => submitForm()}>
                  OK
                </label>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}
