import { useEffect } from "react";
import { Icon } from "@iconify/react";
import { useNavigate , useLocation} from "react-router-dom";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";

//component
import config from "../../config.json";

//redux
import { useDispatch, useSelector } from "react-redux";
import {
  openModalLogin,
  openModalPurchaseHistory,
  openModalClaimHistory,
  openModalAccount,
  openModalCashBack,
  openModalChangePhone,
} from "../../redux/reducer/modalReducer";
import { setLogoutStatus } from "../../redux/reducer/commonReducer";
import { profile, setProfileData } from "../../redux/reducer/profileReducer";
import { orderListing ,cartListing} from "../../redux/reducer/cartReducer";
import { cashbackData } from "../../redux/reducer/reportReducer";

//auth
import authServices from "../../services/auth.services";

//helper
import { formatNumber } from "../../helper/return_number";

export default function Guest({ setShowBottom, showBottom, name , table_id }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.menu);
  const { data , userno} = useSelector((state) => state.profile);
  const { cashback } = useSelector((state) => state.report);
  const { pathname } = useLocation();
  const table = localStorage.getItem("table");

  const token = localStorage.getItem("token");
  const pre_number = localStorage.getItem("pre_number");
  const deviceId = navigator.userAgent.replace(/[^\w]/gi, "");

  const accountSetting = [
    {
      name: "Purchase History",
      icon: "icon-park-outline:transaction-order",
      state: openModalPurchaseHistory(),
    },
    {
      name: "Ticket Claimed History",
      icon: "uil:transaction",
      state: openModalClaimHistory(),
    },
    {
      name: "My Cashback",
      icon: "grommet-icons:money",
      state: openModalCashBack(),
    },
    {
      name: "My Account Details",
      icon: "mdi:account-outline",
      state: openModalAccount(),
    },
    {
      name: "Change Phone Number",
      icon: "ph:password",
      state: openModalChangePhone(),
    },
  ];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleLogout = () => {
    authServices.logout();
    if (name == "cart") {
      navigate("/cart");
    } else if (name == "/") {
      navigate("/");
    } else {
      navigate("/order");
    }
  };
  useEffect(() => {
    if (token && table_id === undefined) {
      dispatch(profile());
      dispatch(cashbackData())
    }
    scrollToTop();
  }, []);

  return (
    <div className="text-center partial-modal login">
      <MDBRow className="user-modal" onClick={() => setShowBottom(!showBottom)}>
        <MDBCol className="col-8 user-name">
          <label className="_label --user">
            <span className="span-number">
              {userno !== "" ? userno  :cart.username}&nbsp;
            </span>
            <span style={{fontSize : "1.4rem"}}>
             {cart.username !== null && cart.username !== undefined && `(${cashback !== null && cashback !== undefined && cashback !== "" ? formatNumber(cashback) : 0}  stamp)`}
            </span>
          </label>
        </MDBCol>
        <MDBCol className="col-4 text-center">
          <label>
            {showBottom ? (
              <Icon
                icon="material-symbols:close"
                height="28"
                onClick={() => setShowBottom(!showBottom)}
              />
            ) : (
              <Icon
                icon="ri:menu-2-fill"
                height="26"
                onClick={() => setShowBottom(!showBottom)}
              />
            )}
          </label>
        </MDBCol>
      </MDBRow>
      <MDBContainer className="account-container">
        <MDBRow className="text-start">
          <label className="_label --title">Account settings</label>
        </MDBRow>
        {accountSetting.map((item, index) => {
          return (
            <MDBRow
              key={index}
              className="modal-listing"
              onClick={() => {
                !token ? dispatch(openModalLogin()) : dispatch(item.state);
              }}
            >
              <MDBCol className="col-2">
                <Icon icon={item.icon} color="#FDFDFF" width="25" height="22" />
              </MDBCol>
              <MDBCol className="col-8">
                <label className="_label --name">{item.name}</label>
              </MDBCol>
              <MDBCol className="col-2">
                <Icon
                  icon="eva:arrow-ios-back-outline"
                  color="#FDFDFF"
                  width="28"
                  height="28"
                  hFlip={true}
                />
              </MDBCol>
            </MDBRow>
          );
        })}

        <MDBRow className="modal-listing">
          <MDBCol>
            <label
              className="_label --logout"
              onClick={() => {
                handleLogout();
                dispatch(setLogoutStatus())
                dispatch(setProfileData({}));
                dispatch(orderListing({ device_id: deviceId }));
              }}
            >
              Log out
            </label>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-3">
          <MDBCol>
            <label className="_label --logout" style={{fontSize : "10px"}}>Copyright © 2023 Food Link • Powered by MSI HUB • Version ({process.env.REACT_APP_VERSION})</label>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
        {/* <div className="text-center" style={{position : "fixed" , bottom : "2%" ,left : "23%" , color : "#000000"}}>Copyright © 2023 Food Link • Powered by Techpole </div> */}
    </div>
  );
}
