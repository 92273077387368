import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";

import { useRef } from "react";

//component
import ProtectedRoute from "../../components/common/protected.route";
import MainHeader from "../../components/header/main-header";
import Card from "./component/location.card";
import FoodListing from "./component/food.listing";
import ModalCart from "./component/cart.modal";
import ModalCart2 from "./component/cart2.modal";
import ModalDenied from "../Modal/denied";

//image
import { Banner } from "../../assets/images";

//redux
import { useDispatch, useSelector } from "react-redux";
import { closeAllModal } from "../../redux/reducer/modalReducer";
import { card, food, f_category } from "../../redux/reducer/menuReducer";
import { Skeleton } from "primereact/skeleton";

export default function Menu() {
  const now = Date.now();
  const dispatch = useDispatch();
  const table = localStorage.getItem("table");
  const expired = localStorage.getItem("expired");
  const [animation, setAnimation] = useState(false);
  const [animation2, setAnimation2] = useState(false);

  const inputBRef = useRef(null);

  const menuPageContainerRef = useRef(null);

  const { picture } = useSelector((state) => state.menu.data);
  const { card_loading } = useSelector((state) => state.menu);
  const { isOpenCart, isOpenCart2, isOpenDenied, isOpenPeregister } =
    useSelector((state) => state.modal);
  const navigate = useNavigate();

  const toggleVisible = () => {
    const scrolled =
      document.documentElement.scrollTop || document.body.scrollTop;
    if (scrolled > 0) {
      setAnimation(true);
    } else if (scrolled < 300) {
      setAnimation(false);
    }
  };

  document.addEventListener("scroll", toggleVisible);

  const closeModal = () => {
    dispatch(closeAllModal());
  };

  const scrollToTop = () => {
    if (menuPageContainerRef.current) {
      menuPageContainerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (now > expired) {
      navigate("/");
    }
    scrollToTop();
    dispatch(card(table));
    dispatch(
      food({
        remark: "food",
        table_id: table,
      })
    );
    dispatch(
      f_category({
        remark: "food",
        table_id: table,
      })
    )
      .unwrap()
      .then((res) => {})
      .catch((ex) => {
        navigate("/");
      });
  }, []);

  useEffect(() => {
    setAnimation2(animation);
  }, [animation]);

  return (
    <>
      <MDBContainer className="menu-page-container">
        <ProtectedRoute />
        <MainHeader animation={animation2} />
        <MDBRow className="menu-banner">
          {!card_loading && picture ? 
              <img
                src={picture ? picture : Banner}
                className="banner_image"
                alt="store image"
                placeholder={Banner}
                fetchpriority="high"
              />
            : 
            <Skeleton width="100%" height="16.5em" borderRadius="1em" className="mt-2"/>
          }
        </MDBRow>
        <MDBRow className="menu-location-card">
          <Card />
        </MDBRow>
        <FoodListing animation={animation2} inputBRef={inputBRef} />
      </MDBContainer>

      {isOpenCart && <ModalCart />}
      {isOpenCart2 && <ModalCart2 onDismiss={closeModal} />}
      {isOpenDenied && <ModalDenied message="expired" />}
    </>
  );
}
