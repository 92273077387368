import { Field, useFormikContext } from "formik";
import { MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";

//auth
import authServices from "../../services/auth.services";
import { Icon } from "@iconify/react";

export default function Button({
  label,
  name,
  type,
  disabled,
  className,
  sendCode,
  time,
  setTime,
  values,
  loading
}) {
  const { isValid, isSubmitting } = useFormikContext();
  const [ sendingCode, setSendingCode ] = useState(false);

  const sendVerificationCode = async () => {
    setSendingCode(true)
    try {
      const response = await authServices.sendCode({
        username: values.username,
        type: "phone verification",
      });
      if (response) {
        setSendingCode(false)
        setTime(60);
      }
    } catch (ex) {}
  };

  useEffect(() => {
    time > 0 && setTimeout(() => setTime(time - 1), 1000);
  }, [time]);

  return (
    <MDBCol className={`form-group _button ${className}`}>
      {label && (
        <MDBRow>
          <label htmlFor={name} className="form-label">
            {label}
          </label>
        </MDBRow>
      )}
      {sendCode == "code" ? (
        <MDBBtn
          className="button-group submit-button"
          style={{ background: sendingCode ? "#ec999d" : "", color: sendingCode ? "#fafafa" : "" }}
          onClick={() => sendVerificationCode()}
          disabled={disabled || sendingCode}
          type={type}
        >
          <div className="d-flex align-items-center justify-content-center">
          {name} 
          {sendingCode && 
            <Icon icon="line-md:loading-twotone-loop" color="#fafafa" width="1.4em" className="ms-3" />
          }
          </div>
        </MDBBtn>
      ) : (
        <MDBBtn
          type={type}
          className="button-group submit-button"
          disabled={disabled || !isValid || loading}
          style={{ background: loading ? "#ec999d" : "", color: loading ? "#fafafa" : "", opacity: 1 }}
        >
          <div className="d-flex align-items-center justify-content-center">
          {name} 
          {loading && 
            <Icon icon="line-md:loading-twotone-loop" color="#fafafa" width="1.4em" className="ms-3" />
          }
          </div>
        </MDBBtn>
      )}
    </MDBCol>
  );
}
