import {
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBModalBody,
  MDBContainer,
} from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { RemoveScroll } from "react-remove-scroll";
import apiServices from "../../services/api.services";

//component
import ModalBox from "../../components/modal/full.modal.box";

//redux
import { useSelector, useDispatch } from "react-redux";
import { closeAllModal } from "../../redux/reducer/modalReducer";
import { cartListing, setLoadingStatus , setCartOrderLoading , setCartLoading} from "../../redux/reducer/cartReducer";
import { total_item } from "../../redux/reducer/menuReducer";

export default function ModalRemove({ title, food_id }) {
  const deviceId = navigator.userAgent.replace(/[^\w]/gi, "");

  const dispatch = useDispatch();
  const { isOpenRemove } = useSelector((state) => state.modal);

  const updateCart = async () => {
    try {
      const response = await apiServices.updateCart({
        cart_item_id: food_id ? food_id : "",
        quantity: 0,
      });
      if (response) {
        dispatch(setCartLoading(true));
        dispatch(total_item({ device_id: deviceId }));
        dispatch(
          cartListing({
            device_id: deviceId,
          })
        );
        dispatch(closeAllModal());
      }
    } catch (ex) {
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {});
          }
        }
      }
    }
  };

  return (
    <RemoveScroll>
      <ModalBox
        show={isOpenRemove}
        screenSize="s"
        className="remove-modal"
        content={
          <MDBModalBody style={{ height: "18em" }}>
            <MDBContainer>
              <MDBRow className="header">
                <MDBCol className="icon-col mt-2 me-1">
                  <Icon
                    height="20"
                    icon="material-symbols:close"
                    onClick={() => dispatch(closeAllModal())}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <label className="remove-title">Are you sure?</label>
              </MDBRow>
              <MDBRow>
                <span className="remove-span">
                  Do you want to remove this item from cart?
                  <br />
                  This action can't be undone.
                </span>
              </MDBRow>
              <MDBContainer className="information">
                <MDBRow className="btn-row">
                  <MDBBtn
                    className="remove_button"
                    onClick={() => updateCart()}
                  >
                    Remove
                  </MDBBtn>
                </MDBRow>
              </MDBContainer>
            </MDBContainer>
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}
