import { useState } from "react";
import moment from "moment/moment";
import html2canvas from "html2canvas";
import { RemoveScroll } from "react-remove-scroll";
import { MDBCol, MDBRow, MDBModalBody, MDBContainer } from "mdb-react-ui-kit";
import { Icon } from '@iconify/react';

//component
import ModalBox from "../../components/modal/full.modal.box";
import ModalHeader from "../../components/header/modal.header";
import CongratulationsEffect from "../../components/common/congratulations";

//redux
import { useSelector } from "react-redux";

export default function ModalPurchaseReceipt() {
  const [image, setImage] = useState("");
  const { isOpenReceipt2 , isOpenCongratulations} = useSelector((state) => state.modal);
  const { receipt , loading } = useSelector((state) => state.report);

  const getImage = () => {
    var canvas = document.getElementById("receipt-table");
    html2canvas(canvas).then((canvas) => {
      const baserImage = canvas.toDataURL("image/png");
      var img = document.createElement("img");
      img.src = baserImage;
      setImage(img);
      canvas.toBlob((blob) => {
        // 创建分享数据
        var shareData = {
          files: [new File([blob], "screenshot.png", { type: "image/png" })],
        };

        // 如果 navigator.share 存在，则将分享数据作为参数调用它
        if (navigator.share) {
          navigator
            .share(shareData)
            .then(() => console.log("成功分享"))
            .catch((error) => console.error("分享失败", error));
        }
        // 如果 navigator.share 不存在，则提示用户无法分享
        else {
          alert("抱歉，您的浏览器不支持此功能");
        }
      });
    });
  };

  const paymentStatus = {
    completed: "Completed",
    Fail: "Fail",
    Pending: "Pending",
    Pending: "Pending",
  };

  const paymentStatusColor = {
    completed: "green",
    Fail: "red",
    pending: "yellow",
    Pending: "yellow",
  };

  return (
    <RemoveScroll>
      <ModalBox
        show={isOpenReceipt2}
        screenSize="fullscreen-xl-down"
        className="receipt-modal"
        content={
          <MDBModalBody>
            <ModalHeader title="Receipt" getImage={getImage} />
         {/* {isOpenCongratulations &&  receipt.result && receipt.result.order_no &&  <MDBContainer>
            <CongratulationsEffect/>
            </MDBContainer>} */}
            {!loading && receipt.result ? (
              <MDBContainer className="receipt-container" id="receipt-table">
                  
                  <MDBRow>
                  <label className="_label --title">
                    Order #
                    {receipt.result && receipt.result.order_no
                      ? receipt.result.order_no
                      : ""}
                  </label>
                </MDBRow>
                <MDBRow>
                  <label className="_label --title">
                    {receipt.result && receipt.result.merchant_name
                      ? receipt.result.merchant_name
                      : ""}
                  </label>
                </MDBRow>
                <MDBRow>
                  <label className="_label --address">
                    {receipt.result && receipt.result.merchant_address
                      ? receipt.result.merchant_address
                      : ""}
                  </label>
                </MDBRow>
                <MDBRow>
                  <label className="_label --address">
                    {receipt.result && receipt.result.sst_no
                      ? "SST ID : " + receipt.result.sst_no
                      : ""}
                  </label>
                </MDBRow>
                <MDBRow>
                  <label className="_label --table">INVOICE</label>
                </MDBRow>
                <MDBRow>
                  <label className="_label --table">
                    Table :{" "}
                    {receipt.result && receipt.result.table_name
                      ? receipt.result.table_name
                      : ""}
                  </label>
                </MDBRow>
                <MDBContainer className="payment-info">
                  <MDBRow>
                    <label className="_label --data">
                      Payment Status :{" "}
                      <span
                        style={{
                          color:
                            receipt.result &&
                            paymentStatusColor[receipt.result.payment_status],
                        }}
                      >
                        {receipt.result &&
                          paymentStatus[receipt.result.payment_status]}
                      </span>
                    </label>
                  </MDBRow>
                  <MDBRow>
                    <label className="_label --data">
                      Receipt No :{" "}
                      {receipt.result && receipt.result.receipt_no
                        ? receipt.result.receipt_no
                        : ""}{" "}
                    </label>
                  </MDBRow>
                  <MDBRow>
                    <label className="_label --data">
                      Transaction ID :{" "}
                      {receipt.result && receipt.result.reference_no
                        ? receipt.result.reference_no
                        : ""}{" "}
                    </label>
                  </MDBRow>
                  <MDBRow>
                    <label className="_label --data">
                      Date and time :{" "}
                      {moment(
                        receipt.result && receipt.result.created_at
                          ? receipt.result.created_at
                          : ""
                      ).format("DD MMM YYYY h:mm A")}
                    </label>
                  </MDBRow>
                </MDBContainer>

                <MDBRow className="list-title">
                  <MDBCol md="2" lg="2" className="col-1">
                    <label className="_label --qty">Qty</label>
                  </MDBCol>
                  <MDBCol
                    md="8"
                    lg="8"
                    className="col-8 justify-content-start d-flex"
                  >
                    <label className="_label --qty">Item</label>
                  </MDBCol>
                  <MDBCol md="" lg="" className="text-end">
                    <label className="_label --rm">RM</label>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <label className="_label --table">*** {receipt.result && receipt.result.method} ***</label>
                </MDBRow>
                <MDBRow>
                  {receipt.result && receipt.result.user_order
                    ? receipt.result.user_order.map((item, index) => {
                        return (
                          <MDBContainer key={index} className="order-listing">
                            <MDBRow>
                              <MDBCol md="2" lg="2" className="col-1 food-col">
                                <label className="_label --detail">
                                  {item.quantity}
                                </label>
                              </MDBCol>

                              <MDBCol
                               
                                className={
                                  item.menu_code
                                    ? "col-9 food-name"
                                    : "col-9 food-name"
                                }
                              >
                                <label className="_label --food">
                                  {item.menu_name}
                                </label>
                                {item.details
                                  ? item.details.map((item, index) => {
                                      return (
                                        <MDBRow key={index} className="p-0">
                                          <label className="_label --option">
                                            {item}
                                          </label>
                                        </MDBRow>
                                      );
                                    })
                                  : ""}
                                <MDBRow>
                                  <label className="_label --detail">
                                    {item.remark}
                                  </label>
                                </MDBRow>
                              </MDBCol>

                              <MDBCol className="col-1 food-col ">
                                <label className="_label --price">
                                {parseFloat(
                         item.total_price ? item.total_price : 0
                        ).toFixed(2)}
                                </label>
                              </MDBCol>
                            </MDBRow>
                          </MDBContainer>
                        );
                      })
                    : ""}
                </MDBRow>
                <MDBContainer className="receipt-detail">
                  <MDBRow className="detail">
                    <MDBCol className="p-0">
                      <label className="_label --data">Subtotal</label>
                    </MDBCol>
                    <MDBCol className="p-0 amount-col">
                      <label className="_label --data2">
                      {parseFloat(
                          receipt && receipt.result.subtotal ? receipt.result.subtotal : 0
                        ).toFixed(2)}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="detail">
                    <MDBCol className="p-0">
                      <label className="_label --data">
                        Service Charge (
                        {receipt &&
                        receipt.result &&
                        receipt.result.service_charge_percent
                          ? parseFloat(
                              receipt.result.service_charge_percent
                            ).toFixed(0)
                          : 0}
                        %)
                      </label>
                    </MDBCol>
                    <MDBCol className="p-0 amount-col">
                      <label className="_label --data2">
                        {parseFloat(
                          receipt.result && receipt.result.service_charge
                            ? receipt.result.service_charge
                            : 0
                        ).toFixed(2)}
                      </label>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow className="detail">
                    <MDBCol className="p-0">
                      <label className="_label --data">
                        {receipt.result && receipt.result.sales_service_tax == 0
                          ? " SST (0%)"
                          : "SST (6%)"}
                      </label>
                    </MDBCol>
                    <MDBCol className="p-0 amount-col">
                      <label className="_label --data2">
                              {parseFloat(
                          receipt.result && receipt.result.sales_service_tax
                            ? receipt.result.sales_service_tax
                            : 0
                        ).toFixed(2)}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="detail">
                    <MDBCol className="p-0">
                      <label className="_label --data">Voucher</label>
                    </MDBCol>
                    <MDBCol className="p-0 amount-col">
                      <label className="_label --data2">
                        {parseFloat(
                          receipt.result && receipt.result.voucher_deduction
                            ? receipt.result.voucher_deduction
                            : 0
                        ).toFixed(2)}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="total-paid">
                    <MDBCol className="p-0">
                      <label className="_label --data p-0">Total Paid:</label>
                    </MDBCol>
                    <MDBCol className="text-end p-0">
                      <label className="_label --data2 p-0 ">
                        {receipt.result ? parseFloat(receipt.result.total_price).toFixed(2) : ""}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <label className="_label --table">
                      Thank You , Please Come Again
                    </label>
                  </MDBRow>
                </MDBContainer>
              </MDBContainer>
            ) : (
              <div style={{display : "flex" , marginTop : "3em" , marginBottom : "5em", alignItems: "center" , justifyContent : "center"}}>
              <Icon icon="line-md:loading-twotone-loop" color="#e26368"  height={"2.5em"}/>
              </div>
            )}
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}
