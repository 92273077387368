import { Icon } from "@iconify/react";
import { RemoveScroll } from "react-remove-scroll";
import { MDBCol, MDBRow, MDBModalBody, MDBContainer } from "mdb-react-ui-kit";

//component
import ModalBox from "../../components/modal/full.modal.box";

//redux
import { useSelector, useDispatch } from "react-redux";
import { closeModalInformation } from "../../redux/reducer/modalReducer";

export default function ModalInformation({ title }) {
  const dispatch = useDispatch();
  const { isOpenInformation } = useSelector((state) => state.modal);

  return (
    <RemoveScroll>
      <ModalBox
        show={isOpenInformation}
        screenSize="s"
        className="information-modal"
        content={
          <MDBModalBody>
            <MDBContainer>
              <MDBRow
                className="header"
                style={{ borderBottom: "0.05em solid #dfdfdf" }}
              >
                <MDBCol lg="11" md="11" className="col-10 mt-2">
                  <label className="_label --title">{title ? title : ""}</label>
                </MDBCol>
                <MDBCol>
                  <Icon
                    className="icon-col"
                    icon="material-symbols:close"
                    height="20"
                    onClick={() => dispatch(closeModalInformation())}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="information">
                {title == "Dine In" ? (
                  <label className="_label --text">
                    Order food and wait for it to be served. Please make sure
                    your table number and ordered food are correct.
                  </label>
                ) : title == "Cashback" ? (
                  <label className="_label --text">
                    Start to order and earn cashback when you buy. It's a double
                    win.
                  </label>
                ) : (
                  <label className="_label --text">
                    It records the time that you ordered the food after making
                    the payment.
                  </label>
                )}
              </MDBRow>
            </MDBContainer>
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}
