import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    login: "Login",
    userId :"",
}

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        changeLogin : (state,action) =>{
            state.login = action.payload
        },
        changeUserId : (state,action) =>{
            state.userId = action.payload
        }
    },
    extraReducers:{

    }
})

export const {
    changeLogin,
    changeUserId
} = loginSlice.actions
const { reducer } = loginSlice
export default reducer;