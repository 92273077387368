import { Formik, Form } from "formik";
import * as Yup from "yup";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";

//component
import Text from "../../../components/element/text";
import Button from "../../../components/element/button";
import Code from "../../../components/element/code";
import CheckBox from "../../../components/element/checkbox";

//redux
import { useDispatch } from "react-redux";
import { changeUserId } from "../../../redux/reducer/loginReducer";

//auth
import authServices from "../../../services/auth.services";
import { Notification } from "../../../helper/react_notification";

export default function RegisterForm() {
  const guest = localStorage.getItem("guest");
  const dispatch = useDispatch();
  const userData = {
    username: "",
    verification_code: "",
    checkBox: false,
    type: "phone verification",
    user_id: guest ? guest : "",
  };

  const loginSchema = Yup.object({
    username: Yup.string()
      .required("Required")
      .matches(/^(0)(1)[0-9]{8}$/, "Invalid phone number format."),
    verification_code: Yup.number()
      .required("Required")
      .typeError("Verification code must be a number"),
    checkBox: Yup.bool().oneOf([true], ""),
  });

  const handleSubmit = async ({ values, setFieldError }) => {
    try {
      const response = await authServices.codeValidate(values);
      if (response) {
        try {
          const response = await authServices.register(values);
          if (response) {
            dispatch(changeUserId(values.username));
            Notification({
              title: "Successful!",
              message: `${values.phone_number} verified.`,
              type: "success",
            });
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            if (ex.response.status === 422) {
              const errors = ex.response.data.errors;
              if (errors && Object.keys(errors).length > 0) {
                Object.keys(errors).map((item, i) => {
                  setFieldError(item, errors[item]);
                });
              }
            }
          }
        }
      }
    } catch (ex) {
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
            });
          }
        }
      }
    }
  };

  return (
    <Formik
      initialValues={userData}
      validationSchema={loginSchema}
      onSubmit={(values, { resetForm, setFieldError, setFieldValue }) => {
        handleSubmit({ values, setFieldError });
        resetForm({ values: "" });
        setFieldValue("verification_code", "");
      }}
    >
      {({}) => (
        <Form>
          <MDBContainer className="login-body">
            <MDBRow>
              <label className="_label --note">Let’s Start your trip </label>
            </MDBRow>
            <MDBRow>
              <label className="_label --text">
                Get an account to enjoy more & benefit on your Selangor trip.
              </label>
            </MDBRow>
            <MDBRow className="tag-div">
              <label className="_label --tag">Email</label>
            </MDBRow>
            <Text
              className="login-field"
              type="numbers"
              name="username"
              placeholder="(+60)"
              verification
            />
            <Code className="login-field" name="verification_code" />
            <MDBRow>
              <CheckBox
                name="checkBox"
                type="toggle"
                className="login_checkbox_div"
                classNameCheckbox="login_checkbox"
                span="span"
              />
            </MDBRow>
            <MDBRow>
              <Button type={"submit"} name="Sign up" className="login_button" />
            </MDBRow>
          </MDBContainer>
        </Form>
      )}
    </Formik>
  );
}
