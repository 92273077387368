import { MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

//component
import CheckBox from "../../components/element/checkbox";

//redux
import { useDispatch } from "react-redux";
import { changeUserId } from "../../redux/reducer/loginReducer";
import { merchantCart } from "../../redux/reducer/menuReducer";
import { openModalDenied } from "../../redux/reducer/modalReducer";
import { cartListing } from "../../redux/reducer/cartReducer";
import { setUserno } from "../../redux/reducer/profileReducer";
import { setLogoutStatus } from "../../redux/reducer/commonReducer";
//auth
import authServices from "../../services/auth.services";
import { Icon } from "@iconify/react";
import useWindowDimensions from "../../components/common/window-dimension";

export default function PreRegister({ name }) {
  const { width } = useWindowDimensions()
  const deviceId = navigator.userAgent.replace(/[^\w]/gi, "");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const table = localStorage.getItem("table");
  const [loading, setLoading] = useState(false);

  const [userData, setUserData] = useState({
    username: "",
    checkBox: false,
    table_id: table,
    device_id: deviceId,
  });

  const useSchema = Yup.object({
    username: Yup.string()
      .test("is-malaysia-phone", "Must use Malaysia phone number", (value) => {
        const regex = /^(01)[02-9]-*[0-9]{7}$|^(01)[1]-*[0-9]{8}$/g;
        return regex.test(value);
      })
      .required("Required"),
    // checkBox: Yup.bool().oneOf([true], ""),
  });

  const handleSubmit = async ({ values, setFieldError }) => {
    setLoading(true)
    try {
      const response = await authServices.perRegister(values);
      if (response) {
        dispatch(setLogoutStatus())
        dispatch(setUserno(values.username))
        setLoading(false)
        navigate(name);
        dispatch(changeUserId(values.username));
        // dispatch(cartListing({ device_id: deviceId }));
        localStorage.setItem("username", values.username);
        localStorage.setItem("pre_number", values.username);
        if (table) {
          // dispatch(merchantCart({ device_id: deviceId, table_id: table }));
        }
      }
    } catch (ex) {
      setLoading(false)
      if (ex && Object.keys(ex).length > 0) {
        let errorMsg = [];
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              errorMsg = [errors[item]];
              if (
                errors[item] &&
                errors[item][0] ==
                  "table_id is currently unavailable. Please scan QR code from another table, thank you."
              ) {
                // dispatch(openModalDenied());
              }
            });
          }
        }
      }
    }
  };

  return (
    <Formik
      initialValues={userData}
      validationSchema={useSchema}
      onSubmit={(values, { resetForm }) => {
        handleSubmit({ values });
        resetForm({ values: "" });
      }}
    >
      {({ errors, isValid, resetForm, isSubmitting, setFieldError }) => (
        <Form>
          <div className="text-center  partial-modal">
            <label className="_label --text">
              Register Now For Proceed Your Order Payment
            </label>
            <br />
            <label className="_label --text">Earn Cash Back Now </label>
            <div
              style={{
                textAlign: "left",
                marginLeft: "10px",
                marginTop: "0.6em",
              }}
            >
              <MDBRow>
                <CheckBox
                  name="checkBox"
                  type="toggle"
                  className="login_checkbox_div"
                  classNameCheckbox="login_checkbox"
                  span="span"
                />
              </MDBRow>
            </div>
            <MDBRow className="form-group _partail-input mt-0">
              <MDBCol className="--input col-6 col-lg-7 col-md-7">
                <Field
                  className="text-input"
                  placeholder="Contact number"
                  type="tel"
                  name="username"
                />
              </MDBCol>
              <MDBCol
                className="--button"
                style={{
                  background: !isValid ? "#acacac" : loading ? "#ec999d" : "#E26368",
                }}
              >
                <MDBBtn disabled={!isValid | isSubmitting} type={"submit"}>
                  <div className="d-flex align-items-center justify-content-center">
                  Sign up / Login
                  {loading && 
                    <Icon icon="line-md:loading-twotone-loop" color="#fafafa" width="1.4em" className="ms-3" />
                  }
                  </div>
                </MDBBtn>
              </MDBCol>
            </MDBRow>
            <div className="text-start">
              <label
                style={{
                  fontSize: "1.2rem",
                  color: "#DC4C64",
                  marginLeft: "0.5em",
                }}
              >
                {errors && errors.username}
              </label>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
