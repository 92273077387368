import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { RemoveScroll } from "react-remove-scroll";
import { MDBRow, MDBBtn, MDBModalBody, MDBContainer } from "mdb-react-ui-kit";

//component
import ModalBox from "../../components/modal/full.modal.box";

//redux
import { useSelector, useDispatch } from "react-redux";
import { closeAllModal } from "../../redux/reducer/modalReducer";

export default function ModalDenied({ message }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isOpenDenied } = useSelector((state) => state.modal);

  return (
    <RemoveScroll>
      <ModalBox
        show={isOpenDenied}
        screenSize="m"
        className="remove-modal"
        content={
          <MDBModalBody>
            <MDBContainer>
              <MDBRow className="mt-3">
                <Icon icon="fa:hand-stop-o" height={"80"} />
              </MDBRow>
              <MDBRow>
                <label className="remove-title">
                  {message == "expired" ? "Expired QR Code" : "Access Denied"}
                </label>
              </MDBRow>
              <MDBRow>
                <span className="remove-span">
                  {message == "expired"
                    ? "Please scan again the given QR code."
                    : "Failure to get the table information, please try again later."}
                  <br />
                  Thank You.
                </span>
              </MDBRow>
              <MDBContainer className="information">
                <MDBRow className="btn-row">
                  <MDBBtn
                    className="home_button"
                    onClick={() => {
                      dispatch(closeAllModal());
                      navigate("/");
                      localStorage.clear();
                    }}
                  >
                    Scan Again
                  </MDBBtn>
                </MDBRow>
              </MDBContainer>
            </MDBContainer>
          </MDBModalBody>
        }
      />
    </RemoveScroll>
  );
}
