import { Field, useFormikContext } from "formik";
import { MDBCol, MDBRow, MDBInput } from "mdb-react-ui-kit";
import { useState, useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import ReactInputVerificationCode from "react-input-verification-code";

//auth
import authServices from "../../services/auth.services";

export default function Code({ name, className }) {
  const { values, errors, setFieldValue } = useFormikContext();
  const [no, setNo] = useState("");
  const handleChange = (e) => {
    setNo(e);
  };

  const handleCompleted = (e) => {
    setFieldValue(name, e);
  };

  return (
    <>
      <MDBCol className={`form-group _code ${className}`}>
        <MDBRow>
          <label className="_label --code">Enter verification code</label>
        </MDBRow>
        <MDBRow className="form-group _code">
          <ReactInputVerificationCode
            length={6}
            value={no}
            onChange={(e) => handleChange(e)}
            placeholder={false}
            type="password"
            onCompleted={(e) => handleCompleted(e)}
          />
        </MDBRow>
      </MDBCol>
      <MDBRow>
        {/* {errors && errors[name] ? (
          <div className="text-danger text-right no-padding error-message">
            {errors[name]}
          </div>
        ) : null} */}
      </MDBRow>
    </>
  );
}
