import { useEffect } from 'react'

//lib
import { MDBContainer } from 'mdb-react-ui-kit'

//component
import DataHeader from './component/dataHeader'
import TermsData from './component/termsData'
import PolicyData from './component/policyData'

//redux
import { useSelector } from "react-redux";

export default function TermsOfUse() {
    const { selectedFile } = useSelector((state) => state.common);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <MDBContainer className='term-container'>
        <DataHeader />
       {selectedFile === "terms" ?  <TermsData /> : <PolicyData />}
    </MDBContainer>
  )
}
