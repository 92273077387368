import { Store } from "react-notifications-component";
import { Icon } from "@iconify/react";

export const Notification = ({ message, title, type }) => {
  return Store.addNotification({
    //content: CustomNotification(message, type, title),
    title: title,
    message: message,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__flipInX"],
    animationOut: ["animate__animated", "animate__flipInY"],
    dismiss: {
      duration: 1000,
      onScreen: true,
    },
    slidingExit: {
      duration: 800,
      timingFunction: "ease-out",
      delay: 0,
    },
  });
};
