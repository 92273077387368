import { MDBTooltip } from 'mdb-react-ui-kit';

// 10
export function basicNumber(number) {
  const checkNumber = number ? number : 0
  const newNumber = Number(checkNumber)

  const formattedNumber =  parseFloat(newNumber).toFixed(0)

  return formattedNumber;
}
// 1,000.00  //hover
export function formatNumber(number) {
  const checkNumber = number ? String(number) : "0.00";

  if (checkNumber.includes(",")) {
    const intValue =parseFloat(checkNumber.replace(/,/g, ""));
    const newNumber = Number(intValue);
    const roundedDownNumber = Math.floor(newNumber * 100) / 100;
    const formattedNumber = roundedDownNumber.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedNumber

  } else {
    const newNumber = Number(checkNumber);
    // const roundedDownNumber = parseFloat(newNumber.toFixed(2));
    // console.log("Dd",formattedNumber1)
    const roundedDownNumber = newNumber.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    const newRoundedDownNumber =  Number(roundedDownNumber).toFixed(2);
    const formattedNumber = newRoundedDownNumber.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedNumber
  }
}
  
    export function formatNumberTwo(number) {
      const checkNumber = number ? String(number) : "0"
   
      if(checkNumber.includes(",")){
        const intValue = parseInt(checkNumber.replace(/,/g, ''), 10);
        const newNumber = Number(intValue)
        const formattedNumber = newNumber.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return (
         // <MDBTooltip tag={"a"} title={number}>
           formattedNumber
           // </MDBTooltip>
       ) 
      } else {
       const newNumber = Number(checkNumber)
       const formattedNumber = newNumber.toLocaleString(undefined, {
         minimumFractionDigits: 2,
         maximumFractionDigits: 2,
       });
   
       return (
         // <MDBTooltip tag={"a"} title={number}>
           formattedNumber
           // </MDBTooltip>
       ) 
      }
     }
  




  
  