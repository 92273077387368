import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import apiServices from "../../services/api.services";

export const profile = createAsyncThunk("get/profile", async () => {
    const response = await apiServices.userProfile();
    return response.data;
  });

const initialState = {
  data:{},
  userno : "" ,
}

const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
      setProfileData : (state,action) =>{
        state.data = action.payload
    },
    setUserno : (state,action) =>{
      state.userno = action.payload
  },
    },
    extraReducers:{
        [profile.pending]: (state, action) => {
            state.loading = true;
          },
          [profile.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload;
          },
    }
})

export const {
  setProfileData,
  setUserno
} = profileSlice.actions
const { reducer } = profileSlice
export default reducer;