import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import apiServices from "../../services/api.services";

export const cartListing = createAsyncThunk("get/cart", async (payload) => {
  const response = await apiServices.cartListing(payload);
  return response.data;
});

export const orderListing = createAsyncThunk("get/order", async (payload) => {
  const response = await apiServices.orderListing(payload);
  return response.data;
});



const initialState = {
  foodData:{},
  data:[],
  order:[],
  loading: false,
  sub:[],
  pointSeleted : 0,
  cartData : {},
  cartPointSeleted:[],
  loadingPageStatus : false,
  cart_loading: false,
  order_loading: false , 
  cart_order_loading : false , 
  selected_cart_food_id : "" , 
  selected_cart_index : "" , 
  editFormData : {},
}

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
      setFoodData : (state,action) =>{
        state.foodData = action.payload
        const value = action.payload
       if(value.cart_item_id !== undefined && value !== ""){ state.editFormData = {
          cart_item_id : value.cart_item_id ,
          quantity : value.quantity ,
          remark : value.remark
        }
        value.details.forEach((item) => {
          const optionId = item.option_id;
          const optionItemId = item.option_item_id;
        
          if (!state.editFormData.hasOwnProperty(optionId)) {
            state.editFormData[optionId] = [optionItemId];
          } else {
            state.editFormData[optionId].push(optionItemId);
          }
        });}
    },
     setData : (state,action) =>{
      state.data = []
  },
    setPointSeleted : (state,action) =>{
    state.pointSeleted = action.payload
    },   
     setCartData : (state,action) =>{
     state.cartData = action.payload
   }, 
    setCartPointSeleted: (state,action) =>{
      void(state.cartPointSeleted =
        state.cartPointSeleted.filter((data) =>  
        data.id !== action.payload.id,
        )
        )
        state.cartPointSeleted.push(action.payload)
    },

    setLoadingStatus : (state,action) =>{
      state.loadingPageStatus = action.payload
    }, 
    setCartOrderLoading : (state,action) =>{
      state.cart_order_loading = action.payload
  },
  setSelectedCartFoodId : (state,action) =>{
    state.selected_cart_food_id = action.payload
},
setSelectedCartIndex : (state,action) =>{
  state.selected_cart_index = action.payload
},

setCartLoading : ( state , action) => {
  state.cart_loading = action.payload
}
    
  },
    extraReducers:{
      [cartListing.pending]: (state, action) => {
        state.cart_loading = true;
      },
      [cartListing.fulfilled]: (state, action) => {
        state.cart_loading = false;
        state.loadingPageStatus = false;
        state.cart_order_loading = false ; 
        state.selected_cart_food_id = "";
        state.selected_cart_index = "" ;
        state.data = action.payload;
      },
      [orderListing.pending]: (state, action) => {
        state.order_loading = true;
      },
      [orderListing.fulfilled]: (state, action) => {
        state.order_loading = false;
        state.order = action.payload;
      },
      
    }
})

export const {
  setFoodData,
  setData,
  setPointSeleted,
  setCartData,
  setCartPointSeleted,
  setLoadingStatus, 
  setSelectedCartFoodId ,
  setCartOrderLoading , 
  selected_cart_index,
  setSelectedCartIndex,
  setCartLoading
} = cartSlice.actions
const { reducer } = cartSlice
export default reducer;