import { Field, FieldArray, useFormikContext } from "formik";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useLocation ,useNavigate} from "react-router-dom";
import {  useDispatch } from "react-redux";
import { setSelectedFile } from "../../redux/reducer/commonReducer";

export default function CheckBox({
  type,
  label,
  labelValue,
  labelTrue,
  labelFalse,
  name,
  options,
  className,
  classNameCheckbox,
  span,
}) {
  const { values, errors } = useFormikContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goTermsPage = (item) => {
    navigate("/terms-of-use")
    localStorage.setItem("location" , pathname)
    dispatch(setSelectedFile(item))
  }


  return (
    <MDBCol className={`form-group _checkbox ${className}`}>
      {label && (
        <MDBRow>
          <label className="form-label">{label}</label>
        </MDBRow>
      )}
      {/* type for toggle (true / false only) and non type is (checkbox array) */}
      {type == "toggle" ? (
        <label
          style={{ gap: "0.5em" }}
          className={`col-12 checkbox-button d-flex align-items-center ${classNameCheckbox}`}
        >
          {span !== "span" && (
            <Field type="checkbox" className="_tccheck" name={name} />
          )}
          {span == "span" ? (
            <span className="_span">
              <span style={{ color: "white" }}>I have read and agree to the</span>
              &nbsp;
              <span className="_span --note" onClick={() => goTermsPage("terms")}>Terms of Service </span>
              and
              <span className="_span --note" onClick={() => goTermsPage("privacy")}> Privacy Policy</span>
            </span>
          ) : (
            <span className="_span">
              {labelValue
                ? labelValue
                : values[name] == true && labelTrue
                ? labelTrue
                : values[name] == false && labelFalse
                ? labelFalse
                : values[name].toString()}
            </span>
          )}
        </label>
      ) : (
        <FieldArray
          className={className}
          name={name}
          render={(arrayHelpers) => (
            <MDBRow className="align-items-center">
              {options.map((listing, index) => (
                <MDBCol
                  key={index}
                  className={`col-12 checkbox-button ${className}`}
                >
                  <label key={listing.id}>
                    <Field
                      name={name}
                      type="checkbox"
                      value={listing.value}
                      checked={values[name].includes(listing.value)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          arrayHelpers.push(listing.value);
                        } else {
                          const idx = values[name].indexOf(listing.value);
                          arrayHelpers.remove(idx);
                        }
                      }}
                    />
                    <span>&nbsp;&nbsp;{listing.label}</span>
                  </label>
                </MDBCol>
              ))}
            </MDBRow>
          )}
        />
      )}
      {errors && errors[name] ? (
        <div className="text-danger text-right no-padding error-message">
          {errors[name]}
        </div>
      ) : null}
    </MDBCol>
  );
}
