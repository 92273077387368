import { useEffect } from "react";
import { Icon } from "@iconify/react";
import { Badge } from "primereact/badge";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { useLocation, useNavigate, useParams } from "react-router-dom";
//redux
import { useSelector, useDispatch } from "react-redux";
import {
  total_item,
  merchantCart,
  setSearchValue,
  setAnimationIcon,
} from "../../redux/reducer/menuReducer";

//component
import Category from "../../pages/Menu/component/category";

export default function MainHeader({ animation }) {
  const deviceId = navigator.userAgent.replace(/[^\w]/gi, "");
  const navigate = useNavigate();
  const table = localStorage.getItem("table");
  const p_guest = localStorage.getItem("p_guest");
  const token = localStorage.getItem("token");
  const guest = localStorage.getItem("guest");
  const pre_number = localStorage.getItem("pre_number");
  const userID = localStorage.getItem("userID");
  const username = localStorage.getItem("username");

  const { total, cart, loading, animationIcon } = useSelector(
    (state) => state.menu
  );
  const { id } = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { logoutStatus , pusherStore } = useSelector((state) => state.common);
  const { bank_loading } = useSelector((state) => state.payment);

  const changeNewRedux = (path) => {
    navigate(path);
    dispatch(setSearchValue(""));
  };
  const header = [
    {
      label: "Menu",
      path: "/menu",
      icon: "icon-park-outline:knife-fork",
      icon2: "icon-park-solid:knife-fork",
      value: 0,
      merchant_halal_status: "",
    },
    {
      label: "Alcoholic",
      path: "/alcoholic",
      icon: "maki:alcohol-shop",
      icon2: "maki:alcohol-shop",
      value: 0,
      merchant_halal_status:  cart.merchant_halal_status ? cart.merchant_halal_status : "loading",
    },
    {
      label: "Cart",
      path: "/cart",
      icon: "ph:shopping-cart-simple-bold",
      icon2: "ph:shopping-cart-simple-fill",
      value: 1,
      merchant_halal_status: "",
    },
    {
      label: "Order",
      path: "/order",
      icon: "ri:file-list-3-line",
      icon2: "ri:file-list-3-fill",
      value: 0,
      merchant_halal_status: "",
    },
  ];

  useEffect(() => {
    if ((token || p_guest || guest) && !bank_loading) {
      dispatch(total_item({ device_id: deviceId }));
      dispatch(merchantCart({ device_id: deviceId, table_id: table }));
    }
  }, [logoutStatus]);

  useEffect(() => {
    if (loading === false) {
      const timer = setTimeout(() => {
        dispatch(setAnimationIcon(false));
      }, 700);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [animationIcon, loading]);


  
  return (
    <header
      className={
        animation
          ? "common-header-main2 fixed-top"
          : "common-header-main fixed-top"
      }
    >
      <MDBRow>
        {header.map((listing, index) => {
          if (listing.merchant_halal_status === "halal" || listing.merchant_halal_status === "loading") {
            return null;
          }
          return (
            <MDBCol
              key={index}
              className={
                header[1].merchant_halal_status === "loading"
                  ? "col-4 text-center" :   header[1].merchant_halal_status === "halal"
                  ? "col-4 text-center" 
                  : "col-3 text-center"
              }
              onClick={() => {
                if (
                  (!token && !p_guest && listing.path == "/cart") ||
                  (!token && !p_guest && listing.path == "/order")
                ) {
                  changeNewRedux(listing.path);
                } else {
                  changeNewRedux(listing.path);
                }
              }}
            >
              <Icon
                icon={pathname !== listing.path ? listing.icon : listing.icon2}
                color={
                  pathname == listing.path
                    ? "#E26368"
                    : id && listing.path == "/menu"
                    ? "#E26368"
                    : "#9091A0"
                }
                className={animationIcon && listing.label == "Cart" ? "" : ""}
                width="25"
                height="30"
              />
              {listing.value === 1 && total && total !== 0 ? (
                <Badge value={total} className="badge"></Badge>
              ) : (
                ""
              )}
            </MDBCol>
          );
        })}
      </MDBRow>
      {animation && <Category animation={animation} />}
    </header>
  );
}
