//framework & plugin
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { MDBContainer } from "mdb-react-ui-kit";
import { useState } from "react";

export default function ModalBox({
  show,
  header,
  footer,
  content,
  backDrop,
  className,
  onDismiss,
  size,
}) {
  return (
    <BottomSheet
      open={show}
      onDismiss={onDismiss ? onDismiss : false}
      // snapPoints={({ maxHeight }) => [0.45 * maxHeight, 0.83 * maxHeight]}
      // defaultSnap={({ lastSnap, snapPoints }) => {
      //   lastSnap ?? Math.max(...snapPoints);
      // }}
      snapPoints={
        size == false
          ? ({ maxHeight }) => [maxHeight / 1.5]
          : ({ maxHeight }) => [maxHeight / 2.5]
      }
      blocking={backDrop}
      header={header}
      footer={footer}
      className={className}
    >
      <MDBContainer>{content}</MDBContainer>
    </BottomSheet>
  );
}
