import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import apiServices from "../../services/api.services";

//redux
import { useDispatch , useSelector} from "react-redux";
import { cartListing, setLoadingStatus , setSelectedCartFoodId , setCartOrderLoading ,setSelectedCartIndex} from "../../redux/reducer/cartReducer";
import { openModalRemove } from "../../redux/reducer/modalReducer";

export default function InputCount({
  className,
  quantity,
  changePrice,
  handleChangeQuantity,
  index,
  food_id,
  setId,
  ticket_id,
  max,
  remark,
  balance,
  quantitySum,
  cartIndex
}) {
  const deviceId = navigator.userAgent.replace(/[^\w]/gi, "");
  const dispatch = useDispatch();
  const [number, setNumber] = useState(quantity);
  const { cart_order_loading } = useSelector((state) => state.cart);


  const updateCart = async ({no , original}) => {
    if(cart_order_loading){

    } else {
      setNumber(no)
      if (setId) {
        setId(food_id);
      }
      if (food_id && no !== 0) {
        dispatch(setCartOrderLoading(true));
        dispatch(setSelectedCartFoodId(food_id));
        dispatch(setSelectedCartIndex(cartIndex))
        try {
          const response = await apiServices.updateCart({
            cart_item_id: food_id ? food_id : "",
            quantity: no ? no : 0,
            remark: remark ? remark : "",
          });
          if (response) {
            dispatch(cartListing({ device_id: deviceId }));
          }
        } catch (ex) {
          setNumber(original)
        dispatch(setCartOrderLoading(false));
          if (ex && Object.keys(ex).length > 0) {
            if (ex.response.status === 422) {
              const errors = ex.response.data.errors;
              if (errors && Object.keys(errors).length > 0) {
                Object.keys(errors).map((item, i) => {});
              }
            }
          }
        }
      }
      if (food_id && no == 0) {
        dispatch(openModalRemove());
        // dispatch(setSelectedCartFoodId(food_id));
        // dispatch(setSelectedCartIndex(cartIndex))
        setNumber(1);
      } else {
      }
    }
  };

  return (
    <MDBRow className={`count-button ${className}`}>
      <MDBCol className="col-4">
        <Icon
          onClick={() => {
            if (!number <= 0 && !quantity <= 0 && max == null) {
              // setNumber(number - 1);
              updateCart({ no : number - 1 , original : number});
              index == null
                ? dispatch(changePrice(number - 1))
                : dispatch(
                    handleChangeQuantity({ index: index, type: "decreases" })
                  );
            } else if (max && !number <= 0 && number !== 0) {
              setNumber(number - 1);
              dispatch(changePrice(number - 1));
            }
          }}
          icon="ic:baseline-minus"
          color="#707070"
          width="30"
          height="25"
        />
      </MDBCol>

      <MDBCol className="col-4">
        <label
          className="_label --number"
          style={{
            color:
              (max !== null && number > max) ||
              (balance !== undefined && quantitySum >= balance) ||
              (balance !== undefined && number > balance)
                ? "#9091A0"
                : "",
          }}
        >
          {number}
        </label>
      </MDBCol>
      <MDBCol className="col-4">
        <Icon
          style={{
            color:
              (max !== null && number >= max) ||
              (balance !== undefined && quantitySum >= balance) ||
              (balance !== undefined && number >= balance)
                ? "#9091A0"
                : "",
          }}
          onClick={() => {
            if (
              number < 99 &&
              quantity < 99 &&
              max == null &&
              balance == undefined
            ) {
              // setNumber(number + 1);
              updateCart({ no : number + 1 , original : number});
              index == null
                ? dispatch(changePrice(number + 1))
                : dispatch(
                    handleChangeQuantity({ index: index, type: "increases" })
                  );
            }
            if (
              balance !== undefined &&
              number < 99 &&
              number < balance &&
              balance > quantitySum &&
              max == null
            ) {
              // setNumber(number + 1);
              updateCart({ no : number + 1 , original : number});
              index == null
                ? dispatch(changePrice(number + 1))
                : dispatch(
                    handleChangeQuantity({ index: index, type: "increases" })
                  );
            } else if (max && number < max && balance == undefined) {
              setNumber(number + 1);
              dispatch(changePrice(number + 1));
            }
          }}
          icon="ic:outline-plus"
          color="#707070"
          width="30"
          height="25"
        />
      </MDBCol>
    </MDBRow>
  );
}
