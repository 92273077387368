import { useState } from "react";
import { RemoveScroll } from "react-remove-scroll";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";

//component
import ModalBox from "../../components/modal/half.modal.box";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  cartListing,
  setPointSeleted,
  setCartPointSeleted,
} from "../../redux/reducer/cartReducer";

export default function ModalRedeemOption({ onDismiss }) {
  const { isOpenRedeem } = useSelector((state) => state.modal);
  const { pointSeleted, cartData } = useSelector((state) => state.cart);
  const { cashback } = useSelector((state) => state.report);
  const loginId = localStorage.getItem("loginId");

  const dispatch = useDispatch();
  const deviceId = navigator.userAgent.replace(/[^\w]/gi, "");
  const cashbackPoint = cashback ? cashback : 0
  const newCashback = parseInt(cashbackPoint.toString().replace(/,/g, '') , 10)

  const optionList = [
    {
      name: "Rm 1000 off",
      value: 100000,
      stamps: 100000,
      id: 8,
      status:
        cartData.subtotal / 2 > 1000 && newCashback > 100000 ? true : false,
    },
    {
      name: "Rm 500 off",
      value: 50000,
      stamps: 50000,
      id: 7,
      status: cartData.total_price / 2 > 500 && newCashback > 50000 ? true : false,
    },
    {
      name: "Rm 100 off",
      value: 10000,
      stamps: 10000,
      id: 6,
      status: cartData.total_price / 2 > 100 && newCashback > 10000 ? true : false,
    },
    {
      name: "Rm 50 off",
      value: 5000,
      stamps: 5000,
      id: 5,
      status: cartData.total_price / 2 > 50 && newCashback > 5000 ? true : false,
    },
    {
      name: "Rm 20 off",
      value: 2000,
      stamps: 2000,
      id: 4,
      status: cartData.total_price / 2 > 20 && newCashback > 2000 ? true : false,
    },
    {
      name: "Rm 10 off",
      value: 1000,
      stamps: 1000,
      id: 3,
      status: cartData.total_price / 2 > 10 && newCashback > 1000 ? true : false,
    },
    {
      name: "Rm 5 off",
      value: 500,
      stamps: 500,
      id: 2,
      status: cartData.total_price / 2 > 5 && newCashback > 500 ? true : false,
    },
    {
      name: "Rm 1 off",
      value: 100,
      stamps: 100,
      id: 1,
      status: cartData.total_price / 2 > 1 && newCashback > 100 ? true : false,
    },
  ];
  const selectPoint = (data) => {
    if (data === pointSeleted) {
      dispatch(setPointSeleted(""));
    } else {
      dispatch(setPointSeleted(data));
    }
  };

  const handleSubmit = (data) => {
    onDismiss();
    dispatch(
      setCartPointSeleted({
        id: cartData.cart_id,
        point: data,
      })
    );
  };

  return (
    <RemoveScroll>
      <ModalBox
        show={isOpenRedeem}
        size={false}
        onDismiss={onDismiss}
        className="redeem-modal"
        content={
          <MDBContainer className="redeem-container">
            <MDBRow>
              <label className="_label --title">Save more using stamps</label>
            </MDBRow>
            <MDBRow>
              <label className="_label --text">
                Your have {newCashback} stamp available to use
              </label>
            </MDBRow>
            {optionList.map((item, index) => {
              if (item.status == false) {
                return null;
              }
              return (
                <MDBRow
                  key={index}
                  className={
                    pointSeleted == item.value
                      ? "stamps-row active"
                      : "stamps-row"
                  }
                  onClick={() => {
                    selectPoint(item.value);
                  }}
                >
                  <MDBCol className="col-7">
                    <label className="_label --name">{item.name}</label>
                  </MDBCol>
                  <MDBCol className="col-4 _label --stemps">
                    <label>{item.stamps ? item.stamps : 0} stamps</label>
                  </MDBCol>
                  <MDBCol className="col-1 text-center">
                    <Icon
                      icon={
                        pointSeleted == item.value
                          ? "healthicons:yes"
                          : "la:circle-solid"
                      }
                      color="#e26368"
                      height={"1.4em"}
                    />
                  </MDBCol>
                </MDBRow>
              );
            })}
            <MDBRow className="pay-button">
              <MDBCol className="button">
                <MDBBtn
                  className="button-group"
                  onClick={() => handleSubmit(pointSeleted)}
                >
                  Redeem Now
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        }
      />
    </RemoveScroll>
  );
}
